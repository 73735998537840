<template>
  <span class="audio-btn">
    <span class="audio-btn__view" @click="clickPlayHandle">
      <base-icon v-if="isPlay" class="audio-btn__icon audio-btn__icon-pause" glyph="#icon-pause" width="24px" />

      <base-icon v-else class="audio-btn__icon audio-btn__icon-play" glyph="#icon-play" width="24px" />
    </span>
    <audio ref="audio" :src="src" controls="true" class="audio-btn__audio"></audio>
  </span>
</template>

<script>
export default {
  name: 'AudioBtn',
  props: {
    src: {
      type: String,
    },
  },
  data() {
    return {
      isPlay: false,
    };
  },

  methods: {
    clickPlayHandle() {
      if (this.isPlay) {
        this.$refs.audio.pause();
        this.isPlay = false;
      } else {
        this.$refs.audio.play();
        this.isPlay = true;
      }
    },
  },

  mounted() {
    this.$refs.audio.addEventListener('ended', () => {
      this.isPlay = false;
    });
  },
};
</script>

<style lang="less">
@import '~less_vars';
.audio-btn {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;

  &__view {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    cursor: pointer;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.12);
    }
  }

  &__icon {
    position: absolute;
    &-play {
      left: 15px;
      top: 13px;
    }
    &-pause {
      left: 13px;
      top: 13px;
    }
  }

  &__audio {
    display: none;
  }
}
</style>
