<template>
  <base-modal class="modal-new-section" title="Новый раздел">
    <div slot="modal-body">
      <div class="modal-new-section__row">
        <base-input width="100%" placeholder="Имя раздела" type="text" required :value="newSection.name" @input="setName" />
      </div>
      <div class="modal-new-section__footer">
        <base-button size="large" @click="submit" :disabled="!newSection.name">
          <span v-if="mod === 'new'">Создать</span>
          <span v-else>Сохранить</span>
        </base-button>
        <base-button v-if="mod !== 'new'" size="large" theme="secondary" @click="remove">Удалить</base-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ModalNewSection',
  props: {
    section: Object,
    mod: {
      type: String,
      default: 'new',
    },
  },
  data() {
    return {
      newSection: {
        name: '',
      },
    };
  },
  methods: {
    ...mapActions('sections', ['addSection', 'removeSection']),

    setName(e) {
      this.newSection.name = e.target.value;
    },

    submit() {
      const { newSection, addSection } = this;
      addSection(newSection);
      this.$emit('vuedals:close');
    },

    remove() {
      const { newSection, removeSection } = this;
      removeSection({ _id: newSection._id });
      this.$emit('vuedals:close');
    },
  },
  mounted() {
    const { mod, section } = this;

    if (mod !== 'new') {
      this.newSection = { ...section };
    }
  },
};
</script>

<style lang="less">
@import '~less_vars';

.modal-new-section {
  &__row {
    margin: 30px 0;
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    .base-btn {
      width: 200px;
      max-width: 100%;
    }
  }
}
</style>
