import { HTTP } from '@/http';

const variants = {
  namespaced: true,
  state: {
    ids: [],
    variantTypes: {
      OneOfMany: 'Единственно верный ответ',
      ManyOfMany: 'Несколько верных ответов',
      FormulaOne: 'Единственно верный ответ (формула)',
      FormulaMany: 'Несколько верных ответов (формула)',
      Matching: 'Соответствие ответов',
      Choice: 'Выбор Да/Нет',
      KeyboardGap: 'Заполнение пропусков',
      MouseGap: 'Заполнение пропусков с перетаскиванием',
      SelectGap: 'Заполнение пропусков с вариантами',
      CombinationLock: 'Кодовый замок',
      Algorithm: 'Алгоритм с демонстрацией',
      SplitAtGroups: 'Распределение по столбцам',
      Sequence: 'Расположение по порядку',
      Morphemes: 'Разбор слова по составу',
      Syntactic: 'Разбор предложения по членам',
      Highlighting: 'Выделение текста',
    },
    difficulties: {
      normal: 'Средний',
      hard: 'Увеличенный',
      insane: 'Сложный',
    },
  },
  mutations: {
    FETCH_VARIANTS: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    REMOVE_VARIANT: (state, id) => {
      const idx = state.ids.indexOf(id);
      if (idx !== -1) {
        state.ids.splice(idx, 1);
      }
    },
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.variants[id]);
    },
    listBlockVariants: (state, getters, rootState) => (blockVariants) => {
      return blockVariants.map((id) => rootState.DB.variants[id]);
    },
    typeList(state) {
      return Object.keys(state.variantTypes).map((n) => {
        return {
          type: n,
          label: state.variantTypes[n],
        };
      });
    },
    difficultList(state) {
      return Object.keys(state.difficulties).map((n) => {
        return {
          type: n,
          label: state.difficulties[n],
        };
      });
    },
  },
  actions: {
    fetchVariants({ state, commit }, params) {
      // TODO: проверить наличие модели, если есть не грузить
      return HTTP.get('/api/getvariantslist', { params }).then((response) => {
        commit(
          'FETCH_VARIANTS',
          response.data.variants.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'variants',
            payload: response.data.variants,
          },
          { root: true }
        );
        return response.data.variants;
      });
    },
    fetchVariantsBulk({ state, commit }, params) {
      params.populate = 'practice';
      return HTTP.get('/crud/variant', { params }).then((response) => {
        const ids = response.data.models.map((n) => n._id);
        commit('FETCH_VARIANTS', ids);
        commit(
          'FETCH_MODELS',
          {
            type: 'variants',
            payload: response.data.models,
          },
          { root: true }
        );
        return response.data.models;
      });
    },
    addVariant({ state, commit }, params) {
      return HTTP.post('/api/createvariant', { ...params }).then((response) => {
        if (response.data.variant) {
          commit('FETCH_VARIANTS', [response.data.variant._id]);
          commit(
            'FETCH_MODELS',
            {
              type: 'variants',
              payload: [response.data.variant],
            },
            { root: true }
          );
          return response.data.variant;
        }
      });
    },
    removeVariant({ state, commit }, params) {
      HTTP.post('/api/deletevariant', { ...params })
        .then((response) => {
          commit('REMOVE_VARIANT', params._id);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    changeVariant({ state, commit }, params) {
      HTTP.post('/api/updatevariant', { ...params }).then((response) => {
        const { variant } = response.data;
        if (variant) {
          commit('FETCH_VARIANT', variant, { root: true });
        }
      });
    },

    setAnswer({ state, commit }, params) {
      return HTTP.post('/api/setanswer', { ...params }).then((response) => {
        if (response.data.variant) {
          commit('FETCH_VARIANTS', [response.data.variant._id]);
          commit(
            'FETCH_MODELS',
            {
              type: 'variants',
              payload: [response.data.variant],
            },
            { root: true }
          );
          return response.data.variant;
        }
      });
    },
    removeAnswer({ state, commit }, params) {
      HTTP.post('/api/deleteanswer', { ...params })
        .then((response) => {
          if (response.data.variant) {
            commit('FETCH_VARIANTS', [response.data.variant._id]);
            commit(
              'FETCH_MODELS',
              {
                type: 'variants',
                payload: [response.data.variant],
              },
              { root: true }
            );
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    setQuestion({ state, commit }, params) {
      return HTTP.post('/api/setquestion', { ...params }).then((response) => {
        if (response.data.variant) {
          commit('FETCH_VARIANTS', [response.data.variant._id]);
          commit(
            'FETCH_MODELS',
            {
              type: 'variants',
              payload: [response.data.variant],
            },
            { root: true }
          );
          console.log(response.data.variant);
          return response.data.variant;
        }
      });
    },
    deleteQuestion({ state, commit }, params) {
      HTTP.post('/api/deletequestion', { ...params })
        .then((response) => {
          if (response.data.variant) {
            commit('FETCH_VARIANTS', [response.data.variant._id]);
            commit(
              'FETCH_MODELS',
              {
                type: 'variants',
                payload: [response.data.variant],
              },
              { root: true }
            );
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    setGroup({ state, commit }, params) {
      return HTTP.post('/api/setgroup', { ...params }).then((response) => {
        if (response.data.variant) {
          commit('FETCH_VARIANTS', [response.data.variant._id]);
          commit(
            'FETCH_MODELS',
            {
              type: 'variants',
              payload: [response.data.variant],
            },
            { root: true }
          );
          return response.data.variant;
        }
      });
    },
    setWrongGroup({ state, commit }, params) {
      return HTTP.post('/api/setwronggroup', { ...params }).then((response) => {
        if (response.data.variant) {
          commit('FETCH_VARIANTS', [response.data.variant._id]);
          commit(
            'FETCH_MODELS',
            {
              type: 'variants',
              payload: [response.data.variant],
            },
            { root: true }
          );
          return response.data.variant;
        }
      });
    },
    deleteGroup({ state, commit }, params) {
      HTTP.post('/api/deletegroup', { ...params })
        .then((response) => {
          if (response.data.variant) {
            commit('FETCH_VARIANTS', [response.data.variant._id]);
            commit(
              'FETCH_MODELS',
              {
                type: 'variants',
                payload: [response.data.variant],
              },
              { root: true }
            );
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    deleteWrongGroup({ state, commit }, params) {
      HTTP.post('/api/deletewronggroup', { ...params })
        .then((response) => {
          if (response.data.variant) {
            commit('FETCH_VARIANTS', [response.data.variant._id]);
            commit(
              'FETCH_MODELS',
              {
                type: 'variants',
                payload: [response.data.variant],
              },
              { root: true }
            );
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
  },
};

export default variants;
