<template>
  <div class="plain-html-editor">
    <div class="plain-html-editor__bar">
      <button class="plain-html-editor__btn plain-html-editor__btn--selected" @click="handleSave">Сохранить</button>
      <div class="plain-html-editor__bar-spacer"></div>
      <button class="plain-html-editor__btn" @click="handleClean" title="Валидировать HTML">
        <base-icon glyph="#icon-spell" width="20px" />
      </button>
      <button class="plain-html-editor__btn" @click="handleFullScreen" title="Полный экран">
        <base-icon glyph="#icon-enlarge" width="20px" />
      </button>
    </div>
    <div class="plain-html-editor__content">
      <textarea
        class="plain-html-editor__content-inner"
        v-model="text"
        ref="editor"
        spellcheck="false"
        ondragenter="return false;"
        ondragleave="return false;"
        ondragover="return false;"
        ondrop="return false;"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { toggleFullscreen } from '@/helpers/index';

export default {
  name: 'PlainHtmlEditor',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      text: '',
    };
  },
  watch: {
    content: {
      handler(val) {
        this.text = val;
      },
      immediate: true,
    },
  },
  methods: {
    handleFullScreen() {
      const el = this.$el;
      toggleFullscreen(el);
    },
    handleClean() {
      this.$store.dispatch('cleanHtml', this.text).then(({ data }) => {
        this.text = data.html;
      });
    },
    handleSave() {
      const content = this.text;
      this.$emit('setContent', this.text);
    },
  },
};
</script>

<style lang="less" scoped>
.plain-html-editor {
  outline: none;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  &__content {
    background-color: lighten(#000, 10%);
    flex-grow: 1;
    position: relative;

    &-inner {
      position: absolute;
      background-color: lighten(#000, 10%);
      border: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      white-space: pre;
      overflow: auto;
      color: #fff;
      line-height: 1.86;
      font-size: 14px;
      padding: 15px;
      transition: background 0.3s;
      outline: none;
      width: 100%;
      font-family: Consolas, monaco, monospace;
      resize: none;
      &:focus {
        background-color: #000;
      }
    }
  }

  &__bar {
    flex-grow: 0;
    width: 100%;
    height: 40px;
    background: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;

    &-spacer {
      flex-grow: 1;
    }
  }

  &__btn {
    flex: 0 0 auto;
    border: 0;
    background-color: #e7e7e7;
    color: #fff;
    padding: 0 16px;
    cursor: pointer;
    text-align: center;
    outline: none;

    &:hover {
      background-color: darken(#e7e7e7, 10%);
    }

    &--selected {
      background-color: #4507ca;
      color: #fff;
      fill: #fff;

      &:hover {
        background-color: darken(#4507ca, 10%);
      }
    }
  }
}
</style>
