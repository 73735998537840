<template>
  <div class="editable-highlighting">
    <div class="editable-highlighting__title">
      <switcher
        class="my-10"
        :values="[
          { name: 'HTML', value: 'html' },
          { name: 'Редактор', value: 'wysiwyg' },
        ]"
        :selected="practice && practice.contentType"
        @switcher:change="handleChangeType"
      ></switcher>
      <plain-html-editor
        class="mb-10"
        :content="practice.question"
        @setContent="saveText"
        v-if="practice.contentType === 'html'"
      ></plain-html-editor>

      <base-editor v-else :value="practice.question" placeholder="Условие" @save-text="saveText" />
    </div>
  </div>
</template>

<script>
import PlainHtmlEditor from '@/components/Common/PlainHtmlEditor';
import Switcher from '@/components/Common/Switcher';

export default {
  name: 'EditableHighlighting',
  components: {
    PlainHtmlEditor,
    Switcher,
  },
  props: {
    practice: Object,
    type: String,
  },
  methods: {
    saveText(text) {
      this.$emit('set-question', { question: text });
    },
    handleChangeType(type) {
      if (confirm('Текст вопроса, при смене типа контента будет удален.\nВсе равно поменять тип контента?')) {
        let question = '';
        if (type === 'html') {
          question = this.practice.question;
        }
        this.$emit('set-question', { contentType: type });
        this.practice.question = question;
      }
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';
.editable-highlighting {
  padding: 10px 10px 0;
}
</style>