<template>
  <base-modal class="modal-new-tag" title="Новый тег">
    <div slot="modal-body">
      <div class="modal-new-tag__row">
        <base-input width="100%" placeholder="Имя тега" type="text" required :value="newTag.name" @input="setName" />
      </div>
      <div class="modal-new-tag__footer">
        <base-button size="large" @click="submit" :disabled="!newTag.name">
          <span v-if="mod === 'new'">Создать</span>
          <span v-else>Сохранить</span>
        </base-button>
        <base-button v-if="mod !== 'new'" size="large" theme="secondary" @click="remove">Удалить</base-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'MadalNewTag',
  props: {
    tag: Object,
    mod: {
      type: String,
      default: 'new',
    },
    group: String,
    name: String,
  },
  data() {
    return {
      newTag: {
        name: '',
        type: null,
      },
    };
  },
  methods: {
    ...mapActions('tags', ['addTag', 'removeTag']),

    setName(e) {
      this.newTag.name = e.target.value;
    },

    submit() {
      const { newTag, addTag } = this;
      addTag(newTag);
      this.$emit('vuedals:close');
    },

    remove() {
      const { newTag, removeTag } = this;
      removeTag({ _id: newTag._id });
      this.$emit('vuedals:close');
    },
  },
  mounted() {
    const { mod, tag, group, name } = this;

    if (mod !== 'new') {
      this.newTag = { ...tag };
    } else {
      this.newTag.type = group;
      if (name) this.newTag.name = name;
    }
  },
};
</script>

<style lang="less">
@import '~less_vars';

.modal-new-tag {
  &__row {
    margin: 30px 0;
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    .base-btn {
      width: 200px;
      max-width: 100%;
    }
  }
}
</style>
