<template>
  <component :is="parseForHighlighting" />
</template>

<script>
import Vue from 'vue';

export default {
  name: 'PreviewHighlighting',
  props: {
    question: String,
  },
  computed: {
    parseForHighlighting() {
      const re = /\#\{([^\{\}]*)\}/g;

      return Vue.component('highlighting', {
        template: `<div class='preview-highlighting'>${this.question.replace(re, this.highlightRender())}</div>`,
      });
    },
  },
  methods: {
    highlightRender() {
      return (match, word) => {
        let className = 'correct-answer';
        if (match[2] === '!') {
          className = 'incorrect-unswer';
          word = word.slice(1);
        }
        return `<span class="${className}">${word}</span>`;
      };
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';
.preview-highlighting {
  font-size: 22px;
}
.highlighted {
  background: #cfe0ff;
}
.correct-answer {
  background: #219653;
}
.incorrect-unswer {
  background: #fc005c;
}
</style>