<template>
  <header class="base-header">
    <div class="container">
      <div class="base-header__row">
        <router-link to="/" class="base-header__title"><h1>Разбор уроков</h1></router-link>

        <div class="base-header__nav" v-if="nav.length">
          <div class="base-header__nav-item" v-for="(item, index) in nav" :key="index">
            <router-link class="base-header__nav-link" :to="item.path">{{ item.label }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BaseHeader',
  computed: {
    ...mapState('navs', {
      nav: (state) => state.nav,
    }),
  },
};
</script>

<style lang="less">
@import '~less_vars';
.base-header {
  &__row {
    padding: 20px 0;
    display: flex;
  }

  &__title {
    text-decoration: none;
    vertical-align: middle;
    color: #fff;
    margin-right: auto;

    h1 {
      margin: 0;
    }
  }

  &__nav {
    flex: 1;
    margin-right: -20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    &-item {
      padding: 0 20px;
      display: inline-block;
    }

    &-link {
      font-size: @big-fz;
      color: @primary-color;
      cursor: pointer;
      text-decoration: none;
      font-weight: 600;
      line-height: @base-lh;
      transition: all 0.2s;

      &.router-link-active {
        color: @gray-disabled;
        cursor: none;
        pointer-events: none;
      }
    }
  }
}
</style>
