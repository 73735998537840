<template>
  <div class="structure-unit" :class="{ 'structure-unit_is-empty': isEmptyTest }">
    <div class="structure-unit__inner">
      <div class="structure-unit__data">
        <div class="structure-unit__type" v-if="!pending">{{ blockType }}</div>
        <div class="structure-unit__name">{{ name || 'Без названия' }}</div>
      </div>
    </div>
    <div class="structure-unit__tags" v-if="tags">
      <unit-tag v-for="(item, idx) in tagsList" :key="`tag-item-${idx}`">{{ item.name }}</unit-tag>
    </div>
    <div class="structure-unit__pending" v-if="pending">
      <base-spinner />
    </div>
    <div class="structure-unit__controls">
      <slot name="controls" />
    </div>
    <slot name="top-controls" />
  </div>
</template>

<script>
import UnitTag from './UnitTag';

export default {
  name: 'StructureUnit',
  components: {
    UnitTag,
  },
  props: {
    stuctureId: {
      type: String,
    },
    blockId: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    idx: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: 'Без названия',
    },
    blockType: {
      type: String,
      default: 'Неизвестный тип блока',
    },
    pending: {
      type: Boolean,
      default: false,
    },
    lesson: String,
    isEmptyTest: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default() {
        return ['Название тега', 'Еще какой то длинный тег с непонятным названием'];
      },
    },
  },
  computed: {
    tagsList() {
      if (!this.tags) return null;
      return this.tags.map((tagId) => {
        const tag = this.$store.state.DB.tags[tagId];
        if (tag) return tag;
        return {
          _id: tagId,
          pending: true,
          name: '',
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';
.structure-unit {
  background-color: #fff;
  margin-bottom: 20px;
  border: 1px solid #fff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  position: relative;

  &_is-empty {
    background-color: #fc005c24;
  }
  &__head {
    height: 20px;
    background-color: #c0c0c0;
  }

  &__name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__data {
    padding: 20px 20px 10px 20px;
  }

  &__inner {
  }

  &__tags {
    padding: 0px 20px 10px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    .unit-tag {
      margin-bottom: 5px;
    }
  }

  &__icon {
    height: 80px;
    width: 90px;
    flex: 0 0 90px;
    background-color: #e4e4e4;
  }

  &__type {
    font-size: 14px;
    letter-spacing: 0.04em;
    margin-bottom: 8px;
    color: fade(#000, 40%);
  }

  &__controls {
    padding: 5px 15px 15px 15px;

    .base-btn {
      margin: 5px;
    }
  }

  .preview-btn {
    background-color: transparent;
    box-shadow: none;
    border: 0;
    top: 10px;
    position: absolute;
    right: 20px;
    outline: none;
    font-size: 12px;
    text-transform: uppercase;
    color: @secondary-color;
    cursor: pointer;
    border-bottom: 1px dashed fade(@secondary-color, 30%);
    padding-left: 0;
    padding-right: 0;

    &:hover {
      color: @base-color;
      border-bottom-color: fade(@secondary-color, 0%);
    }
  }

  // &.gu-mirror,
  // &.sortable-chosen {
  //   pointer-events: none;
  //   box-shadow: 0px 15px 8px -10px fade(#000, 20%);
  //   //transform: rotateZ(5deg) scale(.9)
  // }

  &.gu-transit,
  &.sortable-ghost {
    background-color: #dedede;
    border: 1px dashed #000;
    opacity: 0.35;
  }
}
</style>
