export const uploadFile = {
  data() {
    return {
      fileUploadPending: false,
      fileUploadUrl: null,
    };
  },
  methods: {
    uploadSingle(e) {
      const file = e.target.files[0];
      if (!file) return;
      this.fileUploadPending = true;
      const formData = new FormData();
      formData.append('file', file);
      this.$store
        .dispatch('fileServer/uploadSingle', formData)
        .then((link) => {
          this.fileUploadPending = false;
          this.fileUploadUrl = link;
          this.$emit('fileupload:done', {
            name: e.target.name,
            link,
          });
        })
        .catch((err) => {
          console.log('err %O', err);
          this.fileUploadPending = false;
          alert('Во время отправки запроса произошла ошибка. Пожалуйста, попробуйте позже.');
        });
    },
  },
};
