import { Quill } from 'vue-quill-editor';

const BlockEmbed = Quill.import('blots/embed');

class Iframe extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute('src', value.src);
    node.setAttribute('frameborder', 0);
    if (value.style) {
      node.setAttribute('style', value.style);
    }
    if (value.allowfullscreen) {
      node.setAttribute('allowfullscreen', value.allowfullscreen);
    }
    if (value.height) {
      node.setAttribute('height', value.height);
    }
    if (value.width) {
      node.setAttribute('width', value.width);
    }
    if (value.class) {
      node.setAttribute('class', value.class);
    }
    return node;
  }

  static value(node) {
    const returnNode = {
      src: node.getAttribute('src'),
      frameborder: node.getAttribute('frameborder'),
    };
    if (node.getAttribute('allowfullscreen')) {
      returnNode.allowfullscreen = node.getAttribute('allowfullscreen');
    }
    if (node.getAttribute('style')) {
      returnNode.style = node.getAttribute('style');
    }
    if (node.getAttribute('allowfullscreen')) {
      returnNode.allowfullscreen = node.getAttribute('allowfullscreen');
    }
    if (node.getAttribute('height')) {
      returnNode.height = node.getAttribute('height');
    }
    if (node.getAttribute('width')) {
      returnNode.width = node.getAttribute('width');
    }
    if (node.getAttribute('class')) {
      returnNode.class = node.getAttribute('class');
    }
    return returnNode;
  }
}
Iframe.blotName = 'iframe';
Iframe.tagName = 'IFRAME';

export default Iframe;
