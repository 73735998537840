<template>
  <span class="base-tag" :class="applyClasses" @click.self="$emit('onTagClick', $event)">
    {{ name }}
    <span class="base-tag__remove" v-if="variant === 'with-icon'" @click="$emit('onCloseClick', $event)">
      <base-icon glyph="#icon-close" variant="hover" width="18px" height="18px" />
    </span>
  </span>
</template>

<script>
import { toBemModifer } from '@/helpers/index';

export default {
  name: 'BaseTag',
  props: {
    name: String,
    variant: [String, Array],
  },
  computed: {
    applyClasses() {
      const result = [...toBemModifer(this.variant, 'base-tag', 'variant')];
      return result;
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-tag {
  border-radius: 4px;
  font-size: 15px;
  height: 42px;
  line-height: 42px;
  margin-right: 5px;
  padding: 0 15px;
  display: inline-block;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  color: @primary-color;

  &:hover {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);
  }

  // Variants
  &--variant-with-icon {
    padding-right: 45px;
    position: relative;
  }

  &--variant-with-icon &__remove {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    padding: 5px;
    line-height: 0;
    cursor: pointer;
  }
}
</style>
