<template>
  <div class="page-sections">
    <div class="page-sections__header">
      <h2>Разделы</h2>
      <base-button variant="with-icon" @click="handleCreateSection">
        <base-icon glyph="#icon-plus" width="20px" />Добавить раздел
      </base-button>
    </div>
    <div class="page-sections__body">
      <div class="page-sections__list">
        <base-tag
          class="page-sections__section"
          variant="with-icon"
          v-for="section in list"
          :key="section._id"
          :name="section.name"
          @onTagClick="handleEditSection(section)"
          @onCloseClick="removeSection({ _id: section._id })"
        />
      </div>
    </div>
    <vuedal></vuedal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Component as Vuedal } from 'vuedals';
import ModalNewSection from '@/components/Modal/ModalNewSection';

export default {
  name: 'PageSections',
  components: {
    Vuedal,
  },
  computed: {
    ...mapGetters('sections', ['list']),
  },
  methods: {
    ...mapActions('sections', ['fetchSections', 'removeSection']),

    handleCreateSection() {
      this.$emit('vuedals:new', {
        name: 'new-section',
        component: ModalNewSection,
        props: {},
        dismissable: false,
        size: 'sm',
      });
    },
    handleEditSection(section) {
      this.$emit('vuedals:new', {
        name: 'new-section',
        component: ModalNewSection,
        props: { section, mod: 'edite' },
        dismissable: false,
        size: 'sm',
      });
    },
  },
  mounted() {
    this.fetchSections();
  },
};
</script>

<style lang="less">
@import '~less_vars';
.page-sections {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    padding: 20px 15px;
    display: flex;
    border-bottom: 1px solid #eaeaea;

    h2 {
      margin: 0;
      margin-right: auto;
      font-size: @h2;
    }

    .base-btn {
      margin-left: 10px;
    }
  }

  &__body {
    flex: 1;
    padding: 15px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__list {
    margin: 0 -7px;
  }

  &__section {
    margin: 7px;
    cursor: pointer;
  }
}
</style>
