<template>
  <div class="editable-selected-answers">
    <div class="editable-selected-answers__title">
      <switcher
        class="my-10"
        :values="[
          { name: 'HTML', value: 'html' },
          { name: 'Редактор', value: 'wysiwyg' },
        ]"
        :selected="practice && practice.contentType"
        @switcher:change="handleChangeType"
      ></switcher>
      <plain-html-editor
        class="mb-10"
        :content="parsedQuestion"
        @setContent="saveText"
        v-if="practice.contentType === 'html'"
      ></plain-html-editor>
      <base-editor :value="parsedQuestion" v-else placeholder="Условие" @save-text="saveText" />
      <code-area
        v-if="isCodeArea"
        class="editable-selected-answers__code-area"
        title="Вставить код"
        :content="practice && practice.sharedCode"
        :collapsed="true"
        @update="saveCode"
      ></code-area>

      <base-checkbox v-model="practice.horizontal" @input="$emit('set-question', { horizontal: $event })" class="mb-20"
        >Расположить ответы горизонтально</base-checkbox
      >
    </div>
    <div class="editable-selected-answers__body">
      <div class="editable-selected-answers__answer" v-for="answer in practice.answers" :key="answer._id">
        <base-checkbox
          v-if="type === 'ManyOfMany' || type === 'FormulaMany'"
          v-model="answer.correct"
          @input="setCheckbox($event, answer._id)"
        />

        <base-radio
          v-if="type === 'OneOfMany' || type === 'FormulaOne'"
          :value="answer._id"
          v-model="correctAnswerId"
          @input="setRadio"
        />

        <!-- <base-input
          placeholder="Ответ"
          type="text"
          name="name"
          :value="answer.answer"
          @blur="setText($event, answer._id)"
        />-->
        <base-editor :value="answer.answer" placeholder="Ответ" @save-text="setText($event, answer._id)" />

        <div class="editable-selected-answers__answer-remove" @click="$emit('deleteAnswer', answer._id)">
          <base-icon glyph="#icon-close" variant="hover" width="20px" />
        </div>
      </div>
    </div>
    <div class="editable-selected-answers__new-answer">
      <base-button theme="dashed" @click="pushNewAnswer">
        <base-icon glyph="#icon-plus" width="16px" />Добавить ответ
      </base-button>
    </div>
  </div>
</template>

<script>
import CodeArea from '@/components/Common/CodeArea';
import PlainHtmlEditor from '@/components/Common/PlainHtmlEditor';
import Switcher from '@/components/Common/Switcher';

export default {
  name: 'EditableSelectedAnswers',
  components: {
    CodeArea,
    PlainHtmlEditor,
    Switcher,
  },
  props: {
    practice: Object,
    type: String,
  },
  data() {
    return {
      correctAnswerId: null,
      correctAnswerIdOld: null,
      parsedQuestion: '',
    };
  },
  watch: {
    'practice.question': {
      immediate: true,
      handler(val) {
        this.setParsedQuestion();
      },
    },
  },
  computed: {
    // Проверям, нужно ли рисовать зону под вставку кода
    isCodeArea() {
      return ['FormulaMany', 'FormulaOne'].includes(this.type);
    },
  },
  methods: {
    saveText(text) {
      if (this.parsedQuestion === text) return;
      this.$emit('set-question', {
        question: text,
        sharedCode: this.practice.sharedCode,
      });
    },
    saveCode(code) {
      this.$emit('set-question', {
        sharedCode: code,
        question: this.practice.question,
      });
    },
    setParsedQuestion() {
      const { question, variables } = this.practice;
      if (question) {
        let parsedQuestion = this.practice.question;
        if (variables && variables.length > 0) {
          variables.forEach((varData) => {
            const { name, varType, rndType, varEnum, max, min, round } = varData;
            let str = `${name};${varType}${round ? `.${round}` : ''};${rndType};`;
            if (rndType === 'enum') {
              str += JSON.stringify(varEnum);
            }
            if (rndType === 'range') {
              str += `[${min}:${max}]`;
            }
            parsedQuestion = parsedQuestion.replace(`@{${name}}`, `@{${str}}`);
          });
        }
        this.parsedQuestion = parsedQuestion;
      }
    },

    handleChangeType(type) {
      if (confirm('Текст вопроса, при смене типа контента будет удален.\nВсе равно поменять тип контента?')) {
        let question = '';
        if (type === 'html') {
          question = this.parsedQuestion;
        }
        this.$emit('set-question', { contentType: type, question });
        this.parsedQuestion = question;
      }
    },
    pushNewAnswer() {
      this.$emit('set-question', { question: this.parsedQuestion });
      this.$emit('setAnswer', { answer: '', correct: false });
      console.count('EditableSelectedAnswers_pushNewAnswer');
    },
    setText(value, answerId) {
      this.$emit('setAnswer', { answer: value }, answerId);
    },
    async setRadio(value) {
      if (this.correctAnswerIdOld) {
        await this.$emit('setAnswer', { correct: false }, this.correctAnswerIdOld);
      }
      await this.$emit('setAnswer', { correct: true }, value);

      this.correctAnswerIdOld = value;
    },
    setCheckbox(value, answerId) {
      this.$emit('setAnswer', { correct: value }, answerId);
    },
  },
  mounted() {
    const {
      type,
      practice: { answers },
    } = this;

    this.setParsedQuestion();

    if ((type === 'OneOfMany' || type === 'FormulaOne') && answers.length) {
      const correctAnswer = answers.find((answer) => answer.correct);
      if (correctAnswer) {
        this.correctAnswerId = correctAnswer._id;
        this.correctAnswerIdOld = correctAnswer._id;
      }
    }
  },
};
</script>

<style lang="less">
.editable-selected-answers {
  padding: 10px;

  &__answer {
    display: flex;
    margin-bottom: 10px;

    &-remove {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  &__code-area {
    margin-bottom: 20px;
  }
}
</style>
