<template>
  <base-modal class="modal-new-image" title="Новое изображение">
    <div slot="modal-body">
      <div class="modal-new-image__row">
        <base-input placeholder="Ссылка на изображение" type="text" name="link" required :value="img.link" @input="updateField" />
        <div class="mb-30 text">или <label for="ModalUpladFile">Загрузите</label> файл с диска</div>
        <input class="hidden" name="image" type="file" id="ModalUpladFile" @change="uploadSingle" />
      </div>
      <div class="modal-new-image__row">
        <base-input placeholder="Порядок" type="number" name="idx" required :value="img.idx" @input="updateField" />
      </div>
      <div class="modal-new-image__row">
        <base-input placeholder="Заголовок" type="text" name="title" required :value="img.title" @input="updateField" />
      </div>
      <base-button class="modal-new-image__save-btn" @click="createImg" size="large" :disabled="!img.link">Создать</base-button>
    </div>
  </base-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { uploadFile } from '@/mixins/Upload';

export default {
  name: 'ModalNewImage',
  mixins: [uploadFile],
  props: {
    idx: Number,
  },
  data() {
    return {
      img: {
        link: '',
        title: '',
        idx: this.idx,
      },
    };
  },
  watch: {
    fileUploadUrl: {
      handler(val) {
        this.img.link = val;
      },
    },
  },
  methods: {
    ...mapActions('images', ['addImage']),
    updateField(e) {
      this.img[e.target.name] = e.target.value;
    },
    async createImg() {
      const imgId = await this.addImage(this.img);
      this.$emit('vuedals:close', imgId);
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.modal-new-image {
  &__row {
    margin: 30px 0;
  }
}
</style>
