<template>
  <div>
    <search
      :results="results"
      :dragOptions="dragOptions"
      @search:get="$emit('search:get', arguments[0])"
      @search:select="$emit('search:select', arguments[0])"
      @search:update="$emit('search:update', arguments[0])"
      @search:reset="handleReset"
    >
      <div slot="filters">
        <search-filters
          :selectedTypes="selectedTypes"
          :selectedTags="selectedTags"
          @tagsChange="handleTags"
          @removeTag="handleRemoveTag"
          @typesInput="handleTypes"
        ></search-filters>
      </div>
    </search>
  </div>
</template>

<script>
import Search from './Search';
import SearchFilters from './SearchFilters';

export default {
  name: 'SearchTab',
  components: {
    Search,
    SearchFilters,
  },
  props: {
    results: {
      type: Array,
      default: [],
    },
    dragOptions: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedTypes: [],
      selectedTags: [],
    };
  },
  methods: {
    handleReset() {
      this.selectedTypes = [];
      this.selectedTags = [];
      this.updateFilters();
      this.$emit('search:reset');
    },
    handleRemoveTag(tagId) {
      const idx = this.selectedTags.findIndex((n) => n._id === tagId);
      if (idx !== -1) {
        this.selectedTags.splice(idx, 1);
        this.updateFilters();
      }
    },
    handleTypes(val) {
      this.selectedTypes = val;
      this.updateFilters();
    },
    handleTags(tags) {
      this.selectedTags = tags;
      this.updateFilters();
    },
    updateFilters() {
      const filters = {};
      if (this.selectedTypes) {
        filters.type = {
          $in: this.selectedTypes,
        };
      }
      if (this.selectedTags) {
        filters.tags = {
          $in: this.selectedTags.map((n) => n._id),
        };
      }

      this.$emit('filters:update', filters);
    },
  },
};
</script>

<style></style>
