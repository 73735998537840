<template>
  <component :is="parseForMorphemes" />
</template>

<script>
import Vue from 'vue';

export default {
  name: 'PreviewMorphemes',
  props: {
    question: String,
  },
  computed: {
    parseForMorphemes() {
      // const question =
      // `#[#sb#pref{по}#root{сад}#suf{к}#eb#end{а}],
      //   #[#sb#root{лом}#eb#end{}],
      //   #[#sb#root{сорок}о#root{нож}#suf{к}#eb#end{а}]`;

      const re = /\#\[([^\]]*)\]/gi;

      return Vue.component('morf-word', {
        template: `<span>${this.question.replace(re, this.morphemesParse(), 'gm')}</span>`,
      });
    },
  },
  methods: {
    morphemesParse() {
      return (match, word) => {
        const p = /\#pref\{([^}]*)\}/gi;
        const r = /\#root\{([^}]*)\}/gi;
        const s = /\#suf\{([^}]*)\}/gi;
        const e = /\#end\{([^}]*)\}/gi;
        const b = /\#sb([^]*)\#eb/gi;

        function replaceMorf(name) {
          return (match, morf) => {
            return `<span class="morf-word__${name}">${morf}</span>`;
          };
        }

        function replaceBasis() {
          return (match, basis) => {
            return `<span class="morf-word__basis">${basis}</span>`;
          };
        }

        return `<span class='morf-word'>
         ${word
           .replace(p, replaceMorf('pref'), 'gm')
           .replace(r, replaceMorf('root'), 'gm')
           .replace(s, replaceMorf('suf'), 'gm')
           .replace(e, replaceMorf('end'), 'gm')
           .replace(b, replaceBasis(), 'gm')}
        </span>`;
      };
    },
    drawSVG(word) {
      const rect = word.getBoundingClientRect();
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      svg.setAttribute('viewBox', `0 0 ${rect.width} ${rect.height * 1.5}`);

      const prefixes = Array.from(word.querySelectorAll('.morf-word__pref'));
      prefixes.forEach((prefix) => {
        svg.append(this.drawMorf('pref', prefix, rect.x));
      });

      const roots = Array.from(word.querySelectorAll('.morf-word__root'));
      roots.forEach((root) => {
        svg.append(this.drawMorf('root', root, rect.x));
      });

      const suffixes = Array.from(word.querySelectorAll('.morf-word__suf'));
      suffixes.forEach((suffix) => {
        svg.append(this.drawMorf('suf', suffix, rect.x));
      });

      const endings = Array.from(word.querySelectorAll('.morf-word__end'));
      endings.forEach((ending) => {
        svg.append(this.drawMorf('end', ending, rect.x));
      });

      const basics = Array.from(word.querySelectorAll('.morf-word__basis'));
      basics.forEach((basis) => {
        svg.append(this.drawMorf('basis', basis, rect.x));
      });

      word.append(svg);
    },
    drawMorf(type, item, svgX) {
      const rect = item.getBoundingClientRect();
      const startX = rect.left - svgX;
      const startY = 3;
      const width = rect.width + startX;
      const height = rect.height * 0.23;

      if (type === 'pref' || type === 'suf' || type === 'basis') {
        const polyline = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');

        switch (type) {
          case 'pref':
            polyline.setAttribute(
              'points',
              `${startX} ${startY}
              ${width} ${startY}
              ${width} ${startY + height}`
            );
            break;
          case 'suf':
            polyline.setAttribute(
              'points',
              `${startX} ${startY + height}
              ${(startX + width) / 2} ${startY}
              ${width} ${startY + height}`
            );
            break;
          case 'basis':
            polyline.setAttribute(
              'points',
              `${startX} ${rect.height - 2}
              ${startX} ${3 + rect.height}
              ${width} ${3 + rect.height}
              ${width} ${rect.height - 2}`
            );
            break;
        }
        return polyline;
      }
      if (type === 'root') {
        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

        path.setAttribute(
          'd',
          `M${startX} ${startY + height}
          Q${(startX + width) / 2} -4
          ${width} ${startY + height}`
        );
        return path;
      }
      if (type === 'end') {
        const path = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

        path.setAttribute('height', `${rect.height ? rect.height * 0.5 : 15}`);
        path.setAttribute('width', `${rect.width}`);
        path.setAttribute('x', `${startX}`);
        path.setAttribute('y', `${startY + (rect.height ? height : 7)}`);
        return path;
      }
    },
  },
  watch: {
    question: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          const words = Array.from(document.querySelectorAll('.morf-word'));

          words.forEach((word) => {
            this.drawSVG(word);
          });
        });
      },
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';
.morf-word {
  display: inline-block;
  position: relative;
  letter-spacing: 2px;
  line-height: 1.5;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    fill: none;
    stroke: @primary-color;
    stroke-linecap: round;
  }

  &__end {
    min-width: 10px;
    display: inline-block;
  }
}
</style>
