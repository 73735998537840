<template>
  <div class="editable-lock-gallery">
    <div class="editable-lock-gallery__spinner" v-if="pending">
      <base-spinner size="50px"></base-spinner>
    </div>
    <div v-else>
      <div class="editable-lock-gallery__image-wrap" v-for="img in sortedGallery" :key="img._id">
        <div class="editable-lock-gallery__image-remove" @click="removeImg(img._id)">
          <base-icon glyph="#icon-close" variant="hover" width="18px" height="18px" />
        </div>
        <div class="editable-lock-gallery__row">
          <base-icon glyph="#icon-img" width="42px" />
          <div class="editable-lock-gallery__col">
            <base-file label="Изображение" :removable="false" :name="img.link" @basefile:edit="handleImageEdit(img)"></base-file>
          </div>
        </div>
      </div>
      <base-button theme="dashed" @click="createNewImg">
        <base-icon glyph="#icon-plus" width="16px" />
        Добавить изображение
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import sortBy from 'lodash.sortby';

import ModalNewImage from '@/components/Modal/ModalNewImage';

export default {
  name: 'EditableLockGallery',
  props: {
    list: Array,
  },
  data() {
    return {
      pending: false,
      gallery: [],
      currentEditingImage: null,
    };
  },
  computed: {
    ...mapGetters('images', { listImg: 'list' }),

    sortedGallery() {
      return sortBy(this.gallery, 'idx');
    },
  },
  methods: {
    ...mapActions('images', ['fetchImages', 'addImage', 'removeImage']),
    imageEdit(url) {
      if (this.currentEditingImage) {
        const data = {
          _id: this.currentEditingImage,
          link: url,
        };
        this.addImage(data);

        const current = this.gallery.find((n) => n._id === this.currentEditingImage);
        if (current) {
          current.link = url;
        }
      }
    },
    handleImageEdit(img) {
      this.currentEditingImage = img._id;
      this.$emit('uploadFile:open', img.link, this.imageEdit);
    },
    pushImg(id) {
      const { listImg } = this;
      if (id) {
        const idx = this.gallery.findIndex(({ _id }) => _id === id);
        // Если такая картинка уже есть в галлерее, ты выходим из функции
        if (idx >= 0) return;
        this.gallery.push(listImg.find((img) => img._id === id));
        this.$emit(
          'save',
          this.gallery.map(({ _id }) => _id)
        );
      }
    },
    removeImg(id) {
      const { removeImage } = this;
      this.gallery = this.gallery.filter((img) => img._id !== id);
      removeImage({ _id: id });
      this.$emit(
        'save',
        this.gallery.map(({ _id }) => _id)
      );
    },
    createNewImg() {
      const { pushImg } = this;
      this.$emit('vuedals:new', {
        name: 'create-img',
        component: ModalNewImage,
        props: {
          idx: this.sortedGallery.length + 1,
        },
        dismissable: false,
        size: 'sm',
        onClose(e) {
          pushImg(e);
        },
      });
    },
  },
  watch: {
    list: {
      immediate: true,
      async handler(val) {
        if (val.length) {
          this.pending = true;
          this.gallery = await this.fetchImages({
            filter: { _id: { $in: [...val] } },
          });
          this.pending = false;
        }
      },
    },
  },
};
</script>

<style lang="less">
.editable-lock-gallery {
  &__spinner .base-spinner {
    margin: 0 auto;
  }

  &__image {
    &-wrap {
      position: relative;
      padding-right: 30px;
      margin-bottom: 20px;
    }

    &-remove {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }

  &__row {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .base-icon {
      margin-right: 10px;
      flex-shrink: 0;
    }
  }

  &__col {
    flex-shrink: 1;
    width: 50%;
    flex-grow: 1;
  }
}
</style>
