<template>
  <div class="editable-test">
    <div class="editable-test__spinner" v-if="pending">
      <base-spinner size="50px"></base-spinner>
    </div>
    <div v-else>
      <div class="editable-test__variants-wrap">
        <editable-variant
          v-for="(variant, index) in currentVariants"
          :key="variant._id"
          :index="index"
          :variant="variant"
          @toggleOpen="toggle"
          @removeVariant="delVariant"
          @uploadFile:open="uploadProxy"
          @test="test"
          :bodyIsShow="isShow(variant._id)"
        />
      </div>

      <base-button theme="dashed" @click="createNewVariant">
        <base-icon glyph="#icon-plus" width="16px" />Добавить вариант
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ModalNewVariant from '@/components/Modal/ModalNewVariant';
import EditableVariant from '@/components/Variant/EditableVariant';

export default {
  name: 'EditableTest',
  props: {
    block: Object,
  },
  components: {
    EditableVariant,
  },
  data() {
    return {
      pending: false,
      openVariant: -1,
    };
  },
  computed: {
    ...mapGetters('variants', { variants: 'list' }),

    currentVariants() {
      return this.variants.filter((variant) => this.block.variants.includes(variant._id));
    },
  },
  methods: {
    ...mapActions('variants', ['fetchVariants', 'addVariant', 'removeVariant']),
    ...mapActions('lessonBlocks', ['fetchBlocks']),

    toggle(id) {
      this.openVariant = this.openVariant === id ? -1 : id;
    },

    isShow(id) {
      return this.openVariant === id;
    },

    uploadProxy(e) {
      this.$emit('uploadFile:open', ...arguments);
    },

    async pushVariant(variant) {
      const { addVariant, block, fetchBlocks } = this;

      if (variant) {
        await addVariant({
          block: block._id,
          type: variant.practiceType,
          difficulty: variant.difficulty,
        });

        fetchBlocks({ filter: { _id: block._id } });
      }
    },
    async delVariant(id) {
      const { removeVariant, block, fetchBlocks } = this;

      if (id) {
        await removeVariant({ _id: id });
      }
    },

    createNewVariant() {
      const { pushVariant } = this;

      this.$emit('vuedals:new', {
        name: 'create-variant',
        component: ModalNewVariant,
        dismissable: false,
        size: 'sm',
        props: {
          handleConfirm: (variant) => this.pushVariant(variant),
        },
      });
    },
    test(e) {
      console.log('this is emitted', e);
      this.$emit('test', e);
    },
  },
  mounted() {
    const { block, fetchVariants } = this;
    if (block.variants.length) {
      this.pending = true;
      fetchVariants({ block: block._id }).then(() => {
        this.pending = false;
      });
    }
  },
};
</script>

<style lang="less">
.editable-test {
  &__spinner .base-spinner {
    margin: 0 auto;
  }
  &__variants-wrap {
    border-radius: 3px;
    margin-bottom: 10px;
  }
}
</style>
