<template>
  <div class="page-lesson">
    <div class="page-lesson__header">
      <div class="page-lesson__buttons">
        <div class="page-lesson__status">
          <div class="page-lesson__status-text">Статус урока:</div>
          <div class="page-lesson__status-toggle">
            <base-button
              variant="toggle"
              font-size="base"
              :theme="lesson.status === '1' ? 'default' : 'disabled'"
              @click="setStatus('1')"
              >В работе</base-button
            >
            <base-button
              variant="toggle"
              font-size="base"
              :theme="lesson.status === '2' ? 'default' : 'disabled'"
              @click="setStatus('2')"
              >Обработан</base-button
            >
          </div>
        </div>
        <div class="page-lesson__buttons-pending" v-if="pendingBtn">
          <base-spinner size="29px"></base-spinner>
        </div>
        <div class="page-lesson__copy-button" v-else>
          <base-button class="page-lesson__button" theme="default" size="small" @click="downloadNewLesson">
            Скачать урок
          </base-button>
          <base-button class="page-lesson__button" theme="default" size="small" @click="showEditLessonModal">
            Настройки урока
          </base-button>
          <base-button
            v-if="false"
            class="page-lesson__button"
            :disabled="true"
            theme="default"
            size="small"
            @click="addLessonToOshPlatform"
          >
            Добавить на ОШ
          </base-button>
          <base-button
            v-if="false"
            class="page-lesson__button"
            :disabled="true"
            theme="default"
            size="small"
            @click="addLessonToAcmPlatform"
          >
            Добавить на АСМ
          </base-button>
          <base-button
            v-if="isOpenschool"
            class="page-lesson__button"
            :disabled="pendingButton"
            theme="default"
            size="small"
            @click="copyToAcmParser"
          >
            Скопировать на ACM парсер
          </base-button>
        </div>
      </div>
    </div>
    <div class="page-lesson__row">
      <lesson-view
        class="page-lesson__col"
        colId="col-1"
        :pending="pending"
        :lesson="lesson"
        :blocksList="blocksList"
        startTab="blocks"
        :subject="subject"
        :subjectArea="subjectArea"
        @scroll-blocks="scrollBlocks"
      />
      <lesson-view
        class="page-lesson__col"
        colId="col-2"
        :pending="pending"
        :lesson="lesson"
        :blocksList="blocksList"
        startTab="new"
        :subject="subject"
        :subjectArea="subjectArea"
        @scroll-blocks="scrollBlocks"
      />
    </div>
    <vuedal></vuedal>
    <div class="modal-path" v-if="showModal">
      <div class="modal-path__wrapper" @click="closeModal"></div>
      <div class="modal-path__block-info">
        <p class="modal-path__info">Нажмите на ссылку чтобы перейти к уроку</p>
        <a v-for="link of links" :key="link" target="_blank" :href="link">{{ link }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Component as Vuedal } from 'vuedals';

import LessonView from '@/components/LessonView';
import { HTTP } from '@/http';
import ModalEditLesson from '@/components/Modal/ModalEditLesson';

export default {
  name: 'PageLesson',
  components: {
    Vuedal,
    // eslint-disable-next-line vue/no-unused-components
    ModalEditLesson,
    LessonView,
  },
  data() {
    return {
      pending: false,
      pendingButton: false,
      lesson: {},
      subject: null,
      subjectArea: null,
      section: 0,
      pendingBtn: false,
      links: [],
      showModal: false,
    };
  },
  computed: {
    ...mapGetters('lessonBlocks', { blocksList: 'list' }),
    ...mapGetters('subjects', ['subjects']),
    isOpenschool() {
      return window.location.host.includes('localhost') || window.location.host.includes('2035');
    },
  },
  methods: {
    ...mapActions('lessonBlocks', ['fetchBlocks']),
    ...mapActions('lessons', ['fetchLessons', 'copyLesson', 'changeLesson']),
    ...mapActions('tags', ['fetchGroups', 'fetchTags']),
    ...mapActions('subjects', ['fetchSubjects', 'fetchSubjectAreas']),
    ...mapActions('sections', ['fetchSections']),
    ...mapActions('variants', ['fetchVariantsBulk']),

    ...mapActions({
      downloadLessonArchive: 'lessons/downloadLessonArchive',
    }),
    showEditLessonModal() {
      this.$emit('vuedals:new', {
        name: 'modal-edit-lesson',
        component: ModalEditLesson,
        props: {
          lesson: this.lesson,
          subject: this.subject,
        },
        dismissable: false,
        size: 'xl',
      });
    },
    async copyToAcmParser() {
      try {
        this.pendingButton = true;
        const { lessonId } = this.$route.params;
        const link = await this.$store.dispatch('lessons/copyLessonToKzParser', { lessonId });
        this.links = [link];
        this.showModal = true;
        this.pendingButton = false;
        alert('Урок был успешно продублирован на ACM парсер');
      } catch (e) {
        console.log(e);
        alert('Ошибка при дублировании урока на ACM парсер');
      }
    },
    closeModal() {
      this.showModal = false;
      this.links = [];
    },
    scrollBlocks(id) {
      const splitId = id.split('_');
      let colId = '';
      colId = splitId[0] === 'col-1' ? 'col-2' : 'col-1';

      let element = document.getElementById(`${colId}_preview_${splitId[1]}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'auto',
          block: 'start',
          inline: 'start',
        });
      }

      element = document.getElementById(`${colId}_block_${splitId[1]}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'auto',
          block: 'start',
          inline: 'start',
        });
      }
    },
    async createNewLesson() {
      this.pendingBtn = true;
      const newId = await this.copyLesson({ _id: this.lesson._id });
      this.pendingBtn = false;
      if (newId) {
        this.$router.push({
          name: 'edit',
          params: { lessonId: newId },
        });
      }
    },
    async addLessonToAcmPlatform() {
      try {
        this.pendingButton = true;
        const isConfirm = confirm('Вы уверены, что хотите добавить урок на платформу?');
        if (!isConfirm) {
          this.pendingButton = false;
          return;
        }
        const linkErg = await this.addLessonToMainPlatform('erg');
        const linkAlmaty = await this.addLessonToMainPlatform('almaty');
        this.links = [...linkErg, ...linkAlmaty];
        this.showModal = true;
        this.pendingButton = false;
      } catch (e) {
        console.log(e);
        alert('Произошла ошибка при добавлении урока на ACM платформы');
        this.showModal = false;
        this.pendingButton = false;
      }
    },
    async addLessonToOshPlatform() {
      try {
        this.pendingButton = true;
        const isConfirm = confirm('Вы уверены, что хотите добавить урок на платформу?');
        if (!isConfirm) {
          this.pendingButton = false;
          return;
        }
        const links = await this.addLessonToMainPlatform('osh');
        this.links = links;
        this.showModal = true;
        this.pendingButton = false;
      } catch (e) {
        console.log(e);
        alert('Произошла ошибка при добавлении урока на ОШ платформу.');
        this.showModal = false;
        this.pendingButton = false;
      }
    },
    async addLessonToMainPlatform(platform) {
      try {
        const { links } = await this.$store.dispatch('lessons/addToPlatform', { _id: this.lesson._id, platform });
        return links;
      } catch (e) {
        console.log(e);
        throw Error(e);
      }
    },
    async downloadNewLesson() {
      this.pendingBtn = true;
      const newId = await this.copyLesson({ _id: this.lesson._id });
      this.pendingBtn = false;
      await this.downloadLessonArchive({ lessonId: newId });
      try {
        const params = {
          lessonId: newId,
        };
        await HTTP.delete('/api/lessonDelete', { params });
      } catch (error) {
        console.log('error', error);
      }
    },
    setStatus(status) {
      this.lesson.status = status;
      this.changeLesson({ _id: this.lesson._id, status });
    },
  },
  //
  async created() {
    const {
      fetchGroups,
      fetchTags,
      fetchLessons,
      fetchBlocks,
      fetchSubjects,
      fetchSubjectAreas,
      fetchSections,
      fetchVariantsBulk,
    } = this;
    const { lessonId } = this.$route.params;
    this.pending = true;
    try {
      const [groups, tags, lessonResponse] = await Promise.all([
        fetchGroups(),
        fetchTags(),
        fetchLessons({ filter: { _id: lessonId } }),
        fetchSections(),
        fetchSubjects(),
      ]);
      this.lesson = lessonResponse[0];

      if (this.lesson.blocks.length) {
        const blocks = await fetchBlocks({
          filter: { _id: { $in: this.lesson.blocks } },
        });

        // TODO: ! возможно  стоит здесь собрать варианты для блоков. И запросить их
        // done:
        // переписать т.к. запрашивает разом 28к id

        const vars = blocks.filter((el) => el.variants && el.variants.length).flatMap((el) => el.variants);
        if (vars.length) {
          await fetchVariantsBulk({ filter: { _id: vars } });
        }
      }

      if (this.lesson.subject) {
        this.subject = this.subjects.find((s) => s._id === this.lesson.subject);
      }
      if (this.lesson.subjectArea) {
        this.subjectArea = (await fetchSubjectAreas({ filter: { _id: this.lesson.subjectArea } }))[0];
      }
      this.pending = false;
    } catch (err) {
      console.log('err', err);
    }
  },
};
</script>

<style lang="less">
@import '~less_vars';

.modal-path {
  z-index: 3;
  width: 500px;
  margin: 0 auto;
  &__wrapper {
    position: fixed;
    z-index: 2;
    opacity: 0.5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #8a8a8a;
  }
  &__block-info {
    z-index: 5;
    top: 0;
    position: fixed;
    background-color: white;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    a {
      word-break: break-word;
    }
  }
  &__info {
    margin: 0 auto 20px;
  }
}

.page-lesson {
  height: 100%;

  &__header {
    margin-top: -20px;
    padding: 15px 0;
  }
  &__button {
    height: 36px !important;
  }
  &__copy-button {
    display: flex;
    gap: 10px;
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &-pending {
      display: inline-block;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    margin-right: 20px;

    &-text {
      font-size: 18px;
      margin-right: 20px;
    }

    &-toggle {
      border-radius: 3px;
      overflow: hidden;
      display: inline-block;
    }
  }

  &__old-lesson {
    margin-right: 20px;
  }

  &__create-lesson {
    height: 36px !important;
  }

  &__row {
    display: flex;
    height: calc(100% - 42px);
    margin: 0 -20px;
    scroll-behavior: smooth;
  }

  &__col {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    &:last-child {
      margin-right: 20px;
    }

    // &:first-of-type {
    //   margin-right: 40px;
    // }

    &-tabs {
      display: flex;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      margin-bottom: -4px;
    }

    &-tab {
      padding: 20px 20px;
      width: 33.33%;
      font-size: @big-fz;
      border-radius: 3px;
      cursor: pointer;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);

      &:hover,
      &.active {
        color: @primary-color;
        background: #f5fafc;
        z-index: 1;
      }
    }

    &-body {
      overflow-y: hidden;
      flex: 1;
      background: @gray-hover;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      border-radius: 0 0 3px 3px;
      z-index: 2;

      & > div,
      & > div > div {
        height: 100%;
      }
    }
  }

  &__col-body &__preview-old {
    iframe {
      position: absolute;
      top: 0;
      width: 160%;
      height: 160%;
      transform: scale(0.6);
      transform-origin: top left;
    }
  }

  &__title {
    margin: 0;
    padding: 20px;
    font-size: 32px;
  }

  &__pending {
    padding: 15px;

    .base-spinner {
      margin: 0 auto;
    }
  }

  &__preview {
    &-blocks {
      overflow-y: auto;
      padding: 0 20px 20px;
    }
    &-new {
      overflow-y: auto;
    }
    &-old {
      position: relative;

      &__pending {
        padding: 15px;

        .base-spinner {
          margin: 0 auto;
        }
      }
    }
  }

  &__subjects {
    padding: 0 20px;
  }

  &__subject {
    display: inline-block;
    background: #5870e9;
    padding: 5px 10px;
    color: #fff;
    border-radius: 20px;
    font-size: @medium-fz;
    margin-right: 10px;
  }

  &__subject-area {
    display: inline-block;
    background: #f2c94c;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: @medium-fz;
    margin-right: 10px;
  }
}
</style>
