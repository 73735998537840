import axios from 'axios';
import Qs from 'qs';

(function Authorization() {
  if (!localStorage.getItem('parser_token')) {
    const user = prompt('Введите пользователя:');
    const pass = prompt('Введите пароль:');
    if (user == 'parser' && pass == '%$#21DaY') {
      const auth = 'Basic ' + Buffer.from(user + ':' + pass).toString('base64');
      localStorage.setItem('parser_token', auth)
    } else {
      alert('Пароль или пользователь неверны.');
      return Authorization();
    }
  }
})();

const token = localStorage.getItem('parser_token');

export const HTTP = axios.create({
  headers: {
    Authorization: token,
  },
  paramsSerializer(params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' });
  },
});
