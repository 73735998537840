<template>
  <base-modal title="Редактирование урока" class="modal-new-lesson">
    <div slot="modal-body">
      <div class="modal-new-lesson__row">
        <div class="select-wrapper modal-new-lesson__object">
          <label>Предмет</label>
          <multiselect
            class="multiselect--theme-combobox"
            placeholder="Предмет"
            :value="lessonData.subject"
            :options="subjects"
            track-by="_id"
            label="name"
            :searchable="false"
            :show-labels="false"
            @input="handleChangeSubject"
          />
        </div>
      </div>

      <div class="modal-new-lesson__row">
        <div class="input-wrapper">
          <label>Порядковый номер урока</label>
          <base-input
            width="100%"
            placeholder="Введите целое неотрицательное число"
            type="number"
            required
            :value="lesson.idx"
            @input="handleChangeLessonIdx"
          />
        </div>
      </div>
    </div>

    <div slot="modal-footer" v-if="lesson">
      <div class="modal-new-lesson__properties">
        <div class="modal-new-lesson__preview-row"></div>

        <div class="modal-new-lesson__row">
          <div class="input-wrapper">
            <label>Название</label>
            <base-input
              width="100%"
              placeholder="Название"
              type="text"
              required
              :value="lesson.name"
              @input="handleChangeLessonName"
            />
          </div>
        </div>

        <div class="modal-new-lesson__row">
          <div class="input-wrapper">
            <label>Путь</label>
            <base-input
              width="100%"
              placeholder="Путь"
              type="text"
              required
              :value="lesson.path"
              @input="handleChangeLessonPath"
              :class="{ error: false || !lesson.path }"
            />
            <div class="modal-new-lesson__error" v-if="false && lesson.path">Такой путь уже существует</div>
          </div>
        </div>

        <div class="modal-new-lesson__row modal-new-lesson__footer">
          <base-button size="large" @click="handleSaveLessonData">
            <span>Сохранить</span>
          </base-button>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalEditLesson',
  components: {
    Multiselect,
  },
  props: {
    lesson: Object,
    subject: Object,
  },
  data() {
    return {
      lessonData: {
        subject: null,
        name: null,
        idx: null,
        path: null,
      },
    };
  },
  computed: {
    ...mapGetters('subjects', ['subjects']),
  },
  methods: {
    handleChangeSubject(subject) {
      this.lessonData.subject = subject;
      console.log(subject);
    },
    handleChangeLessonIdx(event) {
      this.lessonData.idx = Number(event.target.value);
    },
    handleChangeLessonName(event) {
      console.log(event);
      this.lessonData.name = event.target.value;
    },
    handleChangeLessonPath(event) {
      console.log(event);
      this.lessonData.path = event.target.value;
    },
    getLessonDataObject() {
      const filterObj = {
        _id: this.lesson._id,
      };
      for (const field in this.lessonData) {
        if (this.lessonData[field]) {
          if (field === 'subject') {
            filterObj[field] = this.lessonData[field]._id;
            continue;
          }
          filterObj[field] = this.lessonData[field];
        }
      }
      return filterObj;
    },
    async handleSaveLessonData() {
      try {
        const filterObj = this.getLessonDataObject();
        await this.$store.dispatch('lessons/changeLesson', filterObj);
        alert('Данные урока успешно обновлены');
        this.$emit('vuedals:close');
      } catch (e) {
        console.log(e);
        alert('Произошла непредвиденная ошибка при изменении данных урока');
        this.$emit('vuedals:close');
      }
    },
  },
  created() {
    this.lessonData.subject = this.subject;
  },
};
</script>

<style lang="less">
@import '~less_vars';
.modal-new-lesson {
  max-height: 90vh;
  overflow-y: auto;

  .base-modal,
  .base-modal__body {
    overflow: visible;
  }

  &__row {
    margin: 30px -5px;
    display: flex;
    align-items: flex-start;

    &:last-child {
      margin-bottom: 0;
    }

    .base-btn {
      margin: 15px 5px 0;
      display: inline-block;
    }

    .select-wrapper {
      position: relative;
      display: inline-block;
      margin: 15px 5px 0;

      label {
        position: absolute;
        top: -25px;
        left: 5px;
        color: #66626e;
        font-size: 16px;
      }
    }

    &.add-new-group {
      .base-btn {
        width: 100%;
      }

      .select-wrapper {
        width: 300px;
      }

      .add-new-group__remove {
        width: 20px;
        position: absolute;
        right: -30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        .base-icon {
          --primaryIconColor: #c8c1d9;
          --secondaryIconColor: #c8c1d9;

          &:hover {
            --primaryIconColor: rgb(69, 7, 202);
            --secondaryIconColor: #fc005c;
          }
        }
      }
    }
  }

  &__object,
  &__sub-object {
    width: calc(40% - 10px);
  }

  .input-wrapper {
    position: relative;
    margin: 15px 5px 0;
    width: calc(100% - 10px);

    label {
      position: absolute;
      top: -25px;
      left: 5px;
      color: #66626e;
      font-size: 15px;
    }
  }

  &__properties {
    background: #f1f5f8;
    padding: 20px 30px 50px;

    &-info {
      position: relative;
      flex: 1 1 auto;

      &__title {
        font-size: 24px;
        color: #35323d;
        font-weight: 600;
        line-height: 3;
      }

      &__description {
        font-size: 15px;
        color: #66626e;
        line-height: 23px;
        margin-bottom: 20px;
      }

      .img-upload,
      .notes-upload {
        display: inline-block;
        margin-bottom: 5px;
        //position: absolute;
        //bottom: 5px;
        //left: 0;

        .vue-dropzone {
          display: none;
        }

        .spinner-wrap {
          position: absolute;
          right: -60px;
        }

        .file-upload__error {
          margin-top: 10px;
        }
      }
    }
  }

  &__properties > &__preview-row {
    display: flex;
    margin-bottom: 50px;
  }

  &__error {
    padding-top: 10px;
    color: red;
  }

  &__footer {
    justify-content: space-between;
  }
}
</style>
