import { HTTP } from '@/http';

const subjects = {
  namespaced: true,
  state: {
    ids: [],
    subjectAreas: [],
    subjectThemes: [],
  },
  mutations: {
    FETCH_SUBJECTS: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    FETCH_AREAS: (state, payload) => {
      state.subjectAreas = [...new Set([...state.subjectAreas, ...payload])];
    },
    FETCH_THEMES: (state, payload) => {
      state.subjectThemes = [...new Set([...state.subjectThemes, ...payload])];
    },
  },
  getters: {
    subjects(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.subjects[id]);
    },
    subjectAreas(state, getters, rootState) {
      return state.subjectAreas.map((id) => rootState.DB.subjectAreas[id]);
    },
    subjectThemes(state, getters, rootState) {
      return state.subjectThemes.map((id) => rootState.DB.subjectThemes[id]);
    },
  },
  actions: {
    fetchSubjects({ state, commit }, params) {
      return HTTP.get('/crud/subject', { params }).then((response) => {
        commit(
          'FETCH_SUBJECTS',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'subjects',
            payload: response.data.models,
          },
          { root: true }
        );
        return response.data.models;
      });
    },
    fetchSubjectAreas({ state, commit }, params) {
      return HTTP.get('/crud/subjectarea', { params }).then((response) => {
        commit(
          'FETCH_AREAS',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'subjectAreas',
            payload: response.data.models,
          },
          { root: true }
        );
        return response.data.models;
      });
    },
    fetchSubjectThemes({ state, commit }, params) {
      return HTTP.get('/crud/subjecttheme', { params }).then((response) => {
        commit(
          'FETCH_THEMES',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'subjectThemes',
            payload: response.data.models,
          },
          { root: true }
        );
        return response.data.models;
      });
    },
    addSubjectsTheme({ state, commit }, params) {
      return HTTP.post('/crud/subjecttheme', { ...params }).then((response) => {
        if (response.data._id) {
          commit('FETCH_THEMES', [response.data._id]);
          commit('CREATE_THEME', response.data);
          commit(
            'FETCH_MODELS',
            {
              type: 'subjectsThemes',
              payload: [response.data],
            },
            { root: true }
          );
          return response.data;
        }
      });
    },
  },
};

export default subjects;
