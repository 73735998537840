import { HTTP } from '@/http';

const lessonBlocks = {
  namespaced: true,
  state: {
    ids: [],
    blockTypes: {
      text: 'Текстовый блок',
      gallery: 'Галерея',
      video: 'Видео',
      test: 'Задание',
    },
  },
  mutations: {
    FETCH_BLOCKS: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    REMOVE_BLOCK: (state, id) => {
      const idx = state.ids.indexOf(id);
      if (idx !== -1) {
        state.ids.splice(idx, 1);
      }
    },
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.lessonBlocks[id]);
    },
    typeList(state) {
      return Object.keys(state.blockTypes).map((n) => ({
        type: n,
        label: state.blockTypes[n],
      }));
    },
  },
  actions: {
    fetchBlocks({ state, commit, dispatch }, params) {
      return HTTP.get('/crud/lessonblock', { params }).then((response) => {
        const { models } = response.data;
        commit(
          'FETCH_BLOCKS',
          models.map((n) => n._id)
        );
        dispatch('addBlocksToStore', models);
        return models;
      });
    },

    addBlock({ state, commit, dispatch }, params) {
      return HTTP.post('/api/createlessonblock', { ...params }).then((response) => {
        if (response.data.lessonBlock._id) {
          commit('FETCH_BLOCKS', [response.data.lessonBlock._id]);
          dispatch('addBlocksToStore', [response.data.lessonBlock]);
          return response.data.lessonBlock;
        }
      });
    },

    changeBlock({ state, commit, dispatch }, params) {
      return HTTP.post('/crud/lessonblock', { ...params }).then((response) => {
        if (response.data._id) {
          commit('FETCH_BLOCKS', [response.data._id]);
          dispatch('addBlocksToStore', [response.data]);
          return response.data;
        }
      });
    },
    removeBlock({ state, commit }, params) {
      HTTP.delete('/crud/lessonblock', { data: { ...params } })
        .then((response) => {
          commit('REMOVE_BLOCK', params._id);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    swapBlocks({ state, commit, dispatch }, params) {
      HTTP.post('/api/swapblocks', { ...params }).then((response) => {
        const { blocks } = response.data;
        if (blocks.length) {
          commit(
            'FETCH_BLOCKS',
            blocks.map((n) => n._id)
          );
          dispatch('addBlocksToStore', blocks);
        }
      });
    },
    idxUpdate({ state, commit, dispatch }, params) {
      HTTP.post('/api/changeblocksidx', { ...params }).then((response) => {
        const { blocks } = response.data;
        if (blocks.length) {
          commit(
            'FETCH_BLOCKS',
            blocks.map((n) => n._id)
          );
          dispatch('addBlocksToStore', blocks);
        }
      });
    },
    getStructure(context, params) {
      if (!params.lesson) {
        return Promise.reject(new Error('lesson is required'));
      }

      return new Promise((resolve, reject) => {
        HTTP.get('/api/getstructure', { params })
          .then((response) => {
            const { error } = response.data;
            if (error) {
              reject(error);
            } else {
              resolve(response.data);
            }
          })
          .catch((error) => reject(error));
      });
    },
    addStructureBlock(context, payload) {
      return new Promise((resolve, reject) => {
        HTTP.post('/api/addstructureblock', payload)
          .then((response) => {
            const { error } = response.data;
            if (error) {
              reject(error);
            } else {
              resolve(response.data);
            }
          })
          .catch((error) => reject(error));
      });
    },
    deleteStructureBlock(context, id) {
      return new Promise((resolve, reject) => {
        HTTP.post('/api/removestructureblock', {
          structure: id,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    toggleStructureVisible(context, id) {
      return new Promise((resolve, reject) => {
        HTTP.post('/api/togglestructureblockvisible', {
          structure: id,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBlockbyId({ commit, dispatch }, id) {
      return new Promise((resolve, reject) => {
        dispatch(
          'fetchModel',
          {
            model: 'lessonblock',
            options: {
              params: {
                filter: {
                  _id: id,
                },
              },
            },
          },
          { root: true }
        )
          .then((response) => {
            const { models } = response.data;
            if (models) {
              dispatch('addBlocksToStore', models);
              resolve(models[0]);
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    swapStructureBlock(context, payload) {
      return new Promise((resolve, reject) => {
        HTTP.post('/api/swapstructureblock', payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    /**
     * !TODO
     * Очень много записей
     * формата "Сложить итемы в DB стора".
     * схлопнул их в 1 функцию.
     * Вообще надо было в рутовом фетче писать эту логику....
     * Есть экшен в руте FetchModels
     * Вот он и должен отвечать за вызов мутации для записи в store.DB
     */
    addBlocksToStore({ commit }, payload) {
      commit(
        'FETCH_MODELS',
        {
          type: 'lessonBlocks',
          payload,
        },
        { root: true }
      );
    },
    changeBlockOrder({ commit }, payload) {
      return new Promise((resolve, reject) => {
        HTTP.post('/api/changeblockorder', payload)
          .then((response) => {
            if (response.data.error) {
              reject(response.data.error);
              return;
            }
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};

export default lessonBlocks;
