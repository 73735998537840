<template>
  <div class="editable-gap-answers">
    <div class="editable-gap-answers__title">
      <switcher
        class="my-10"
        :values="[
          { name: 'HTML', value: 'html' },
          { name: 'Редактор', value: 'wysiwyg' },
        ]"
        :selected="practice && practice.contentType"
        @switcher:change="handleChangeType"
      ></switcher>
      <plain-html-editor
        class="mb-10"
        :content="parsedQuestion"
        @setContent="saveText"
        v-if="practice.contentType === 'html'"
      ></plain-html-editor>
      <base-editor :value="parsedQuestion" v-else placeholder="Условие" @save-text="saveText" />
      <base-checkbox v-model="practice.showAll" @input="$emit('set-question', { showAll: $event })" class="mb-20"
        >Расположить ответы горизонтально</base-checkbox
      >
    </div>
  </div>
</template>

<script>
import PlainHtmlEditor from '@/components/Common/PlainHtmlEditor';
import Switcher from '@/components/Common/Switcher';

export default {
  name: 'EditableGapAnswers',
  components: {
    PlainHtmlEditor,
    Switcher,
  },
  data() {
    return {
      parsedQuestion: '',
    };
  },
  watch: {
    'practice.question': {
      immediate: true,
      handler(val) {
        this.setParsedQuestion(val);
      },
    },
  },
  props: {
    practice: Object,
    type: String,
  },
  methods: {
    saveText(text) {
      if (this.parsedQuestion === text) return;
      this.$emit('set-question', { question: text });
      console.count('EditableGapAnswers_savetext');
    },
    handleChangeType(type) {
      if (confirm('Текст вопроса, при смене типа контента будет удален.\nВсе равно поменять тип контента?')) {
        this.$emit('set-question', { contentType: type, question: '' });
        this.parsedQuestion = '';
      }
    },
    setParsedQuestion() {
      const { question, answers } = this.practice;
      if (question) {
        let parsedQuestion = this.practice.question;
        if (answers && answers.length > 0) {
          answers.forEach((answerData) => {
            const { variable, answer, subString, correct } = answerData;
            const str = subString ? `${answer}!${subString}` : answer;
            if (correct) {
              const re = new RegExp(`#{(${variable}?)\\|(.*?)}`, 'i');
              const found = parsedQuestion.match(re);
              if (found) {
                let inputType = '';
                switch (found[2]) {
                  case 'short': {
                    inputType = 's';
                    break;
                  }
                  case 'long': {
                    inputType = 'l';
                    break;
                  }
                  default:
                    break;
                }
                parsedQuestion = parsedQuestion.replace(re, `#${inputType}{${str}}`);
              }
              parsedQuestion = parsedQuestion.replace(`#{${variable}}`, `#{${str}}`);
            } else {
              parsedQuestion += `<p>!{${str}}</p>`;
            }
          });
        }
        this.parsedQuestion = parsedQuestion;
      } else {
        this.parsedQuestion = '';
      }
    },
  },
  mounted() {
    this.setParsedQuestion();
  },
};
</script>

<style lang="less">
@import '~less_vars';

.editable-gap-answers {
  padding: 10px;

  &:selection {
    background-color: red;
  }
}
</style>
