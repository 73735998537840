<template>
  <div class="unit-tag">
    <span><slot /></span>
  </div>
</template>

<script>
export default {
  name: 'UnitTag',
  props: {
    text: String,
    required: true,
  },
};
</script>

<style lang="less" scoped>
.unit-tag {
  padding: 0 10px;
  border: 1px dotted #c0c0c0;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 5px;
  line-height: 32px;
  min-width: 60px;
  text-align: center;
  height: 32px;

  span {
    max-width: 200px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: fade(#000, 40%);
    font-weight: bold;
  }
}
</style>
