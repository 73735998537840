<template>
  <div class="editable-choosed-answers">
    <div class="editable-choosed-answers__title">
      <switcher
        class="my-10"
        :values="[
          { name: 'HTML', value: 'html' },
          { name: 'Редактор', value: 'wysiwyg' },
        ]"
        :selected="practice && practice.contentType"
        @switcher:change="handleChangeType"
      ></switcher>
      <plain-html-editor
        class="mb-10"
        :content="practice.question"
        @setContent="saveText"
        v-if="practice.contentType === 'html'"
      ></plain-html-editor>

      <base-editor v-else :value="practice.question" placeholder="Условие" @save-text="saveText" />
    </div>
    <div class="editable-choosed-answers__body">
      <div v-if="practice.questions" class="editable-choosed-answers__body-titles">
        <div class="editable-choosed-answers__body-title">Утверждение</div>
        <div class="editable-choosed-answers__body-title">Ответ</div>
      </div>
      <div class="editable-choosed-answers__question" v-for="question in practice.questions" :key="question._id">
        <!-- <base-input
          placeholder="Утвверждение"
          type="text"
          :value="question.question"
          @blur="setText($event, question._id)"
        />-->
        <base-editor :value="question.question" placeholder="Утверждение" @save-text="setText($event, question._id)" />

        <base-checkbox v-model="question.answer" @input="setCheckbox($event, question._id, question.question)" />

        <div class="editable-choosed-answers__question-remove" @click="$emit('deleteQuestion', question._id)">
          <base-icon glyph="#icon-close" variant="hover" width="20px" />
        </div>
      </div>
    </div>
    <div class="editable-choosed-answers__new-question">
      <base-button theme="dashed" @click="pushNewQuestion">
        <base-icon glyph="#icon-plus" width="16px" />Добавить утверждение
      </base-button>
    </div>
  </div>
</template>

<script>
import PlainHtmlEditor from '@/components/Common/PlainHtmlEditor';
import Switcher from '@/components/Common/Switcher';

export default {
  name: 'EditableChoosedAnswers',
  components: {
    PlainHtmlEditor,
    Switcher,
  },
  props: {
    practice: Object,
    type: String,
  },
  methods: {
    saveText(text) {
      this.$emit('set-question', { titleQuestion: text });
      console.count('EditableChoosedAnswers_savetext');
    },
    pushNewQuestion() {
      this.$emit('set-question', { titleQuestion: this.practice.question });
      this.$emit('set-question', { question: '', answer: false });
      console.count('EditableChoosedAnswers_pushNewQuestion');
    },
    setText(value, questionId) {
      this.$emit('set-question', { question: value }, questionId);
      console.count('EditableChoosedAnswers_setText');
    },
    setCheckbox(value, questionId, question) {
      this.$emit('set-question', { question, answer: value }, questionId);
      console.count('EditableChoosedAnswers_setCheckbox');
    },
    handleChangeType(type) {
      if (confirm('Текст вопроса, при смене типа контента будет удален.\nВсе равно поменять тип контента?')) {
        let question = '';
        if (type === 'html') {
          question = this.practice.question;
        }
        this.$emit('set-question', { contentType: type });
        this.practice.question = question;
      }
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';
.editable-choosed-answers {
  padding: 10px;

  &__question {
    display: flex;
    margin-bottom: 10px;

    &-remove {
      margin-left: 10px;
      cursor: pointer;
    }

    .base-checkbox {
      margin-left: 20px;
    }
  }

  &__body-titles {
    display: flex;
    justify-content: space-between;
  }

  &__body-title {
    color: @gray-dark;
    font-size: 0.8em;
    padding: 10px 0;

    &:last-child {
      width: 60px;
    }
  }
}
</style>
