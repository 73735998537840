<template>
  <div class="editable-lock-answers">
    <div class="editable-lock-answers__settings">
      <span>Столбцы:</span>
      <multiselect
        placeholder="Столбцы"
        v-model="cols"
        :options="[1, 2, 3]"
        :allow-empty="false"
        :searchable="false"
        :show-labels="false"
        @input="updateTable"
        id="cols"
        openDirection="bottom"
      />
      <span>Строки:</span>
      <multiselect
        placeholder="Строки"
        v-model="rows"
        :options="[1, 2, 3, 4, 5]"
        :allow-empty="false"
        :searchable="false"
        :show-labels="false"
        @input="updateTable"
        id="rows"
        :disabled="cols < 1"
        openDirection="bottom"
      />
    </div>
    <div class="editable-lock-answers__table" v-if="practice.answers.length && practice.answers[0].rows.length">
      <div v-for="col in practice.answers" :key="`col-${col.x}`" class="editable-lock-answers__col">
        <div v-for="row in col.rows" :key="`row-${row.y}`" class="editable-lock-answers__row">
          <base-input placeholder="Ответ" type="text" name="name" :value="row.value" @blur="setText($event, col.x, row.y)" />
        </div>
      </div>
    </div>
    <div v-else class="editable-lock-answers__empty-table">Создайте таблицу</div>

    <div class="editable-lock-answers__title">Фоновое изображение:</div>
    <div class="editable-lock-answers__bg-img">
      <span v-if="practice.image">{{ practice.image }}</span>
      <span v-else>Не задано</span>
      <div class="editable-lock-answers__edit-btn" @click="handleBgEdit()">
        <base-icon glyph="#icon-pencil" width="16px" />
      </div>
    </div>

    <div class="editable-lock-answers__gallery" v-if="practice.answers.length > 0">
      <div class="editable-lock-answers__title">Галерея (открыта одна группа):</div>
      <editable-lock-gallery :list="practice.gallery1" @save="saveGallery('gallery1', $event)" @uploadFile:open="uploadProxy" />
    </div>
    <div class="editable-lock-answers__gallery" v-if="practice.answers.length > 1">
      <div class="editable-lock-answers__title">Галерея (открыто две группы):</div>
      <editable-lock-gallery :list="practice.gallery2" @save="saveGallery('gallery2', $event)" @uploadFile:open="uploadProxy" />
    </div>
    <div class="editable-lock-answers__gallery" v-if="practice.answers.length > 2">
      <div class="editable-lock-answers__title">Галерея (открыто три группы):</div>
      <editable-lock-gallery :list="practice.gallery3" @save="saveGallery('gallery3', $event)" @uploadFile:open="uploadProxy" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import EditableLockGallery from './EditableLockGallery';

export default {
  name: 'EditableLockAnswers',
  components: {
    Multiselect,
    EditableLockGallery,
  },
  props: { practice: Object },
  data() {
    return {
      rows: 0,
      cols: 0,
      pending: false,
    };
  },
  computed: {
    ...mapGetters('images', { listImg: 'list' }),
  },
  methods: {
    ...mapActions('images', ['fetchImages', 'addImage', 'removeImage']),

    updateTable(value, id) {
      if (id === 'cols') {
        const currentCols = this.practice.answers.length;
        if (value > currentCols) {
          for (let index = currentCols; index < value; index++) {
            const col = [];
            for (let i = 0; i < this.rows; i++) {
              col.push({ y: i, value: '' });
            }
            this.practice.answers.push({ x: index, rows: col });
          }
        } else {
          this.practice.answers = this.practice.answers.slice(0, value);
        }
      } else {
        const currentRows = this.practice.answers[0].rows.length;
        if (value > currentRows) {
          this.practice.answers.map((col) => {
            for (let i = currentRows; i < value; i++) {
              col.rows.push({ y: i, value: '' });
            }
            return col;
          });
        } else {
          this.practice.answers.map((col) => {
            col.rows = col.rows.slice(0, value);
            return col;
          });
        }
      }
      this.$emit('setAnswer', { answers: this.practice.answers });
    },
    parseAnswers() {
      this.cols = this.practice.answers.length;
      this.rows = this.cols > 0 ? this.practice.answers[0].rows.length : 0;
    },
    setText(e, x, y) {
      this.practice.answers[x].rows[y].value = e.target.value;
      this.$emit('setAnswer', { answers: this.practice.answers });
    },
    handleBgEdit() {
      this.$emit('uploadFile:open', this.practice.image, this.imageEdit);
    },
    imageEdit(url) {
      this.practice.image = url;
      this.$emit('setAnswer', { image: this.practice.image });
    },
    uploadProxy(e) {
      this.$emit('uploadFile:open', ...arguments);
    },
    saveGallery(field, gallery) {
      this.practice[field] = gallery;
      this.$emit('setAnswer', { [field]: gallery });
    },
  },
  mounted() {
    this.parseAnswers();
  },
};
</script>

<style lang="less">
@import '~less_vars';
.editable-lock-answers {
  padding: 10px;

  &__settings {
    display: flex;
    align-items: center;

    .multiselect {
      margin-left: 10px;
      margin-right: 20px;
      max-width: 55px;
    }
  }

  &__empty-table {
    margin-top: 10px;
    margin-bottom: 20px;
    color: #8a8a8a;
    font-size: 13px;
  }

  &__table {
    margin: 20px -5px;
    display: flex;
  }

  &__col {
    flex: 1 1 auto;
    padding: 0 5px;
  }

  &__row {
    margin-bottom: 10px;
  }

  &__bg-img {
    color: #8a8a8a;
    font-size: 13px;
    display: flex;
    align-items: center;

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__edit-btn {
    padding: 10px;
    display: inline-block;
    cursor: pointer;
  }
  &__gallery {
    margin-top: 30px;
  }

  &__gallery &__title {
    margin-bottom: 10px;
  }
}
</style>
