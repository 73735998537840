<template>
  <div class="base-mathjax" ref="content">
    <component :is="parseHtml" />
  </div>
</template>

<script>
import AudioBtn from '@/components/Common/AudioBtn';

export default {
  name: 'BaseMathjax',
  props: {
    html: String,
  },
  watch: {
    'window.MathJax': {
      immediate: true,
      handler(val) {
        this.renderMathJax();
      },
    },
    html: {
      immediate: true,
      handler(val) {
        this.renderMathJax();
      },
    },
  },
  computed: {
    parseHtml() {
      return {
        components: { AudioBtn },
        template: `<div>${this.html
          .replace(/<audio class="ql-audio" controls="true"/g, '<AudioBtn')
          .replace(/<\/audio>/g, '</AudioBtn>')}</div>`,
      };
    },
  },
  methods: {
    renderMathJax() {
      if (!window.MathJax) return;
      this.$nextTick(() => {
        window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, this.$refs.content]);
      });
    },
  },
};
</script>

<style lang="less">
.base-mathjax {
  p {
    margin: 0 0 10px 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;

    td {
      border: 1px solid #ccc;
      padding: 5px 10px;
    }
  }

  img {
    max-width: 100%;
  }

  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-justify {
    text-align: justify;
  }

  iframe {
    margin-bottom: 10px;
    height: 500px;
  }
}
</style>
