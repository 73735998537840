<template>
  <div>
    <div v-if="list && list.length">
      <lesson-block
        v-for="(item, idx) in list"
        :id="`perview_${item._id}`"
        :key="item && `lesson-block-${item._id}-${idx}`"
        :block="item"
      />
    </div>
  </div>
</template>

<script>
import LessonBlock from '@/components/LessonBlock';

export default {
  name: 'PreviewTab',
  components: {
    LessonBlock,
  },
  props: {
    blocks: {
      type: Array,
      default: null,
    },
  },
  computed: {
    list() {
      const { blocks } = this;
      return blocks && blocks.filter((n) => n.isVisible);
    },
  },
};
</script>

<style></style>
