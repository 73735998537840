<template>
  <input
    class="base-input"
    :type="type"
    :placeholder="placeholder"
    :title="placeholder"
    :style="applyStyles"
    :name="name"
    :value="value"
    :required="required"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @keyup.enter="$emit('enter', $event)"
    @blur="$emit('blur', $event)"
  />
</template>
<script>
import { toBemModifer } from '@/helpers/index';

export default {
  name: 'BaseInput',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    theme: {
      type: [String, Array],
      default: 'default',
    },
    width: {
      type: String,
      default: '100%',
    },
    name: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    applyClasses() {
      const { pending, disabled, size, theme, variant } = this;
      const baseClass = 'base-btn';
      const result = [
        ...toBemModifer(theme, baseClass, 'theme'),
        ...toBemModifer(size, baseClass, 'size'),
        ...toBemModifer(variant, baseClass, 'variant'),
      ];
    },
    applyStyles() {
      return {
        width: this.width,
      };
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-input {
  height: 42px;
  background-color: #fff;
  border: 1px solid @gray-light;
  border-radius: 2px;
  padding: 10px 15px;
  max-width: 100%;
  transition: border 0.3s;
  font-family: inherit;

  &:focus {
    outline-color: @primary-color;
    outline-width: 2px;
    border: 1px solid @primary-color;
  }

  &:placeholder {
    color: @gray-dark;
    font-weight: 100;
  }

  &:disabled {
    background-color: @gray-light;
  }
}
</style>
