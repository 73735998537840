<template>
  <div class="editable-sequence-answers">
    <switcher
      class="my-10"
      :values="[
        { name: 'HTML', value: 'html' },
        { name: 'Редактор', value: 'wysiwyg' },
      ]"
      :selected="practice && practice.contentType"
      @switcher:change="handleChangeType"
    ></switcher>

    <plain-html-editor
      class="mb-10"
      :content="practice.question"
      @setContent="saveText"
      v-if="practice.contentType === 'html'"
    ></plain-html-editor>

    <base-editor v-else class="mb-10" :value="practice.question" placeholder="Условие" @save-text="saveText" />

    <base-checkbox v-model="practice.horizontal" @input="$emit('set-question', { horizontal: $event })" class="mb-20"
      >Расположить последовательность горизонтально</base-checkbox
    >

    <div class="editable-sequence-answers__answer" v-for="answer in practice.answers" :key="answer._id">
      <div class="editable-sequence-answers__answer-wrap">
        Порядок
        <base-input
          class="editable-sequence-answers__answer-order"
          placeholder="Порядок"
          type="text"
          name="idx"
          :value="answer.idx"
          @blur="setOrder($event, answer._id)"
        />
        <span class="editable-sequence-answers__answer-remove" @click="$emit('deleteAnswer', answer._id)">
          <base-icon glyph="#icon-close" width="20px" />
        </span>
      </div>

      <base-editor
        class="editable-sequence-answers__answer-value"
        :value="answer.value"
        placeholder="Ответ"
        @save-text="setAnswer($event, answer._id)"
      />
    </div>

    <base-button theme="dashed" @click="$emit('setAnswer', { value: '' })">
      <base-icon glyph="#icon-plus" width="16px" />
      Добавить ответ
    </base-button>
  </div>
</template>

<script>
import PlainHtmlEditor from '@/components/Common/PlainHtmlEditor';
import Switcher from '@/components/Common/Switcher';

export default {
  name: 'EditableSequenceAnswers',
  components: { PlainHtmlEditor, Switcher },
  props: { practice: Object },
  methods: {
    saveText(text) {
      this.$emit('set-question', { question: text });
    },
    setOrder(e, answerId) {
      this.$emit('setAnswer', { idx: e.target.value }, answerId);
    },
    setAnswer(value, answerId) {
      this.$emit('setAnswer', { value }, answerId);
    },
    handleChangeType(type) {
      if (confirm('Текст вопроса, при смене типа контента будет удален.\nВсе равно поменять тип контента?')) {
        let question = '';
        if (type === 'html') {
          question = this.practice.question;
        }
        this.$emit('set-question', { contentType: type });
        this.practice.question = question;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';
.editable-sequence-answers {
  padding: 10px;

  &__answer {
    &-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    &-order {
      margin: 0 10px;
    }
    &-value {
      margin-right: 30px;
    }

    &-remove {
      cursor: pointer;
    }
  }
}
</style>
