<template>
  <transition
    name="collapsing"
    class="base-collapsing"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
  >
    <div class="base-collapsing__body" v-show="show">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseCollapsing',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    scrollTop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    beforeEnter(el) {
      el.style.height = '0';
      el.style.overflow = 'hidden';
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.overflow = 'hidden';
    },
    afterEnter(el) {
      el.style.height = 'auto';
      el.style.overflow = 'hidden';

      if (this.scrollTop) el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    },
    beforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.overflow = 'hidden';
    },
    leave(el) {
      setTimeout(() => {
        el.style.height = '0';
        el.style.overflow = 'hidden';
      }, 10);
    },
  },
};
</script>

<style lang="less">
.base-collapsing {
  &__body {
    transition: 0.3s ease;
  }
}
</style>
