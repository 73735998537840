<template>
  <base-modal class="modal-lesson-created" :title="status === 'success' ? 'Урок успешно создан' : 'Не удалось создать урок'">
    <div slot="modal-body">
      <base-button theme="default" class="modal-lesson-created__btn" size="large" v-if="status === 'success'" v-on:click="confirm"
        >Перейти к редактированию</base-button
      >

      <base-button theme="secondary" class="modal-lesson-created__btn" size="large" v-on:click="cancel">Закрыть</base-button>
    </div>
  </base-modal>
</template>

<script>
export default {
  name: 'ModalLessonCreated',
  props: {
    lessonId: String,
    status: {
      type: String,
      default: 'success',
    },
  },
  methods: {
    confirm() {
      this.$router.push({ name: 'lesson', params: { lessonId: this.lessonId } });
      this.$emit('vuedals:close');
    },
    cancel() {
      this.$emit('vuedals:close');
    },
  },
};
</script>

<style lang="less">
.modal-lesson-created {
  &__btn {
    margin-right: 15px;
  }
}
</style>
