<template>
  <div class="editable-gallery">
    <div class="editable-gallery__spinner" v-if="pending">
      <base-spinner size="50px"></base-spinner>
    </div>
    <div v-else>
      <div class="editable-gallery__image-wrap" v-for="img in sortedGallery" :key="img._id">
        <div class="editable-gallery__image-remove" @click="removeImg(img._id)">
          <base-icon glyph="#icon-close" variant="hover" width="18px" height="18px" />
        </div>
        <div class="editable-gallery__row">
          <base-icon glyph="#icon-img" width="42px" />
          <div class="editable-gallery__col">
            <base-file label="Изображение" :removable="false" :name="img.link" @basefile:edit="handleImageEdit(img)"></base-file>
          </div>
        </div>
        <div class="editable-gallery__row">
          <base-input
            placeholder="Порядок"
            type="number"
            name="idx"
            required
            :value="img.idx"
            @blur="changeImage($event, img._id)"
            width="80px"
          />
          <base-input
            placeholder="Заголовок"
            type="text"
            name="title"
            required
            :value="img.title"
            @blur="changeImage($event, img._id)"
          />
        </div>
      </div>
      <base-button theme="dashed" @click="createNewImg">
        <base-icon glyph="#icon-plus" width="16px" />
        Добавить изображение
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { sortBy, compact } from 'lodash';

import ModalNewImage from '@/components/Modal/ModalNewImage';

export default {
  name: 'EditableGallery',
  props: {
    block: Object,
  },
  data() {
    return {
      pending: false,
      gallery: [],
      currentEditingImage: null,
    };
  },
  computed: {
    ...mapGetters('images', { listImg: 'list' }),

    filteredGallery() {
      const imgList = [];
      this.listImg.map((img) => {
        if (this.block.gallery.includes(img._id)) {
          imgList.push(img);
        }
      });
      return imgList;
    },

    sortedGallery() {
      return sortBy(this.filteredGallery, 'idx');
      //      return sortBy(this.gallery, "idx");
    },
  },
  methods: {
    ...mapActions('images', ['fetchImages', 'addImage', 'removeImage']),
    ...mapActions('lessonBlocks', ['changeBlock']),

    imageEdit(url) {
      if (this.currentEditingImage) {
        this.$emit('save', this.currentEditingImage, 'link', url, (data) => this.addImage(data));
        const current = this.gallery.find((n) => n._id === this.currentEditingImage);
        if (current) {
          current.link = url;
        }
      }
    },
    handleImageEdit(img) {
      this.currentEditingImage = img._id;
      this.$emit('uploadFile:open', img.link, this.imageEdit);
      // this.$emit('save', id, 'link', e.target.value, (data) => this.addImage(data));
    },
    pushImg(id) {
      const { listImg, block } = this;
      if (id) {
        const idx = this.gallery.findIndex(({ _id }) => _id === id);
        // Если такая картинка уже есть в галлерее, ты выходим из функции
        if (idx >= 0) return;
        this.gallery.push(listImg.find((img) => img._id === id));
        this.$emit('save', block._id, 'gallery', [...block.gallery, id], (data) => this.changeBlock(data));
      }
    },
    removeImg(id) {
      const { removeImage, block } = this;
      this.gallery = this.gallery.filter((img) => img._id !== id);
      removeImage({ _id: id });
      this.$emit('save', block._id, 'gallery', [...block.gallery.filter((img) => img !== id)], (data) => this.changeBlock(data));
    },
    changeImage(e, id) {
      this.$emit('save', id, e.target.name, e.target.value, (data) => this.addImage(data));
    },
    createNewImg() {
      const { pushImg } = this;
      this.$emit('vuedals:new', {
        name: 'create-img',
        component: ModalNewImage,
        props: {
          idx: this.sortedGallery.length + 1,
        },
        dismissable: false,
        size: 'sm',
        onClose(e) {
          pushImg(e);
        },
      });
    },
  },
  watch: {
    'block.gallery': {
      immediate: true,
      async handler(val) {
        if (val.length) {
          this.pending = true;
          this.gallery = await this.fetchImages({
            filter: { _id: { $in: [...val] } },
          });
          this.pending = false;
        }
      },
    },
  },
};
</script>

<style lang="less">
.editable-gallery {
  &__spinner .base-spinner {
    margin: 0 auto;
  }

  &__image {
    &-wrap {
      position: relative;
      padding-right: 30px;
      margin-bottom: 20px;
    }

    &-remove {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }

  &__row {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .base-icon {
      margin-right: 10px;
      flex-shrink: 0;
    }
  }

  &__col {
    flex-shrink: 1;
    width: 50%;
    flex-grow: 1;
  }
}
</style>
