import { render, staticRenderFns } from "./BaseEditor.vue?vue&type=template&id=1bbdbb1c&scoped=true&"
import script from "./BaseEditor.vue?vue&type=script&lang=js&"
export * from "./BaseEditor.vue?vue&type=script&lang=js&"
import style0 from "./BaseEditor.vue?vue&type=style&index=0&id=1bbdbb1c&lang=less&scoped=true&"
import style1 from "./BaseEditor.vue?vue&type=style&index=1&lang=css&"
import style2 from "./BaseEditor.vue?vue&type=style&index=2&id=1bbdbb1c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bbdbb1c",
  null
  
)

export default component.exports