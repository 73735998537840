import { HTTP } from '@/http';

export default {
  deleteModel(context, { model, options }) {
    return HTTP.delete(`/crud/${model}`, options);
  },
  fetchModel(context, { model, options }) {
    return HTTP.get(`/crud/${model}`, options);
  },
  saveModel(context, { model, options }) {
    return HTTP.get(`/crud/${model}`, options);
  },
  testHead(context, url) {
    return new Promise((resolve, reject) => {
      HTTP.head(url)
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  },
  cleanHtml(context, text) {
    return new Promise((resolve, reject) => {
      HTTP.post('/api/cleanhtml', { text })
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });
  },
};
