import { HTTP } from '@/http.js';

const sections = {
  namespaced: true,
  state: {
    ids: [],
    tempSection: {},
  },
  mutations: {
    FETCH_SECTIONS: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    CREATE_SECTION: (state, payload) => {
      state.tempSection = payload;
    },
    REMOVE_SECTION: (state, id) => {
      const idx = state.ids.indexOf(id);
      if (idx !== -1) {
        state.ids.splice(idx, 1);
      }
    },
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.sections[id]);
    },
  },
  actions: {
    getSections({ commit }, params) {
      return new Promise((resolve, reject) => {
        HTTP.get('/crud/lessonsection', { params })
          .then((response) => {
            if (response.data.error) {
              reject(response.data.error);
              return;
            }
            commit(
              'FETCH_MODELS',
              {
                type: 'sections',
                payload: response.data.models,
              },
              { root: true }
            );
            resolve(response.data.models);
          })
          .catch((error) => reject(error));
      });
    },
    fetchSections({ commit, dispatch }, params) {
      return dispatch('getSections', params).then((models) => {
        commit(
          'FETCH_SECTIONS',
          models.map((n) => n._id)
        );
        return models;
      });
    },
    addSection({ commit }, params) {
      HTTP.post('/crud/lessonsection', { ...params }).then((response) => {
        if (response.data._id) {
          commit('FETCH_SECTIONS', [response.data._id]);
          commit('CREATE_SECTION', response.data);
          commit(
            'FETCH_MODELS',
            {
              type: 'sections',
              payload: [response.data],
            },
            { root: true }
          );
        }
      });
    },
    removeSection({ commit }, params) {
      HTTP.delete('/crud/lessonsection', { data: { ...params } })
        .then(() => {
          commit('REMOVE_SECTION', params._id);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
  },
};

export default sections;
