import { Quill } from 'vue-quill-editor';

const BlockEmbed = Quill.import('blots/embed');

class Image extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute('alt', value.alt);
    node.setAttribute('src', value.url);
    return node;
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      url: node.getAttribute('src'),
    };
  }
}
Image.blotName = 'image';
Image.tagName = 'IMG';

export default Image;
