<template>
  <div class="code-area" :class="{ 'is-active': opened }">
    <div class="code-area__title" @click="opened = !opened">
      {{ title }}
      <div class="code-area__close">
        <base-icon glyph="#icon-close" width="18px" height="18px"></base-icon>
      </div>
    </div>
    <div class="code-area__content" v-show="opened">
      <textarea rows="10" @blur="handleUpdate" v-model="code"></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CodeArea',
  props: {
    content: {
      type: String,
      default: '',
    },
    title: {
      default: 'Вставить код',
      type: String,
    },
  },
  watch: {
    content: {
      immediate: true,
      handler(val) {
        this.code = val;
      },
    },
  },
  data() {
    return {
      opened: false,
      code: null,
    };
  },
  methods: {
    handleUpdate() {
      this.$emit('update', this.code);
    },
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';
.code-area {
  &__close {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -9px;
    transform: rotateZ(45deg);
    transition: transform 0.3s;
  }

  &.is-active &__close {
    transform: rotateZ(0);
  }

  &__title {
    position: relative;
    padding: 12px 20px;
    border: 1px solid #c0c0c0;
    background-color: #fff;
    cursor: pointer;
    user-select: none;

    &:hover {
      border-color: @primary-color;
    }
  }
  textarea {
    background-color: #000;
    display: block;
    width: 100%;
    height: 300px;
    color: fade(#fff, 90%);
    padding: 10px;
    font-family: Consolas, monaco, monospace;
    outline: none;
    border: 0;
    white-space: nowrap;
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
