<template>
  <div class="base-file">
    <slot name="label">
      <div v-if="label" class="base-file__label">{{ label }}:</div>
    </slot>
    <div class="base-file__inner">
      <slot name="prepend"></slot>
      <a :href="name" target="_blank" class="base-file__name" title="Открыть в новом окне" v-if="status === 'ok'">
        <span class="status-checker" :class="status"></span>{{ name }}
      </a>
      <span class="base-file__name" v-else>
        <span class="status-checker" :class="status"></span>{{ name || 'Ссылка на файл не задана' }}
      </span>
      <button title="Редактировать" class="base-file__control" @click.prevent="$emit('basefile:edit')">
        <base-icon glyph="#icon-pencil" variant="hover" width="22px" height="22px" />
      </button>
      <button v-if="removable" title="Удалить" class="base-file__control" @click.prevent="$emit('basefile:remove')">
        <base-icon glyph="#icon-close" variant="hover" width="22px" height="22px" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFile',
  props: {
    name: {
      type: String,
      default: 'Ссылка на файл не задана',
    },
    label: String,
    removable: {
      type: Boolean,
      default: true,
    },
    ediatable: {
      type: Boolean,
      default: true,
    },
    checkStatus: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    name: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$store
            .dispatch('testHead', val)
            .then(() => {
              this.fileIsOk = true;
            })
            .catch(() => {
              this.fileIsOk = false;
            });
        }
      },
    },
  },
  computed: {
    status() {
      const checker = this.fileIsOk;
      if (typeof checker === 'boolean') {
        return checker ? 'ok' : 'error';
      }
      return 'pending';
    },
  },
  data() {
    return {
      fileIsOk: null,
    };
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';
@basefile-size: 42px;

.status-checker {
  display: inline-block;
  width: 0.45em;
  height: 0.45em;
  vertical-align: baseline;
  border-radius: 50%;
  margin-right: 0.3em;
  background-color: #000;

  &.error {
    background-color: @secondary-color;
  }

  &.ok {
    background-color: @tertiary-color;
  }

  &.pending {
    background-color: @gray-light;
  }
}
.base-file {
  max-width: 100%;

  &__label {
    font-size: 14px;
  }

  &__name {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Consolas, monaco, monospace;
    color: @gray-dark;
    text-decoration: none;

    &:hover {
      color: @primary-color;
    }
  }

  &__inner {
    height: @basefile-size;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    flex-wrap: nowrap;
  }

  &__control {
    flex: 0 0 @basefile-size;
    height: @basefile-size;
    overflow: hidden;
    border: 1px solid @gray-light;
    outline: none;
    background-color: #fff;
    text-align: center;
    margin-left: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: @primary-color;
      box-shadow: 0 0 1px 0 @primary-color;
    }

    .base-icon {
      margin-right: 0;

      // hoverable area
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: @basefile-size;
        height: @basefile-size;
      }
    }
  }
}
</style>
