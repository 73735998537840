<template>
  <div class="switcher">
    <div class="switcher__item" v-for="(item, idx) in values" :key="`switcher-element-${_uid + idx}`">
      <button
        class="switcher__btn"
        type="submit"
        :disabled="item.value === selected"
        :class="{ 'is-active': item.value === selected }"
        @click="handleClick(item.value)"
      >
        {{ item.name }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Switcher',
  props: {
    values: {
      type: Array,
      required: true,
    },
    selected: String,
  },
  methods: {
    handleClick(val) {
      // если кликнули по активной
      if (val === this.selected) return;
      this.$emit('switcher:change', val);
    },
  },
};
</script>

<style lang="less" scoped>
@switcher-default-bg: #a0a9bc;
@switcher-active-bg: #4507ca;
@switcher-active-color: #ffffff;
@switcher-default-color: #ffffff;

.switcher {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex-wrap: nowrap;

  &__item {
    flex: 0 0 auto;
    overflow: hidden;
    &:first-child {
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
    }

    &:last-child {
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
    }
  }

  &__btn {
    border: 0;
    background-color: transparent;
    padding: 6px 18px;
    background-color: @switcher-default-bg;
    color: @switcher-default-color;
    outline: none;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background-color: darken(@switcher-default-bg, 10%);
    }

    &.is-active {
      background-color: @switcher-active-bg;
      color: @switcher-active-color;

      &:hover {
        background-color: darken(@switcher-active-bg, 10%);
      }
    }
  }
}
</style>
