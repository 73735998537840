<template>
  <div class="base-modal" :style="applyStyles">
    <div class="base-modal__close" v-on:click="close">
      <base-icon glyph="#icon-close" width="20px" />
    </div>
    <div class="base-modal__content">
      <div>
        <div class="base-modal__head">
          {{ title }}
          <div class="base-modal__head-btn">
            <slot name="modal-head-btn"></slot>
          </div>
        </div>
        <div class="base-modal__body">
          <slot name="modal-body"></slot>
        </div>
        <div>
          <slot name="modal-footer"></slot>
        </div>
      </div>
      <div class="base-modal__aside">
        <slot name="modal-aside"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Bus as VuedalsBus } from 'vuedals';

export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
  },
  computed: {
    applyStyles() {
      return {
        width: this.width,
      };
    },
  },
  methods: {
    close(e) {
      // this.$store.commit('modal/HIDE_MODAL');
      VuedalsBus.$emit('close');
      this.$emit('onClose');
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-modal {
  display: block;
  z-index: 1;

  &__close {
    position: absolute;
    top: 0;
    left: 100%;
    padding: 10px;

    &:hover {
      cursor: pointer;
      background: @primary-color;
    }

    .base-icon {
      --primaryIconColor: #ffffff;
      --secondaryIconColor: #ffffff;
    }
  }

  &__content {
    background: #fff;
    display: flex;
    flex-direction: row;

    > div {
      flex: auto;
      position: relative;
      max-width: 100%;
    }
  }

  &:before {
    content: '';
    width: 245px;
    height: 245px;
    border: 25px solid @secondary-color;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    left: -45px;
    z-index: -1;
  }
  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 18px;
    height: 18px;
    border-top: 20px solid @secondary-color;
    border-right: 21px solid transparent;
  }

  &__head {
    font-size: 32px;
    font-weight: bold;
    padding: 45px 35px 10px 35px;
    position: relative;

    &-btn {
      position: absolute;
      top: 45px;
      right: 35px;
    }
  }

  &__body {
    padding: 5px 35px 45px 35px;
  }

  &__aside {
    padding: 30px;
    max-width: 37% !important;
    background: #f1f5f8;
    position: relative;

    &:empty {
      display: none;
    }

    .multiselect {
      min-width: 100px;
    }
  }

  @media (max-width: 768px) {
    height: 100%;

    &:after,
    &:before {
      display: none;
    }

    &__head {
      font-size: 24px;
      padding: 50px 15px 10px 15px;

      &-btn {
        position: unset;
        padding: 5px 0;
      }
    }

    &__body {
      padding: 5px 15px 45px 15px;
    }

    &__close {
      right: 0;
      left: unset;
      background: @primary-color;
      z-index: 3;
    }

    &__content {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>
