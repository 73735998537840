import Vue from 'vue';
import Vuex from 'vuex';
import mutations from '@/store/mutations';
import getters from '@/store/getters';
import actions from '@/store/actions';

// modules
import lessonBlocks from '@/store/modules/LessonBlocks';
import lessons from '@/store/modules/Lessons';
import images from '@/store/modules/Images';
import tags from '@/store/modules/Tags';
import sections from '@/store/modules/Sections';
import navs from '@/store/modules/Navs';
import subjects from '@/store/modules/Subjects';
import variants from '@/store/modules/Variants';
import fileServer from '@/store/modules/FileServer';

Vue.use(Vuex);

const modules = {
  lessonBlocks,
  lessons,
  images,
  tags,
  navs,
  subjects,
  variants,
  fileServer,
  sections,
};

// define initial state
const state = {
  layout: 'BaseLayout',
  redirect: null,
  DB: {
    activities: {},
    lessonBlocks: {},
    lessons: {},
    images: {},
    tags: {},
    sections: {},
    tagGroups: {},
    subjects: {},
    subjectAreas: {},
    subjectThemes: {},
    variants: {},
  },
  mathjaxIsReady: false,
  pending: {},
};

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
});

export default store;
