import { Quill } from 'vue-quill-editor';
// import Scroll from "quill/blots/scroll";

const BlockEmbed = Quill.import('blots/block/embed');

class Div extends BlockEmbed {
  constructor(node) {
    super(node);
    this.domNode.setAttribute('contenteditable', false);
  }

  static create(value) {
    return value;
  }

  static value(domNode) {
    return domNode;
  }
}

Div.blotName = 'div';
Div.tagName = 'DIV';

export default Div;
