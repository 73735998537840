<template>
  <div class="container">
    <div class="sup-title">Ошибка</div>
    <div class="title">4<span>0</span>4 :(</div>
    <p class="text">Страница была удалена или никогда не существовала</p>
    <base-button size="large" tag="router-link" :to="{ path: '/' }">На главную</base-button>
  </div>
</template>

<script>
export default {
  name: 'Page404',
};
</script>

<style lang="less" scoped>
@import '~less_vars';

.container {
  max-width: 600px;
  height: initial;
  margin: 100px auto;
  text-align: center;
  padding: 50px;
  background-color: #fff;
  border: 1px solid fade(#000, 5%);
  box-shadow: 0px 2px 10px -6px #000;
  border-radius: 3px;
}

.title {
  font-size: 100px;
  font-weight: bold;
  margin-bottom: 40px;
  color: @primary-color;

  span {
    color: @secondary-color;
  }
}

.text {
  margin-bottom: 30px;
}

.sup-title {
  font-size: 18px;
}
</style>
