<template>
  <div class="preview-variant">
    <div class="preview-variant__index">{{ index + 1 }}</div>
    <div class="preview-variant__type">{{ variantTypeToText }}</div>

    <h2 v-if="variant.name">{{ variant.name }}</h2>
    <div v-if="variant.practice.question" class="preview-variant__question">
      <base-mathjax-text v-if="!exceptionsPracticeType.includes(variant.practiceType)" :html="parsedQuestion" />
      <component :is="parseForSelectGap" v-if="variant.practiceType === 'SelectGap'" />
      <PreviewMorphemes v-if="variant.practiceType === 'Morphemes'" :question="variant.practice.question" />

      <PreviewSyntactic v-if="variant.practiceType === 'Syntactic'" :question="variant.practice.question" />

      <PreviewHighlighting v-if="variant.practiceType === 'Highlighting'" :question="variant.practice.question" />
    </div>

    <div v-if="variant.practiceType === 'Algorithm'">
      <div class="alg-video">
        <video ref="algVideo" :src="variant.practice.video"></video>
      </div>

      <div class="alg-panels" v-if="sortedAnswers.length">
        <div
          class="alg-panels__item"
          v-for="(item, idx) in sortedAnswers"
          :key="item._id"
          @click.stop="handleAlgAnswerClick(item.range)"
        >
          <div class="alg-panels__step">Операция № {{ idx + 1 }}</div>
          <base-mathjax-text :html="item.value" />
        </div>
      </div>
      <div v-else class="alg-error">
        <p>Список операций пуст. Создайте операции для алгоритма.</p>
      </div>
    </div>

    <div v-if="variant.practiceType === 'SplitAtGroups'">
      <div class="split-groups">
        <div class="split-group" v-for="(group, index) in variant.practice.groups" :key="group._id">
          <div class="split-group__name">
            <base-mathjax-text v-if="group.name" :html="group.name" />
            <span v-else>Группа {{ index + 1 }}</span>
          </div>
          <div class="split-group__question">
            <base-mathjax-text :html="group.question" />
          </div>
        </div>
      </div>

      <div>Ответы:</div>
      <div class="split-answers">
        <div class="split-answer" v-for="answer in mixAnswers" :key="`split-answer-${answer._id}`">
          <base-mathjax-text :html="answer.value" />
        </div>
      </div>
    </div>

    <div v-if="variant.practice.answers">
      <div v-if="variant.practiceType === 'CombinationLock'">
        <div
          v-if="variant.practice.image"
          class="preview-variant__image"
          :style="{ backgroundImage: `url(${variant.practice.image})` }"
        ></div>

        <div class="preview-variant__table">
          <div v-for="col in variant.practice.answers" :key="`col-${col.x}`" class="preview-variant__table-col">
            <div class="preview-variant__answer-card">
              <div class="preview-variant__answer-input" v-for="row in col.rows" :key="`row-${row.y}`">{{ row.value }}</div>
            </div>
          </div>
        </div>

        <PreviewLockGallery v-if="variant.practice.gallery1.length" :gallery="variant.practice.gallery1" />
        <PreviewLockGallery v-if="variant.practice.gallery2.length" :gallery="variant.practice.gallery2" />
        <PreviewLockGallery v-if="variant.practice.gallery3.length" :gallery="variant.practice.gallery3" />
      </div>
      <div v-else class="preview-variant__answers">
        <div v-if="variant.practiceType === 'Sequence'">
          <div
            v-for="answer in answersSortByIdx"
            :key="answer._id"
            class="preview-variant__answer preview-variant__answer-ticket"
          >
            <base-mathjax-text :html="answer.value" />
          </div>
        </div>
        <div v-else :class="{ horizontal: variant.practice.horizontal }">
          <div
            class="preview-variant__answer"
            v-bind:class="{
              'preview-variant__answer-drag': variant.practiceType === 'MouseGap',
            }"
            v-for="answer in variant.practice.answers"
            :key="answer._id"
          >
            <base-radio
              v-if="variant.practiceType === 'OneOfMany' || variant.practiceType === 'FormulaOne'"
              theme="question"
              :value="answer._id"
              v-model="tempAnswer"
            >
              <base-mathjax-text v-if="variant.practiceType === 'OneOfMany'" :html="answer.answer" />
              <base-mathjax-text
                v-if="variant.practiceType === 'FormulaOne'"
                :html="answer.answer.slice(8, answer.answer.length - 2)"
              />
            </base-radio>

            <base-checkbox
              v-if="variant.practiceType === 'ManyOfMany' || variant.practiceType === 'FormulaMany'"
              theme="question"
              :value="answer._id"
            >
              <base-mathjax-text v-if="variant.practiceType === 'ManyOfMany'" :html="answer.answer" />
              <base-mathjax-text v-if="variant.practiceType === 'FormulaMany'" :html="answer.answer.replace(/return |;/gi, '')" />
            </base-checkbox>

            <base-mathjax-text v-if="variant.practiceType === 'MouseGap'" :html="answer.answer" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="variant.practice.questions" class="preview-variant__questions">
      <div v-if="variant.practiceType === 'Matching'">
        <div class="preview-variant__questions-couple" v-for="question in variant.practice.questions" :key="question._id">
          <base-mathjax-text class="preview-variant__questions-card" :html="question.question" />
          <base-mathjax-text class="preview-variant__questions-card" :html="question.answer" />
        </div>
      </div>
      <div v-if="variant.practiceType === 'Choice'">
        <div class="preview-variant__choice-item" v-for="question in variant.practice.questions" :key="question._id">
          <div class="preview-variant__choice-item__button">
            <base-icon glyph="#icon-check" width="30px" />
          </div>
          <base-mathjax-text class="preview-variant__choice-item__question" :html="question.question" />
          <div class="preview-variant__choice-item__button">
            <base-icon glyph="#icon-cross" width="25px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Vue from 'vue';
import { playVideoRange } from '@/helpers';
import sortBy from 'lodash.sortby';
import PreviewLockGallery from './PreviewLockGallery';
import PreviewMorphemes from './PreviewMorphemes';
import PreviewSyntactic from './PreviewSyntactic';
import PreviewHighlighting from './PreviewHighlighting';

export default {
  name: 'PreviewVariant',
  components: {
    Multiselect,
    PreviewLockGallery,
    PreviewMorphemes,
    PreviewSyntactic,
    PreviewHighlighting,
  },
  props: {
    variant: Object,
    index: Number,
  },
  data() {
    return {
      tempAnswer: null,
      exceptionsPracticeType: ['SelectGap', 'Morphemes', 'Syntactic', 'Highlighting'],
    };
  },
  computed: {
    variantTypeToText() {
      let text = '';
      const type = this.variant.practiceType;
      // eslint-disable-next-line default-case
      switch (type) {
        case 'OneOfMany':
          text = 'Выберите один правильный вариант ответа:';
          break;
        case 'ManyOfMany':
          text = 'Выберите несколько правильных вариантов ответов:';
          break;
        case 'Matching':
          text = 'Сопоставьте предложенные варианты:';
          break;
        case 'Choice':
          text = 'Согласитесь, или опровергните высказывания:';
          break;
        case 'KeyboardGap':
          text = 'Заполните пропуски:';
          break;
        case 'MouseGap':
          text = 'Заполните пропуски, перетаскивая варианты ответов:';
          break;
        case 'SelectGap':
          text = 'Заполните пропуски, выбрав ответ из предложенных:';
          break;
        case 'CombinationLock':
          text = 'Кодовый замок:';
          break;
        case 'Algorithm':
          text = 'Постройте алгоритм решения задачи, перемещая карточки в правильной последовательности:';
          break;
        case 'SplitAtGroups':
          text = 'Распределите карточки по группам:';
          break;
        case 'Sequence':
          text = 'Распределите карточки в правильном порядке:';
          break;
        case 'Morphemes':
          text = 'Разберите слова по составу:';
          break;
        case 'Syntactic':
          text = 'Разберите предложения по членам:';
          break;
        case 'Highlighting':
          text = 'Выделите текст:';
          break;
      }
      return text;
    },
    parsedQuestion() {
      const { question } = this.variant.practice;

      if (!['KeyboardGap', 'MouseGap'].includes(this.variant.practiceType)) {
        return question;
      }

      const re = /\#\{[^}]*\}/gi;
      let className = 'preview-variant__gap';
      // eslint-disable-next-line default-case
      switch (this.variant.practiceType) {
        case 'KeyboardGap':
          className += '';
          break;
        case 'MouseGap':
          className += '-drag';
          break;
      }

      return question.replace(re, `<span class="${className}"></span>`, 'gm');
    },
    parseForSelectGap() {
      const { question, answers } = this.variant.practice;

      const re = /#{([^}]*)}/gi;

      return Vue.component('MathjaxWithSelectGap', {
        template: `<div>${question.replace(re, this.selectGapReplacer(answers), 'gm')}</div>`,
        mounted() {
          this.$nextTick(() => {
            window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, this.$el]);
          });
        },
      });
    },
    sortedAnswers() {
      const { variant } = this;
      if (!variant.practiceType === 'Algorithm') return;
      const answers = [...variant.practice.answers];
      return answers.sort((a, b) => a.idx - b.idx);
    },
    mixAnswers() {
      const { practiceType, practice } = this.variant;
      if (practiceType !== 'SplitAtGroups') {
        return null;
      }
      return practice.groups.reduce((acc, val) => [...acc, ...val.answers], [...practice.wrongAnswersGroup]);
    },
    answersSortByIdx() {
      return sortBy(this.variant.practice.answers, 'idx');
    },
  },
  methods: {
    selectGapReplacer(answers) {
      return (match, group) => {
        if (group.match('|')) {
          const arGroup = group.split('|');
          // eslint-disable-next-line no-param-reassign
          group = arGroup[0];
        }
        const currentAnswer = answers.find(({ variable }) => variable === group);
        if (!currentAnswer) return '%error parse selectGap%';
        const options = currentAnswer.answer.split('|');

        function makeOptions(opts) {
          return opts.map((option) => `<option value="${option}">${option}</option>`);
        }

        function makeTabs(opts) {
          return opts.reduce((acc, val) => {
            acc += `<span class="select-gap__tab">${val}</span>`;
            return acc;
          }, '');
        }

        if (this.variant.practice.showAll) {
          return `<span class="select-gap__tabs">
            ${makeTabs(options)}
          </span>`;
        }
        return `<select class="custom-select" name="${currentAnswer._id}_${group}">
                    <option disabled selected>...</option>
                    ${makeOptions(options)}
                  </select>`;
      };
    },
    handleAlgAnswerClick(range) {
      playVideoRange(this.$refs.algVideo, range);
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.alg-error {
  color: #fff;
  padding: 10px;
  background-color: @secondary-color;
  margin-top: 20px;
  font-weight: bold;
}

.alg-panels {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;

  &__step {
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-weight: bold;
  }

  &__item {
    border: 1px solid #dedede;
    padding: 10px;
    margin-bottom: 10px;
    flex: 0 0 49%;
    max-width: 49%;
    font-size: 13px;
    line-height: 1.32;
    transition: border 0.3s;
    cursor: pointer;

    &:hover {
      border-color: @primary-color;
    }

    .base-mathjax {
      pointer-events: none;
    }
  }
}

.alg-video {
  position: relative;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
  }

  &::before {
    padding-top: 52.6%;
    display: block;
    content: '';
  }
}

.split-groups {
  // display: flex;
  margin-bottom: 20px;
}

.split-group {
  // flex: 0 1 100%;
  border: 1px solid #f1f5f8;
  border-radius: 4px;
  margin-bottom: 5px;

  &__name {
    padding: 10px 20px;
    background: #f1f5f8;
    min-height: 38px;
  }

  &__question {
    padding: 10px 20px;
    min-height: 70px;
  }
}

.split-answers {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  &__group {
    display: flex;
    flex-wrap: wrap;
  }
}

.split-answer {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  padding: 10px 20px;
  margin: 10px;
  max-width: 200px;

  .base-mathjax > div {
    :last-child {
      margin-bottom: 0;
    }
  }
}

.select-gap {
  &__tabs {
  }

  &__tab {
    padding: 5px 10px;
    background: #f1f5f8;
    color: @primary-color;
    font-size: 16px;
    border: 1px solid #d8e1e7;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    & + & {
      border-left: none;
    }
  }
}

.preview-variant {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  padding: 20px;
  margin: 10px 0;
  position: relative;

  &__index {
    position: absolute;
    width: 30px;
    height: 30px;
    border: 3px solid #a0a9bc;
    border-radius: 50%;
    text-align: center;
    line-height: 23px;
    font-weight: bold;
    font-size: 16px;
    color: #a0a9bc;
    top: 15px;
    right: 20px;
  }

  &__type {
    font-size: 14px;
    color: #66626e;
    margin-bottom: 20px;
    margin-right: 40px;
  }

  &__question {
    font-size: 22px;
    margin: 20px 0;

    .custom-select {
      padding: 5px 20px 5px 10px;
      margin: 0 10px;
      font-size: 16px;
      outline-color: @primary-color;
      cursor: pointer;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-align-right {
      text-align: right;
    }
    .ql-align-justify {
      text-align: justify;
    }

    .style-link-button {
      display: inline-block;
      border-radius: 10px;
      background: linear-gradient(0deg, rgba(193, 35, 229, 1) 0%, rgba(211, 138, 241, 1) 100%);
      padding: 2px 15px 4px;
      text-decoration: none;
      color: #fff;
      &:hover {
        opacity: 0.9;
      }
      &:active {
        background: linear-gradient(180deg, rgba(193, 35, 229, 1) 0%, rgba(211, 138, 241, 1) 100%);
      }
    }

    .style-text-question {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      border: 2px solid @primary-color;
      border-radius: 15px;
      color: @primary-color;
      margin: 10px 0;

      &__mark {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-right: 20px;
        width: 50px;
        height: 50px;
        font-size: 42px;
        font-weight: 700;
        color: white;
        background-color: @primary-color;
        border-radius: 50%;
      }
    }

    .style-text-dashed-border {
      text-align: center;
      padding: 20px;
      background-image: url('../../assets/border-dashed.svg');
      border-radius: 30px;
      margin: 10px 0;
      &__title {
        margin-bottom: 15px;
        color: #1848ac;
        font-weight: 700;
      }
      &__text {
        color: black;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;

      td {
        border: 1px solid #ccc;
        padding: 5px 10px;
      }
    }

    table.style-table-color-primary {
      td {
        border: 1px solid #4507ca;
      }
    }

    table.style-table-color-secondary {
      td {
        border: 1px solid #fc005c;
      }
    }

    table.style-table-color-none {
      td {
        border: none;
      }
    }

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    counter-reset: content-ol-counter;

    .basic-template__col {
      &:first-child {
        counter-reset: content-ol-counter;
      }

      > ol {
        counter-reset: none;
      }
    }

    & .basic-template__col:first-child {
      counter-reset: content-ol-counter;
    }

    ol {
      list-style: none;
      padding-left: 2em;
      counter-reset: content-ol-counter;

      > li {
        counter-increment: content-ol-counter;
        margin-bottom: 0.3em;

        &:before {
          content: counter(content-ol-counter) '. ';
          color: @primary-color;
          font-weight: bold;
          margin-left: -2em;
          margin-right: 0.5em;
          min-width: 1.5em;
          display: inline-block;
          text-align: right;
        }
      }
    }

    .basic-template {
      display: flex;
      justify-content: space-between;
      padding: 25px 0;

      &.is-vertical {
        flex-direction: column;
      }

      &.is-center {
        align-items: center;
      }

      &__col {
        & > p {
          margin: 0;
          margin-bottom: 20px;
        }

        img {
          width: 100%;
        }
      }
    }

    img {
      border-radius: 8px;
    }
  }

  &__table {
    display: flex;
    margin: 20px -5px;

    &-col {
      flex: 1 1 100%;
      padding: 0 5px;
      overflow: hidden;
    }
  }

  &__answer-card {
    border: 1px solid #f1f5f8;
    background: #fff;
  }

  &__answer-input {
    border-top: 1px solid #f1f5f8;
    padding: 10px;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    padding-top: 60%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__answers .horizontal {
    display: flex;
  }
  &__answers .horizontal &__answer {
    margin-right: 20px;
  }

  &__answer {
    margin-bottom: 25px;

    &-drag {
      display: inline-block;
      max-width: 100%;
      padding: 0 10px;
      margin-right: 20px;
      margin-bottom: 10px;
      border: 1px solid @gray-light;
      border-radius: 4px;
      background: @bg-blue-light;
      color: @primary-color;
      cursor: pointer;
      line-height: 2em;
      vertical-align: middle;
      white-space: nowrap;
    }

    &-ticket {
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
      border-radius: 2px;
      padding: 20px;
    }
  }

  &__questions {
    &-couple {
      display: flex;
      margin: 0 -30px 10px;
    }

    &-card {
      padding: 15px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
      border-radius: 2px;
      position: relative;
      user-select: none;
      font-size: 14px;
      width: 50%;
      margin: 0 30px;
      background: #ffffff;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 9px;
        background: url(../../assets/curve-white.svg) 0 0 no-repeat repeat;
        background-size: contain;
      }

      &:first-child&::after {
        right: -8px;
        background-position: 0 -4px;
        filter: drop-shadow(4px 2px 4px rgba(0, 0, 0, 0.1));
      }

      &:last-child&::after {
        left: -8px;
        background-position: 0 10px;
        transform: rotate(180deg);

        filter: drop-shadow(4px -2px 3px rgba(0, 0, 0, 0.1));
      }
    }
  }

  &__gap,
  &__gap-drag {
    display: inline-block;
    max-width: 100%;
    min-width: 15px;
    background-color: white;
    border-bottom: 3px solid @primary-color;
    padding: 0 5px;
    vertical-align: text-bottom;
  }

  &__gap-drag {
    min-width: 70px;
  }

  &__choice-item {
    padding: 20px 0;
    display: flex;
    border-bottom: 1px solid #eaeaea;
    align-items: center;

    &__question {
      flex: 1;
      text-align: center;
    }

    &__button {
      padding: 10px;

      --primaryIconColor: #c8c1d9;
      --secondaryIconColor: #c8c1d9;
    }
  }
}
</style>
