<template>
  <div class="editable-matching-answers">
    <div class="editable-matching-answers__body">
      <div class="editable-matching-answers__question">
        <switcher
          class="my-10"
          :values="[
            { name: 'HTML', value: 'html' },
            { name: 'Редактор', value: 'wysiwyg' },
          ]"
          :selected="practice && practice.contentType"
          @switcher:change="handleChangeType"
        ></switcher>
        <plain-html-editor
          class="mb-10"
          :content="practice.question"
          @setContent="saveText"
          v-if="practice.contentType === 'html'"
        ></plain-html-editor>
        <base-editor :value="practice.question" v-else placeholder="Условие" @save-text="saveText" />
      </div>
      <div
        class="editable-matching-answers__question editable-matching-answers__question--flex"
        v-for="question in practice.questions"
        :key="question._id"
      >
        <div class="editable-matching-answers__question-body">
          <div class="mb-10">Вопрос</div>
          <!-- <base-input placeholder="Вопрос"
            type="text"
            :value="question.question"
          @blur="setText($event, question._id, 'question')"/>-->

          <base-editor :value="question.question" placeholder="Условие" @save-text="setText($event, question._id, 'question')" />

          <div class="mb-10">Ответ</div>
          <!-- <base-input placeholder="Ответ"
            type="text"
            :value="question.answer"
          @blur="setText($event, question._id, 'answer')"/>-->
          <base-editor :value="question.answer" placeholder="Ответ" @save-text="setText($event, question._id, 'answer')" />
        </div>
        <div class="editable-matching-answers__question-remove" @click="$emit('deleteQuestion', question._id)">
          <base-icon glyph="#icon-close" width="20px" />
        </div>
      </div>
    </div>
    <div class="editable-matching-answers__new-question">
      <base-button theme="dashed" @click="pushNewQuestion">
        <base-icon glyph="#icon-plus" width="16px" />Добавить пару
      </base-button>
    </div>
  </div>
</template>

<script>
import PlainHtmlEditor from '@/components/Common/PlainHtmlEditor';
import Switcher from '@/components/Common/Switcher';

export default {
  name: 'EditableMatchingAnswers',
  props: {
    practice: Object,
  },
  components: {
    PlainHtmlEditor,
    Switcher,
  },
  methods: {
    saveText(text) {
      if (this.practice && this.practice.question === text) return;
      this.$emit('set-question', { titleQuestion: text });
      console.count('EditableMatchingAnswers_saveText');
    },
    pushNewQuestion() {
      this.$emit('set-question', { question: '', answer: '' });
      console.count('EditableMatchingAnswers_pushNewQuestion');
    },
    handleChangeType(type) {
      if (confirm('Текст вопроса, при смене типа контента будет удален.\nВсе равно поменять тип контента?')) {
        let question = '';
        if (type === 'html') {
          question = this.practice.question;
        }
        this.$emit('set-question', { contentType: type });
        this.practice.question = question;
      }
    },

    setText(value, questionId, field) {
      const currentQuestion = this.practice.questions.find((question) => question._id === questionId);
      currentQuestion[field] = value;

      this.$emit('set-question', currentQuestion, questionId);
      console.count('EditableMatchingAnswers_setText');
    },
  },
};
</script>

<style lang="less">
.editable-matching-answers {
  margin: 10px;

  &__question {
    // display: flex;
    align-items: center;
    margin-bottom: 10px;

    &--flex {
      display: flex;
      align-items: flex-start;
    }

    .base-input {
      margin-right: 10px;
    }

    .quill-editor {
      background: white;
    }

    &-remove {
      cursor: pointer;
    }

    &-body {
      margin-right: 10px;
    }
  }
}
</style>
