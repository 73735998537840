<template>
  <div class="editable-variant">
    <div class="editable-variant__head" @click="$emit('toggleOpen', variant._id)">
      <div class="editable-variant__index">{{ index + 1 }}</div>
      <div class="editable-variant__type">{{ getType }}</div>
      <div class="editable-variant__difficulty">
        <multiselect
          placeholder="Сложность"
          v-model="selectedDifficulty"
          :options="difficultList"
          track-by="type"
          label="label"
          :allow-empty="false"
          :searchable="false"
          :show-labels="false"
          @input="setDifficulty"
        />
      </div>
      <div class="editable-variant__controls-wrap">
        <!-- <div class="editable-variant__control">
          <base-icon glyph="#icon-import" variant="hover" width="20px" />
        </div>-->
        <div class="editable-variant__control">
          <base-icon glyph="#icon-close" variant="hover" width="20px" @click="$emit('removeVariant', variant._id)" />
        </div>
      </div>
    </div>

    <base-collapsing class="editable-variant__body" :show="bodyIsShow">
      <div class="editable-variant__body__input">
        <base-input placeholder="Текст вопроса" type="text" name="name" :value="variant.name" @blur="changeNameVariant" />
      </div>
      <editable-selected-answers
        v-if="variant.practiceRefPath === 'selectanswer' || variant.practiceRefPath === 'formulaselect'"
        :practice="variant.practice"
        :type="variant.practiceType"
        :contentType="variant.contentType"
        @setAnswer="setAnswerForVariant"
        @deleteAnswer="deleteAnswer"
        @setContentType="setContentType"
        @set-question="setQuestionForVariant"
      />
      <editable-matching-answers
        v-if="variant.practiceRefPath === 'matching'"
        :practice="variant.practice"
        :contentType="variant.contentType"
        @set-question="setQuestionForVariant"
        @deleteQuestion="removeQuestion"
      />
      <editable-choosed-answers
        v-if="variant.practiceRefPath === 'choice'"
        :practice="variant.practice"
        :contentType="variant.contentType"
        @set-question="setQuestionForVariant"
        @deleteQuestion="removeQuestion"
      />
      <editable-gap-answers
        v-if="variant.practiceRefPath === 'gap'"
        :practice="variant.practice"
        :type="variant.practiceType"
        @set-question="setQuestionForVariant"
        @setContentType="setContentType"
      />
      <editable-lock-answers
        v-if="variant.practiceRefPath === 'lock'"
        :practice="variant.practice"
        @setAnswer="setAnswerForVariant"
        @uploadFile:open="uploadProxy"
      />
      <editable-algorithm-answers
        v-if="variant.practiceRefPath === 'algorithm'"
        :practice="variant.practice"
        @set-question="setQuestionForVariant"
        @setAnswer="setAnswerForVariant"
        @deleteAnswer="deleteAnswer"
      />
      <editable-grouping-answers
        v-if="variant.practiceRefPath === 'grouping'"
        :practice="variant.practice"
        @setGroup="setGroupForVariant"
        @set-wrong-group="setWrongGroupForVariant"
        @setAnswer="setAnswerForGroup"
        @set-question="setQuestionForVariant"
        @deleteGroup="deletGroup"
        @delete-wrong-group="deletWrongGroup"
        @deleteAnswer="deleteAnswer"
      />
      <editable-sequence-answers
        v-if="variant.practiceRefPath === 'sequence'"
        :practice="variant.practice"
        @set-question="setQuestionForVariant"
        @setAnswer="setAnswerForVariant"
        @deleteAnswer="deleteAnswer"
      />
      <editable-morphemes
        v-if="variant.practiceRefPath === 'morphemes'"
        :practice="variant.practice"
        @set-question="setQuestionForVariant"
      />
      <editable-syntactic
        v-if="variant.practiceRefPath === 'syntactic'"
        :practice="variant.practice"
        @set-question="setQuestionForVariant"
      />
      <editable-highlighting
        v-if="variant.practiceRefPath === 'highlighting'"
        :practice="variant.practice"
        @set-question="setQuestionForVariant"
      />
      <div class="editable-variant__help-wrap">
        <h4>Подсказка:</h4>
        <base-editor :value="variant.help" placeholder="Подсказка" @save-text="saveHelp" />
      </div>
    </base-collapsing>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';

import EditableSelectedAnswers from '@/components/Variant/EditableSelectedAnswers';
import EditableMatchingAnswers from '@/components/Variant/EditableMatchingAnswers';
import EditableChoosedAnswers from '@/components/Variant/EditableChoosedAnswers';
import EditableGapAnswers from '@/components/Variant/EditableGapAnswers';
import EditableLockAnswers from '@/components/Variant/EditableLockAnswers';
import EditableAlgorithmAnswers from '@/components/Variant/EditableAlgorithmAnswers';
import EditableGroupingAnswers from '@/components/Variant/EditableGroupingAnswers';
import EditableSequenceAnswers from '@/components/Variant/EditableSequenceAnswers';
import EditableMorphemes from '@/components/Variant/EditableMorphemes';
import EditableSyntactic from '@/components/Variant/EditableSyntactic';
import EditableHighlighting from '@/components/Variant/EditableHighlighting';

export default {
  name: 'EditableVariant',
  components: {
    Multiselect,
    EditableSelectedAnswers,
    EditableMatchingAnswers,
    EditableChoosedAnswers,
    EditableGapAnswers,
    EditableLockAnswers,
    EditableAlgorithmAnswers,
    EditableGroupingAnswers,
    EditableSequenceAnswers,
    EditableMorphemes,
    EditableSyntactic,
    EditableHighlighting,
  },
  props: {
    variant: Object,
    index: Number,
    bodyIsShow: Boolean,
  },
  data() {
    return {
      selectedDifficulty: null,
    };
  },
  computed: {
    ...mapGetters('variants', ['typeList', 'difficultList']),
    getType() {
      return this.typeList.find((t) => t.type === this.variant.practiceType).label;
    },
  },
  methods: {
    ...mapActions('variants', [
      'changeVariant',
      'setAnswer',
      'removeAnswer',
      'setQuestion',
      'deleteQuestion',
      'setGroup',
      'setWrongGroup',
      'deleteGroup',
      'deleteWrongGroup',
    ]),

    setDifficulty(difficulty) {
      this.variant.difficulty = difficulty ? difficulty.type : '';
      this.changeVariant({
        _id: this.variant._id,
        difficulty: difficulty.type,
      });
    },

    setContentType(contentType) {
      this.changeVariant({ _id: this.variant._id, contentType });
    },

    setAnswerForVariant(answerObj, answerId) {
      const { variant, setAnswer } = this;
      const params = {
        variant: variant._id,
        answerObj,
      };
      if (answerId) params.answer = answerId;

      setAnswer(params);
    },
    deleteAnswer(answerId) {
      const { variant, removeAnswer } = this;

      removeAnswer({ variant: variant._id, answer: answerId });
    },
    saveHelp(text) {
      const { variant, changeVariant } = this;

      changeVariant({
        _id: variant._id,
        help: text,
      });
    },
    setQuestionForVariant(questionObj, questionId) {
      const { variant, setQuestion } = this;
      const params = {
        variant: variant._id,
        questionObj,
      };
      if (questionId) params.question = questionId;

      setQuestion(params);
    },
    removeQuestion(questionId) {
      const { variant, deleteQuestion } = this;

      deleteQuestion({ variant: variant._id, question: questionId });
    },
    uploadProxy(e) {
      this.$emit('uploadFile:open', ...arguments);
    },
    setGroupForVariant(groupObj, groupId) {
      const { variant, setGroup } = this;
      const params = {
        variant: variant._id,
        groupObj,
      };
      if (groupId) params.group = groupId;

      setGroup(params);
    },
    setWrongGroupForVariant(groupObj, groupId) {
      const { variant, setWrongGroup } = this;
      const params = {
        variant: variant._id,
        groupObj,
      };
      if (groupId) params.group = groupId;

      setWrongGroup(params);
    },
    deletWrongGroup() {
      const { variant, deleteWrongGroup } = this;

      deleteWrongGroup({ variant: variant._id });
    },
    deletGroup(groupId) {
      const { variant, deleteGroup } = this;

      deleteGroup({ variant: variant._id, group: groupId });
    },
    setAnswerForGroup(groupId, answerId, answerObj) {
      const { variant, setAnswer } = this;
      const params = {
        variant: variant._id,
      };
      if (answerId) params.answer = answerId;
      if (answerObj) params.answerObj = answerObj;
      if (groupId) params.group = groupId;

      setAnswer(params);
    },
    changeNameVariant(e) {
      this.changeVariant({ _id: this.variant._id, name: e.target.value });
    },
  },
  mounted() {
    if (this.variant.difficulty) {
      this.selectedDifficulty = this.difficultList.find((d) => d.type === this.variant.difficulty);
    }
  },
};
</script>

<style lang="less">
.editable-variant {
  border: 1px solid #eaeaea;
  border-bottom: none;

  &:last-of-type {
    border-bottom: 1px solid #eaeaea;
  }

  &__head {
    display: flex;
    align-items: stretch;
    position: relative;
    cursor: pointer;
    padding-right: 40px;
  }

  &__body {
    border-top: 1px solid #eaeaea;
    background: #f1f5f8;
    &__input {
      width: 100%;
      padding: 10px;
    }
  }

  &__index {
    padding: 10px 16px;
    border-right: 1px solid #eaeaea;
    line-height: 1.4;
    color: #4507ca;
    font-weight: 600;
    width: 50px;
    text-align: right;
  }

  &__type {
    padding: 10px;
    font-size: 12px;
    align-self: center;
    text-transform: uppercase;
    color: #66626e;
  }

  &__difficulty {
    margin-left: auto;
  }

  &__controls-wrap {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 5px;
  }

  &__control {
    padding: 5px;
  }

  &__help-wrap {
    padding: 0 10px 20px;
  }
}
</style>
