<template>
  <div class="editable-grouping-answers">
    <base-checkbox v-model="practice.showAll" @input="$emit('set-question', { showAll: $event })" class="mb-20"
      >Показывать карточки все вместе</base-checkbox
    >
    <switcher
      class="my-10"
      :values="[
        { name: 'HTML', value: 'html' },
        { name: 'Редактор', value: 'wysiwyg' },
      ]"
      :selected="practice && practice.contentType"
      @switcher:change="handleChangeType"
    ></switcher>
    <plain-html-editor
      class="mb-10"
      :content="practice.question"
      @setContent="saveText"
      v-if="practice.contentType === 'html'"
    ></plain-html-editor>

    <base-editor v-else :value="practice.question" placeholder="Условие" @save-text="saveText" />

    <div class="editable-grouping-answers__group group" v-for="(group, index) in practice.groups" :key="group._id">
      <div>
        <span>Группа {{ index + 1 }}</span>
        <span class="editable-grouping-answers__group-remove" @click="deleteGroup(group)">
          <base-icon glyph="#icon-close" width="20px" />
        </span>
      </div>
      <base-input
        class="group__name"
        placeholder="Имя группы"
        type="text"
        name="name"
        :value="group.name"
        @blur="saveGroupsName($event, group._id)"
      />

      <base-editor :value="group.question" placeholder="Описание группы" @save-text="saveQuestion($event, group._id)" />

      <div class="mb-10">Ответы группы {{ index + 1 }}:</div>

      <div class="editable-grouping-answers__answer" v-for="answer in group.answers" :key="answer._id">
        <base-editor :value="answer.value" placeholder="Ответ" @save-text="saveAnswer($event, group._id, answer._id)" />
        <span class="editable-grouping-answers__answer-remove" @click="$emit('deleteAnswer', answer._id)">
          <base-icon glyph="#icon-close" width="20px" />
        </span>
      </div>

      <base-button theme="dashed" @click="pushNewAnswer(group._id)">
        <base-icon glyph="#icon-plus" width="16px" />
        Добавить ответ
      </base-button>
    </div>

    <div class="editable-grouping-answers__group group" v-if="wrongAnswersGroupExist">
      <div>
        <span>Группа с неправильными ответами</span>
        <span class="editable-grouping-answers__group-remove" @click="deleteWrongGroup()">
          <base-icon glyph="#icon-close" width="20px" />
        </span>
      </div>

      <div class="mb-10">Ответы</div>

      <div class="editable-grouping-answers__answer" v-for="answer in practice.wrongAnswersGroup" :key="answer._id">
        <base-editor :value="answer.value" placeholder="Ответ" @save-text="saveAnswer($event, null, answer._id)" />
        <span class="editable-grouping-answers__answer-remove" @click="$emit('deleteAnswer', answer._id)">
          <base-icon glyph="#icon-close" width="20px" />
        </span>
      </div>

      <base-button theme="dashed" @click="pushNewAnswer(null)">
        <base-icon glyph="#icon-plus" width="16px" />
        Добавить ответ
      </base-button>
    </div>

    <base-button theme="dashed" @click="pushNewGroup">
      <base-icon glyph="#icon-plus" width="16px" />
      Добавить группу
    </base-button>

    <base-button v-if="!wrongAnswersGroupExist" theme="dashed" @click="pushNewWrongGroup">
      <base-icon glyph="#icon-plus" width="16px" />
      Добавить группу неправильных ответов
    </base-button>
  </div>
</template>

<script>
import PlainHtmlEditor from '@/components/Common/PlainHtmlEditor';
import Switcher from '@/components/Common/Switcher';

export default {
  name: 'EditableGroupingAnswers',
  components: { PlainHtmlEditor, Switcher },
  data() {
    return {
      wrongAnswersGroupExist: false,
    };
  },
  props: {
    practice: Object,
  },

  methods: {
    saveText(text) {
      this.$emit('set-question', { question: text });
    },
    pushNewGroup() {
      this.$emit('setGroup', { name: '', question: '', answers: [] });
    },
    pushNewWrongGroup() {
      this.wrongAnswersGroupExist = true;
      this.$emit('set-wrong-group', { wrongAnswersGroup: [] });
    },
    deleteGroup({ _id }) {
      this.$emit('deleteGroup', _id);
    },
    deleteWrongGroup() {
      this.wrongAnswersGroupExist = false;
      this.$emit('delete-wrong-group');
    },
    saveGroupsName(e, groupId) {
      this.$emit('setGroup', { name: e.target.value }, groupId);
    },
    saveQuestion(value, groupId) {
      this.$emit('setGroup', { question: value }, groupId);
    },
    pushNewAnswer(groupId) {
      this.$emit('setAnswer', groupId);
    },
    saveAnswer(value, groupId, answerId) {
      this.$emit('setAnswer', groupId, answerId, { value });
    },
    handleChangeType(type) {
      if (confirm('Текст вопроса, при смене типа контента будет удален.\nВсе равно поменять тип контента?')) {
        let question = '';
        if (type === 'html') {
          question = this.practice.question;
        }
        this.$emit('set-question', { contentType: type });
        this.practice.question = question;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.editable-grouping-answers {
  padding: 20px 10px 10px;

  &__group-remove {
    margin-left: 10px;
    cursor: pointer;
  }

  &__answer {
    display: flex;

    &-remove {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
</style>

<style lang="less" scoped>
.group {
  padding-bottom: 10px;
  & + & {
    border-top: 2px dashed #dcdcdc;

    padding-top: 10px;
  }

  &__name {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
</style>
