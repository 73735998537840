<template>
  <div class="page-lessons">
    <div class="page-lessons__header">
      <h2>{{ title }}</h2>
      <router-link
        v-bind:to="{
          name: 'newlesson',
          params: { oldFormat },
        }"
      >
        <base-button theme="default" size="large" font-size="big"> Создать урок </base-button>
      </router-link>
      <multiselect
        class="multiselect--theme-combobox"
        v-model="selectedType"
        :options="types"
        track-by="key"
        label="value"
        :searchable="false"
        :show-labels="false"
        :allow-empty="false"
        :preselect-first="true"
      />
      <multiselect
        class="multiselect--theme-combobox"
        placeholder="Предмет"
        v-model="selectedSubject"
        :options="subjects"
        track-by="_id"
        label="name"
        :searchable="false"
        :show-labels="false"
        @input="setSubject"
      />
      <multiselect
        class="multiselect--theme-combobox"
        placeholder="Подпредмет"
        v-model="selectedSubjectArea"
        :options="subjectAreas"
        track-by="_id"
        label="name"
        :disabled="!selectedSubject || !subjectAreas.length"
        :searchable="false"
        :show-labels="false"
        :show-no-options="false"
        @input="setSubjectArea"
      />
    </div>
    <div class="page-lessons__body">
      <div class="page-lessons__pending" v-if="pending">
        <base-spinner size="50px"></base-spinner>
      </div>
      <div v-else>
        <div class="page-lessons__empty-list" v-if="!lessons.length">Список уроков пуст</div>
        <lesson v-else v-for="lesson in lessons" :key="lesson._id" :lesson="lesson" :isOld="oldFormat" />
      </div>
    </div>
    <div class="click-wrapper" v-if="newLessonData.isOpenModal" @click="closeModal"></div>

    <div class="modal-dublicate-lesson" v-if="newLessonData.isOpenModal">
      <div class="modal-dublicate-lesson__header">
        <h2>Создание дубликата урока</h2>
        <div @click="closeModal" class="modal-dublicate-lesson__close-button">Х</div>
      </div>
      <div class="modal-dublicate-lesson__name">
        <p>Введите название нового урока</p>
        <div class="modal-dublicate-lesson__input">
          <div class="modal-dublicate-lesson_error">{{ newLesson.errorMsg.name }}</div>
          <input
            :disabled="newLesson.isCreateNewLesson"
            type="text"
            :value="newLessonData.name"
            @input="changeName($event.target.value)"
          />
        </div>
      </div>
      <div class="modal-dublicate-lesson__path">
        <p>Введите путь к новому уроку</p>
        <div class="modal-dublicate-lesson__input">
          <div class="modal-dublicate-lesson_error">{{ newLesson.errorMsg.path }}</div>
          <input
            :disabled="newLesson.isCreateNewLesson"
            type="text"
            :value="newLessonData.path"
            @input="changePath($event.target.value)"
          />
        </div>
      </div>
      <button :disabled="isError || isEmpty" v-if="!newLesson.isCreateNewLesson" @click="createDublicateLesson">
        Создать урок
      </button>
      <p class="modal-dublicate-lesson_success" v-if="newLesson.isCreateNewLesson">Урок успешно создан</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Multiselect from 'vue-multiselect';

import Lesson from '@/components/Lesson';
import slugify from '@/helpers/slugify';

export default {
  name: 'PageLessons',
  components: {
    Multiselect,
    Lesson,
  },
  data() {
    return {
      newLesson: {
        isCreateNewLesson: false,
        errorMsg: {
          name: 'Урок с данным названием существует',
          path: 'Путь занят',
        },
      },
      pending: false,
      lessonsData: [],
      selectedType: null,
      selectedSubject: null,
      selectedSubjectArea: null,
      subjectAreas: [],
    };
  },
  props: {
    title: {
      type: String,
      default: 'Уроки',
    },
    oldFormat: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('subjects', ['subjects']),
    ...mapGetters('lessons', ['newLessonData']),
    lessons() {
      return this.lessonsData.filter((lesson) => lesson.oldFormat === this.oldFormat);
    },
    isError() {
      return this.newLesson.errorMsg.name || this.newLesson.errorMsg.path;
    },
    isEmpty() {
      return !this.newLessonData.name || !this.newLessonData.path;
    },
    types() {
      const result = [
        { key: 'all', value: 'Все' },
        { key: '2', value: 'Обработанные' },
        { key: '1', value: 'В работе' },
      ];
      if (this.oldFormat) {
        result.push({ key: '0', value: 'Необработанные' });
      } else {
        result.push({ key: '3', value: 'Новые' });
      }

      this.selectedType = result[0];

      return result;
    },
  },
  watch: {
    selectedType: {
      immediate: true,
      handler(val) {
        this.getFilteredLessons();
      },
    },
  },
  methods: {
    ...mapActions('lessons', ['getListLessons', 'dublicateLesson']),
    ...mapActions('subjects', ['fetchSubjects', 'fetchSubjectAreas', 'fetchSubjectThemes']),
    async createDublicateLesson() {
      try {
        await this.dublicateLesson({
          lessonId: this.newLessonData._id,
          name: this.newLessonData.name,
          path: this.newLessonData.path,
        });
        this.newLesson.isCreateNewLesson = true;
      } catch (e) {
        const fieldType = e.response.data.field;
        const errorMessage = e.response.data.message;
        this.newLesson.errorMsg[fieldType] = errorMessage;
        alert(errorMessage);
      }
    },
    changeName(value) {
      this.newLesson.errorMsg.name = '';
      this.$store.commit('lessons/SET_NEW_LESSON_DATA', { name: value, path: slugify(value) || '' });
      this.newLesson.errorMsg.path = '';
    },
    changePath(value) {
      this.$store.commit('lessons/SET_NEW_LESSON_DATA', { path: value });
    },
    closeModal() {
      this.$store.commit('lessons/SET_NEW_LESSON_DATA', { isOpenModal: false, name: null, path: null });
      this.newLesson.name = null;
      this.newLesson.path = null;
      this.newLesson.errorMsg.path = 'Путь занят';
      this.newLesson.errorMsg.name = 'Урок с данным названием существует';
      this.newLesson.isCreateNewLesson = false;
    },
    async setSubject(subject) {
      this.selectedSubjectArea = null;
      if (subject) this.subjectAreas = await this.fetchSubjectAreas({ subject: subject._id });
      this.getFilteredLessons();
    },

    setSubjectArea(subjectArea) {
      this.getFilteredLessons();
    },

    async getFilteredLessons() {
      const { selectedType, selectedSubject, selectedSubjectArea } = this;

      this.pending = true;
      const params = {};
      if (selectedType && selectedType.key !== 'all') params.status = selectedType.key;
      if (selectedSubject) params.subject = selectedSubject._id;
      if (selectedSubjectArea) params.subjectArea = selectedSubjectArea._id;
      params.isDeleted = false;

      this.lessonsData = await this.getListLessons(params);
      this.pending = false;
    },
  },
  mounted() {
    this.fetchSubjects();
    if (!this.oldFormat) {
      this.getFilteredLessons();
    }

    this.$on('getFilteredLessons', () => {
      this.getFilteredLessons();
    });
  },
};
</script>

<style lang="less">
@import '~less_vars';
.click-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.47);
  z-index: 10;
}
.modal-dublicate-lesson {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 650px;
  left: 50%;
  right: 50%;
  top: 10%;
  gap: 20px;
  transform: translate(-50%, 50%);
  background: #e6eaec;
  z-index: 12;
  padding: 10px;
  border-radius: 20px;
  &_success {
    color: green;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__close-button {
    font-size: 30px;
    padding-right: 20px;
    cursor: pointer;
  }
  &__input {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
  }
  &_error {
    color: red;
  }
  button {
    height: 50px;
    width: 50%;
    margin: 0 auto;
  }
  &__name {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    align-items: center;
  }
  &__path {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    align-items: center;
  }
}
.page-lessons {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    padding: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaeaea;

    h2 {
      margin: 0;
      margin-right: auto;
      font-size: @h2;
    }

    .multiselect {
      max-width: 200px;
      margin-left: 10px;
    }
  }

  &__body {
    flex: 1;
    padding: 10px 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__pending {
    padding: 15px;

    .base-spinner {
      margin: 0 auto;
    }
  }

  &__empty-list {
    padding: 15px;
  }
}
</style>
