<template>
  <component
    class="base-btn"
    :is="tag"
    :to="to"
    :href="href"
    :class="applyClasses"
    :disabled="disabled"
    :type="type"
    @click="$emit('click', $event)"
    ><slot>Кнопка</slot>
  </component>
</template>

<script>
import { toBemModifer } from '@/helpers/index';

export default {
  name: 'BaseButton',
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    to: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    theme: {
      type: [String, Array],
      default: 'default',
    },
    size: {
      type: String,
      default: null,
    },
    variant: {
      type: [String, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
    },
  },
  computed: {
    applyClasses() {
      const { pending, disabled, size, theme, variant, fontSize } = this;
      const baseClass = 'base-btn';
      const result = [
        ...toBemModifer(theme, baseClass, 'theme'),
        ...toBemModifer(size, baseClass, 'size'),
        ...toBemModifer(variant, baseClass, 'variant'),
        ...toBemModifer(fontSize, baseClass, 'fz'),
      ];

      if (disabled) {
        result.push('is-disabled');
      }

      if (pending) {
        result.push('is-pending');
      }

      return result;
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';
.base-btn {
  height: @btn-height;
  display: inline-block;
  background-color: #c0c0c0;
  text-decoration: none;
  outline: none;
  user-select: none;
  vertical-align: middle;
  line-height: @btn-height;
  padding: 0 15px;
  border: none;
  border-radius: 2px;
  font-family: inherit;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background 0.2s, color 0.2s;

  &:active {
    transform: translateY(2px);
  }

  .base-icon {
    vertical-align: initial;
    margin-right: 5px;
  }

  &.is-disabled {
    opacity: 0.4;
  }

  // themes
  &--theme-default {
    background-color: @primary-color;
    color: #fff;
    --primaryIconColor: #fff;
    --secondaryIconColor: #fff;

    &:hover {
      background-color: darken(@primary-color, 5%);
    }
  }

  &--theme-light-gray {
    background-color: #e4e4e4;
    color: @base-color;
    --primaryIconColor: @base-color;
    --secondaryIconColor: @base-color;

    &:hover {
      background-color: darken(#e4e4e4, 5%);
    }
  }

  &--theme-disabled {
    background-color: @gray-disabled;
    color: #fff;
  }

  &--theme-invert {
    background-color: #fff;
    color: @primary-color;
    border: 1px solid @primary-color;
  }

  &--theme-invert-1 {
    background-color: #fff;
    color: @secondary-color;
  }

  &--theme-invert-2 {
    background-color: #fff;
    color: @primary-color;
  }

  &--theme-secondary {
    background-color: @secondary-color;
    transition: background 0.3s;
    color: #fff;
    font-size: @base-fz;
    --primaryIconColor: #fff;
    --secondaryIconColor: #fff;

    &:hover {
      background-color: darken(@secondary-color, 5%);
    }
  }

  &--theme-dashed {
    border: 2px dashed #dcdcdc;
    padding: 15px 20px;
    text-align: center;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 4px;
    font-size: @base-fz;
    text-transform: uppercase;
    color: #4507ca;
    font-weight: 600;
    width: 100%;
    height: initial;
    line-height: inherit;
    background: @gray-hover;
    will-change: opacity;

    &:hover {
      opacity: 1;
    }

    .base-icon {
      vertical-align: top;
    }
  }

  // variants
  &--variant-toggle {
    border-radius: 0;
    height: @btn-height-toggle;
    line-height: @btn-height-toggle;

    &:active {
      transform: none;
    }
  }

  &--variant-toggle&--theme-disabled:hover {
    background: #8f97a9;
  }

  &--variant-arrow {
    position: relative;
    overflow: visible;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      border-left: 10px solid @primary-color;
      border-top: 21px solid transparent;
      border-bottom: 21px solid transparent;
    }

    .base-icon {
      vertical-align: text-top !important;
    }
  }

  &--variant-arrow-reverse {
    position: relative;
    overflow: visible;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      border-right: 10px solid @primary-color;
      border-top: 21px solid transparent;
      border-bottom: 21px solid transparent;
    }
  }

  &--variant-with-icon {
    position: relative;
    padding-left: 35px;

    .base-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &--variant-only-icon {
    position: relative;
    padding: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;

    .base-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  // sizes
  &--size-large {
    line-height: @btn-height-large;
    height: @btn-height-large;
    font-size: @large-fz;
    @media (max-width: 768px) {
      font-size: @big-fz;
      height: @btn-height;
      line-height: @btn-height;
    }
  }

  &--size-big {
    font-size: @bigger-fz;
    padding: 30px;
  }

  // font-sizes
  &--fz-base {
    font-size: @base-fz;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &--fz-medium {
    font-size: @medium-fz;
    line-height: @medium-lh;
    @media (max-width: 768px) {
      font-size: @small-fz;
    }
  }

  &--fz-big {
    font-size: @big-fz;
    @media (max-width: 768px) {
      font-size: @base-fz;
    }
  }

  &--fz-large {
    font-size: @large-fz;
    @media (max-width: 768px) {
      font-size: @big-fz;
    }
  }

  &--variant-block {
    display: block;
    width: 100%;
    text-align: center;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &--variant-animated {
    padding: 10px;
    margin: 15px 0;
    font-size: 16px;
    line-height: 22px;
    will-change: opacity;
    //transition: all 0.2s cubic-bezier(0.18, 0.38, 0.04, 0.87);

    &:hover {
      //padding: 25px;
      //font-size: 18px;
    }
  }
}
</style>
