<template>
  <draggable class="drag-zone" v-model="list" :options="dragOptions" @end="endHandler">
    <structure-unit
      v-for="(unit, i) in structure"
      :key="`structure-unit-${unit._id}`"
      :stuctureId="unit._id"
      :blockId="unit.block._id"
      :name="unit.block.name"
      :blockType="unit.block.type"
      :lesson="unit.lesson"
      :idx="unit.idx"
      :pending="!unit.block.type"
      :tags="null"
      :visible="unit.visible"
      :isEmptyTest="unit.block.type === 'test' && unit.block.variants && !unit.block.variants.length"
    >
      <div slot="top-controls">
        <button v-if="showScrollbtn && unit.visible" class="preview-btn" @click="$emit('structure:scrollblock', unit.block._id)">
          Preview
        </button>
      </div>
      <div slot="controls">
        <base-button @click.prevent="handleVisibility(unit._id)" theme="light-gray">
          <span v-text="unit.visible ? 'скрыть' : 'показать'"></span>
        </base-button>
        <base-button @click.prevent="handleDelete(unit._id)" theme="light-gray"> Удалить </base-button>
        <!-- <base-button
          v-if="i !== 0"
          @click.prevent="handleSwap(unit.idx, -1)">
          Выше
        </base-button>
        <base-button
          v-if="i !== structure.length - 1"
          @click.prevent="handleSwap(unit.idx, 1)">
          Ниже
        </base-button> -->
      </div>
    </structure-unit>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState } from 'vuex';
import StructureUnit from './StructureUnit';

export default {
  name: 'StructureEditor',
  components: { StructureUnit, draggable },
  props: {
    structure: {
      type: Array,
      required: true,
    },
    dragOptions: {
      type: Object,
    },
    showScrollbtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeIdx: 0,
    };
  },
  computed: {
    ...mapState({
      typeList: (state) => state.lessonBlocks.blockTypes,
    }),
    list: {
      get() {
        return this.structure;
      },
      set(val) {
        this.$emit('structure:optiUpdate', val);
      },
    },
  },
  methods: {
    handleVisibility(id) {
      this.$emit('structure:visibility', id);
    },
    handleDelete(id) {
      this.$emit('structure:delete', id);
    },
    handleSwap(idx, direction) {
      this.$emit('structure:swap', {
        idx,
        direction,
      });
    },
    endHandler(e) {
      this.$emit('structure:orderChange', e.newIndex);
    },
  },
};
</script>
<style scoped>
.drag-zone {
  min-height: 100%;
  padding: 20px 15px;
}
</style>
