<template>
  <div class="editable-algorithm-answers">
    <div class="editable-choosed-answers__title">
      <switcher
        class="my-10"
        :values="[
          { name: 'HTML', value: 'html' },
          { name: 'Редактор', value: 'wysiwyg' },
        ]"
        :selected="practice && practice.contentType"
        @switcher:change="handleChangeType"
      ></switcher>
      <plain-html-editor
        class="mb-10"
        :content="practice.question"
        @setContent="saveText"
        v-if="practice.contentType === 'html'"
      ></plain-html-editor>
      <base-editor v-else :value="practice.question" placeholder="Условие" @save-text="saveText" />
    </div>
    <div>
      <base-file @basefile:edit="toggleOperationVideoModal" label="Видео" :removable="false" :name="practice.video">
        <div slot="label" class="video-title">Общий видеофайл, mp4</div>
      </base-file>
      <div class="operation-title">Список операций</div>
      <div v-if="!practice.video" class="text-meta mb-10">Чтобы создать список операций, загрузите видео</div>
      <div class="operation-list" v-else>
        <div class="operation" v-for="operation in operationList" :key="operation._id">
          <button title="Удалить операцию" class="operation__remove" @click="$emit('deleteAnswer', operation._id)">
            <base-icon glyph="#icon-close" width="22px" height="22px" />
          </button>
          <div class="operation__label">Текст или формула</div>
          <base-input
            placeholder="Операция"
            type="text"
            class="mb-20"
            name="value"
            :value="operation.value"
            @blur="updateOperationValue($event, operation._id)"
          />

          <div class="operation__row">
            <div class="operation__col">
              <div class="operation__label">Диапазон, милисекунды:</div>
              <div class="operation__timing">
                <span>От</span>
                <base-input
                  class="operation__timing-input mr-10"
                  placeholder=""
                  type="number"
                  name="min"
                  :value="operation.range[0]"
                  @blur="updateOperationRange($event, operation._id)"
                />
                <span>До</span>
                <base-input
                  class="operation__timing-input"
                  placeholder=""
                  type="number"
                  :value="operation.range[1]"
                  @blur="updateOperationRange($event, operation._id)"
                  name="max"
                />
              </div>
            </div>
            <div class="operation__col">
              <div class="operation__label">Порядок:</div>
              <base-input
                placeholder="Порядок"
                type="text"
                name="idx"
                class="mb-20"
                :value="operation.idx"
                @blur="updateIdx($event, operation._id)"
              />
            </div>
          </div>
        </div>

        <base-button theme="dashed" @click="createOperation"> Добавить операцию </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import PlainHtmlEditor from '@/components/Common/PlainHtmlEditor';
import Switcher from '@/components/Common/Switcher';
import ModalUploadFile from '@/components/Modal/ModalUploadFile';

export default {
  name: 'EditableAlgorithmAnswers',
  props: { practice: Object },
  components: {
    PlainHtmlEditor,
    Switcher,
  },
  methods: {
    toggleOperationVideoModal() {
      this.openUploadFileModal(this.practice.video, this.handleSaveVideo);
    },
    handleSaveVideo(link) {
      this.$emit('set-question', { video: link });
    },
    handleChangeType(type) {
      if (confirm('Текст вопроса, при смене типа контента будет удален.\nВсе равно поменять тип контента?')) {
        let question = '';
        if (type === 'html') {
          question = this.practice.question;
        }
        this.$emit('set-question', { contentType: type });
        this.practice.question = question;
      }
    },
    saveText(text) {
      this.$emit('set-question', { question: text });
    },
    openUploadFileModal(current, handleSave) {
      this.$emit('vuedals:new', {
        name: 'upload-file',
        component: ModalUploadFile,
        dismissable: false,
        size: 'sm',
        props: {
          current,
          handleSave,
        },
      });
    },
    updateIdx(e, _id) {
      if (!_id) return;
      this.updateOperation(_id, { idx: +e.target.value });
    },
    updateOperationRange(e, _id) {
      const answr = this.operationList.find((n) => n._id === _id);
      const { name, value } = e.target;
      const range = [...answr.range];
      if (name === 'min') {
        range[0] = +value;
      } else if (name === 'max') {
        range[1] = +value;
      }
      this.$emit('setAnswer', { ...answr, range }, answr._id);
    },
    updateOperation(_id, payload) {
      const answr = this.operationList.find((n) => n._id === _id);
      this.$emit('setAnswer', { ...answr, ...payload }, answr._id);
    },
    createOperation() {
      const diff = 10;
      const answr = {
        value: 'Текст операции',
        range: [0, diff],
      };
      if (this.operationList.length) {
        const sorted = this.operationList.sort((a, b) => b.idx - a.idx);
        const lastItem = sorted[0];
        answr.range = [lastItem.range[1], lastItem.range[1] + diff];
        answr.idx = lastItem.idx + 1;
      }
      this.$emit('setAnswer', answr);
    },
    updateOperationValue(e, _id) {
      if (!_id) return;
      this.updateOperation(_id, { value: e.target.value });
    },
  },
  computed: {
    operationList() {
      const answers = [...this.practice.answers];
      return answers.sort((a, b) => a.idx - b.idx);
    },
  },
};
</script>

<style lang="less" scoped>
@import '~less_vars';
.video-title {
  margin-bottom: 0;
  font-weight: 600;
  margin-top: 15px;
}
.operation {
  padding: 20px;
  background-color: #fff;
  margin: 10px auto;
  position: relative;
  padding-right: 50px;

  &__row {
    display: flex;
    flex-direction: row;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__col {
    flex: 0 0 60%;
    max-width: 60%;
    padding: 0 15px;

    &:last-child {
      max-width: 40%;
      flex: 0 0 40%;
    }
  }

  &-title {
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  &__remove {
    width: 32px;
    height: 32px;
    border: 1px solid #d8e1e7;
    outline: none;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    padding: 0;
    box-shadow: none;
    position: absolute;
    right: 5px;
    top: 5px;

    &:hover {
      border-color: @primary-color;
    }
  }

  &__label {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
  }

  &__timing {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > span {
      margin-right: 10px;
    }

    &-input {
      max-width: 100px;
      flex-grow: 0;

      & + span {
        margin-left: 10px;
      }
    }
  }
}
.editable-algorithm-answers {
  padding: 10px;

  &__title {
    margin-bottom: 20px;
  }
}
</style>
