<template>
  <div class="editable-text">
    <switcher
      class="my-10"
      :values="[
        { name: 'HTML', value: 'html' },
        { name: 'Редактор', value: 'wysiwyg' },
      ]"
      :selected="block && block.contentType"
      @switcher:change="handleChangeType"
    ></switcher>
    <plain-html-editor
      class="mb-10"
      :content="block.text"
      @setContent="saveText"
      v-if="block.contentType === 'html'"
    ></plain-html-editor>
    <base-editor v-else :value="block.text" placeholder="Содержание блока" @save-text="saveText" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PlainHtmlEditor from '@/components/Common/PlainHtmlEditor';
import Switcher from '@/components/Common/Switcher';

export default {
  name: 'EditableText',
  props: {
    block: Object,
  },
  components: {
    PlainHtmlEditor,
    Switcher,
  },
  methods: {
    ...mapActions('lessonBlocks', ['changeBlock']),

    saveText(text) {
      if (this.block.text === text) return;
      this.$emit('save', this.block._id, 'text', text, (data) => this.changeBlock(data));
    },
    handleChangeType(type) {
      if (confirm('Текст вопроса, при смене типа контента будет удален.\nВсе равно поменять тип контента?')) {
        let text = '';
        if (type === 'html') {
          text = this.block.text;
        }
        this.changeBlock({
          _id: this.block._id,
          text,
          contentType: type,
        });
      }
    },
  },
};
</script>
