<template>
  <div class="struct-search">
    <base-input
      placeholder="Название блока"
      type="text"
      name="name"
      :value="query"
      autocomplete="off"
      @enter="handleSubmit"
      @input="query = arguments[0].target.value"
    />
    <div class="filters">
      <slot name="filters" />
    </div>

    <div class="struct-search__controls">
      <base-button @click="handleSearch">Искать</base-button>
      <base-button theme="light-gray" @click="handleReset">Сбросить</base-button>
    </div>

    <draggable v-model="test" :options="dragOptions">
      <structure-unit
        v-for="item in test"
        :key="`search-result-${item._id}`"
        :blockId="item._id"
        :blockType="item.type"
        :name="item.name"
        :tags="item.tags"
      >
        <div slot="controls">
          <base-button theme="light-gray" @click.prevent="handleClick(item._id)">Добавить</base-button>
        </div>
      </structure-unit>
    </draggable>
    <div v-if="results && results.length">
      <base-button @click="handleLoadMoreClick"> Загрузить еще 20 блоков </base-button>
    </div>
    <div v-if="results && results.length === 0">Ничего не найдено</div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import StructureUnit from './StructureUnit';

export default {
  name: 'Search',
  components: {
    StructureUnit,
    draggable,
  },
  props: {
    results: {
      type: Array,
      default: null,
    },
    dragOptions: {
      type: Object,
    },
  },
  data() {
    return {
      pending: false,
      query: null,
    };
  },
  computed: {
    test: {
      get() {
        return this.results;
      },
      set(val) {
        this.$emit('search:update', val);
      },
    },
  },
  methods: {
    handleSearch() {
      this.$emit('search:get', { query: this.query });
    },
    handleSubmit() {},
    handleClick(id) {
      this.$emit('search:select', id);
    },
    handleReset() {
      this.query = null;
      this.$emit('search:reset');
    },
    handleLoadMoreClick() {
      this.$emit('search:get', { query: this.query, skip: this.results.length });
    },
  },
};
</script>

<style lang="less" scoped>
.struct-search {
  padding: 20px 15px;

  &__controls {
    margin-bottom: 20px;
  }
}
</style>
