<template>
  <div class="page-new-lesson">
    <form action="" v-on:submit="onSubmit" name="newlesson" class="page-new-lesson__form">
      <div class="page-new-lesson__header">
        <h2>Создание урока</h2>
      </div>
      <div class="page-new-lesson__body">
        <div class="page-new-lesson__row">
          <div class="page-new-lesson__col">
            <base-input
              placeholder="Название урока*"
              type="text"
              name="name"
              ref="lessonName"
              :value="data.name"
              @input="updateLesson"
            />
            <div class="field-error" v-if="errors.name">{{ errors.name }}</div>
          </div>
        </div>
        <div class="page-new-lesson__row">
          <div class="page-new-lesson__col--half">
            <multiselect
              class="multiselect--theme-combobox"
              placeholder="Предмет*"
              v-model="data.subject"
              :options="subjects"
              track-by="_id"
              label="name"
              ref="subject"
              :searchable="false"
              :show-labels="false"
              @input="setSubject"
            />
            <div class="field-error" v-if="errors.subject">{{ errors.subject }}</div>
          </div>
          <div class="page-new-lesson__col--half">
            <multiselect
              class="multiselect--theme-combobox"
              placeholder="Подпредмет"
              v-model="data.subjectArea"
              :options="subjectAreasList"
              track-by="_id"
              label="name"
              ref="subjectArea"
              :searchable="false"
              :show-labels="false"
              :disabled="!data.subject || !subjectAreasList.length"
            />
          </div>
        </div>
        <div class="page-new-lesson__row" v-if="false">
          <div class="page-new-lesson__col">
            <multiselect
              class="multiselect--theme-combobox"
              placeholder="Тема урока"
              v-model="themesSearch"
              :options="subjectThemesList"
              track-by="_id"
              label="name"
              :disabled="!data.subjectArea && data.subjectAreasList !== 0"
              :searchable="true"
              :show-labels="false"
              :close-on-select="true"
              :internal-search="false"
              :show-no-options="false"
              @search-change="handleThemeChange"
              :loading="isLoadingThemes"
            >
              <span slot="noResult">
                <span v-if="themesSearch.length < 3">Необходимо ввести минимум 3 символа</span>
                <span v-else>Ничего не найдено</span>
              </span>
            </multiselect>
            <div class="field-error" v-if="errors.subjectTheme">{{ errors.subjectTheme }}</div>
          </div>
        </div>
        <div class="page-new-lesson__row">
          <div class="page-new-lesson__col--half">
            <div class="flex-wrapper">
              <span class="page-new-lesson__label">Номер класса: </span>
              <base-input
                placeholder="Номер класса*"
                type="number"
                name="classNumber"
                ref="classNumber"
                :value="data.classNumber"
                @input="updateLesson"
              />
              <div class="field-error" v-if="errors.classNumber">{{ errors.classNumber }}</div>
            </div>
          </div>
        </div>

        <!-- <div class="page-new-lesson__row">
          <div class="page-new-lesson__col">
            <div class="flex-wrapper">
              <input
                type="file"
                accept="image/png, image/jpeg"
                style="display: none;"
                ref="lessonPreview"
                v-on:change="onLoadPhoto"
              >
              <base-button
                theme="default"
                type="button"
                v-on:click="showFileDialog"
                style="align-self: flex-end;"
              >Загрузить превью</base-button>
              <div class="page-new-lesson__filename">{{data.photo && data.photo.name}}</div>

              <img :src="preview" alt="lesson_preview" class="page-new-lesson__preview" v-if="data.photo">
            </div>
          </div>
        </div> -->
      </div>
      <base-button theme="default" class="page-new-lesson__submit" :disabled="pending" type="submit" font-size="big">
        Создать урок
      </base-button>
    </form>
    <vuedal></vuedal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Component as Vuedal } from 'vuedals';
import Multiselect from 'vue-multiselect';

import ModalLessonCreated from '@/components/Modal/ModalLessonCreated';

export default {
  name: 'PageNewLesson',
  data() {
    return {
      data: {
        name: '',
        subject: null,
        subjectTheme: null,
        subjectArea: null,
        classNumber: 1,
        // photo: null,
        oldFormat: this.$route.params.oldFormat || false,
        tags: [],
        status: '3',
        blocks: [],
      },
      defaultData: {},
      pending: false,
      isLoadingThemes: false,
      themesSearch: '',
      // preview: "",
      errors: {},
    };
  },
  components: {
    Multiselect,
    Vuedal,
    ModalLessonCreated,
  },
  computed: {
    ...mapGetters('subjects', ['subjects', 'subjectAreas', 'subjectThemes']),
    subjectAreasList() {
      if (this.data.subject && this.subjectAreas) {
        return this.subjectAreas.filter((area) => area.subject === this.data.subject._id);
      }
      return [];
    },
    subjectThemesList() {
      const { subject, subjectArea } = this.data;

      if (subject && subjectArea && this.subjectThemes) {
        return this.subjectThemes.filter((theme) => theme.subject === subject._id && theme.subjectArea === subjectArea._id);
      }
      return [];
    },
  },
  methods: {
    ...mapActions('subjects', ['fetchSubjects', 'fetchSubjectAreas', 'fetchSubjectThemes']),
    ...mapActions('lessons', {
      createLesson: 'changeLesson',
    }),
    showFileDialog(e) {
      e.preventDefault();
      this.$refs.lessonPreview.click();
    },
    async handleThemeChange(value) {
      if (value && value.length >= 3) {
        this.isLoadingThemes = true;

        const query = {
          name: value,
          subject: this.data.subject._id,
        };
        if (this.data.subjectArea) {
          query.subjectArea = this.data.subjectArea._id;
        }

        await this.fetchSubjectThemes({ name: value });
        this.isLoadingThemes = false;
      }
      this.data.subjectTheme = value;
      this.themesSearch = value;
    },
    // onLoadPhoto(e) {
    //   this.data.photo = e.target.files[0];

    //   let reader = new FileReader();
    //   reader.onload = e => this.preview = e.target.result;

    //   reader.readAsDataURL(e.target.files[0]);
    // },
    updateLesson(e) {
      const { name, value } = e.target;
      this.data[name] = value;
    },
    async setSubject(val) {
      if (val) {
        await this.fetchSubjectAreas({ subject: val._id });

        if (!this.subjectAreasList.find((area) => area._id === val._id)) {
          this.data.subjectArea = null;
        }
      }
    },
    validate() {
      const { name, subject, subjectTheme, classNumber } = this.data;
      const errors = {};
      const reqMsg = 'Это поле необходимо заполнить';
      const numberMsg = 'Необходимо ввести число от 1 до 12';

      if (!name) {
        errors.name = reqMsg;
      }
      if (!subject) {
        errors.subject = reqMsg;
      }
      if (!subjectTheme) {
        // errors.subjectTheme = reqMsg;
      }
      if (!classNumber || !Number(classNumber) || Number(classNumber) > 12 || Number(classNumber) < 1) {
        errors.classNumber = numberMsg;
      }

      this.errors = errors;
    },
    async onSubmit(e) {
      e.preventDefault();

      this.validate();
      if (Object.keys(this.errors).length !== 0) return;

      this.pending = true;

      const data = { ...this.data };
      data.subject = data.subject._id;
      data.subjectArea = data.subjectArea && data.subjectArea._id;

      // if(!data.subjectTheme._id) {
      //   let theme = await this.create
      // }

      const response = await this.createLesson(data);

      const modalProps = {};
      if (response) {
        modalProps.lessonId = response._id;
      } else {
        modalProps.status = 'fail';
      }

      this.$emit('vuedals:new', {
        name: 'modal-lesson-created',
        component: ModalLessonCreated,
        props: modalProps,
        dismissable: false,
        size: 'md',
        onClose() {
          if (modalProps.status) {
            this.data = { ...this.defaultData };
          }
          this.pending = false;
        },
      });
    },
  },
  async mounted() {
    await this.fetchSubjects();
    this.defaultData = { ...this.data };
  },
};
</script>

<style lang="less">
@import '~less_vars';
.page-new-lesson {
  height: 100%;

  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 20px 15px;
    display: flex;
    border-bottom: 1px solid #eaeaea;

    h2 {
      margin: 0;
      margin-right: auto;
      font-size: @h2;
    }
  }

  &__body {
    flex: 1;
    padding: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }

  &__row {
    margin: 0 -15px 20px -15px;

    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }

  &__col {
    padding: 0 15px;

    &--half {
      width: 50%;
      padding: 0 15px;
      float: left;

      @media (max-width: 500px) {
        width: 100%;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__filename {
    border-bottom: 1px solid #d8e1e7;
    flex: 1;
    align-self: flex-end;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 150px;
  }

  // &__preview {
  //   max-width: 500px;
  //   min-width: 33%;
  //   max-height: 200px;
  //   margin-top: 15px;

  //   @media(max-width: 500px) {
  //     min-width: 100%;
  //     max-width: unset;
  //     max-height: unset;
  //   }
  // }

  &__label {
    white-space: nowrap;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: @small-fz;
    color: @primary-color;
  }

  &__submit {
    margin: 15px;
    max-width: 200px;
  }

  .flex-wrapper {
    flex-wrap: wrap;
  }

  .field-error {
    font-size: @small-fz;
    color: @secondary-color;
    width: 100%;
  }
}
</style>
