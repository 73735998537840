<template>
  <div class="base-spinner" :style="{ width: size, height: size }" :class="applyClasses">
    <div class="base-spinner__circle"></div>
  </div>
</template>

<script>
import { toBemModifer } from '@/helpers/index';

export default {
  name: 'BaseSpinner',
  props: {
    theme: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: '42px',
    },
    speed: {
      type: String,
      default: null,
    },
  },
  computed: {
    applyClasses() {
      const { speed, theme } = this;
      const baseClass = 'base-spinner';
      const result = [...toBemModifer(theme, baseClass, 'theme'), ...toBemModifer(speed, baseClass, 'speed')];

      return result;
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-spinner {
  display: block;
  position: relative;

  &::before {
    padding-top: 100%;
    content: '';
    display: block;
  }

  &__circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 80%;
    height: 80%;
    border: 3px solid @secondary-color;
    border-radius: 50%;
    border-top-color: #fff;
    animation: base-spinner-rotation 0.7s infinite linear both;
  }
}

@keyframes base-spinner-rotation {
  form {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(359deg);
  }
}
</style>
