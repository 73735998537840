import { render, staticRenderFns } from "./PlainHtmlEditor.vue?vue&type=template&id=22f83c58&scoped=true&"
import script from "./PlainHtmlEditor.vue?vue&type=script&lang=js&"
export * from "./PlainHtmlEditor.vue?vue&type=script&lang=js&"
import style0 from "./PlainHtmlEditor.vue?vue&type=style&index=0&id=22f83c58&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f83c58",
  null
  
)

export default component.exports