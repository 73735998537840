<template>
  <div class="page-edite-lesson">
    <div class="page-edite-lesson__header">
      <div class="page-edite-lesson__buttons">
        <div class="page-edite-lesson__status">
          <div class="page-edite-lesson__status-text">Статус урока:</div>
          <div class="page-edite-lesson__status-toggle">
            <base-button
              variant="toggle"
              font-size="base"
              :theme="lessonStatus === '1' ? 'default' : 'disabled'"
              @click="handleStatus('1')"
              >В работе</base-button
            >
            <base-button
              variant="toggle"
              font-size="base"
              :theme="lessonStatus === '2' ? 'default' : 'disabled'"
              @click="handleStatus('2')"
              >Обработан</base-button
            >
          </div>
        </div>
        <div class="page-edite-lesson__buttons-pending" v-if="pendingBtn">
          <base-spinner size="29px"></base-spinner>
        </div>
        <base-button v-else class="page-edite-lesson__download-lesson" theme="default" size="small" @click="handleDownloadClick"
          >Скачать архив</base-button
        >
      </div>
    </div>
    <div class="page-edite-lesson__structure-layout">
      <div class="page-edite-lesson__structure-layout__col">
        <div class="page-edite-lesson__structure-layout__block">
          <div class="page-edite-lesson__structure-layout__head">
            Структура урока
            <span v-if="structure">Блоки: {{ combinedStructure.length }}</span>
          </div>
          <div class="page-edite-lesson__structure-layout__body">
            <structure-editor
              v-if="structure"
              :structure="combinedStructure"
              :pending="pending"
              :showScrollbtn="activeTab === 'preview-tab'"
              :dragOptions="dragabbleOptions"
              @structure:delete="handleDelete"
              @structure:swap="handleSwap"
              @structure:visibility="handleVisibility"
              @structure:optiUpdate="optimisticUpdateList"
              @structure:orderChange="structureOrderHandler"
              @structure:scrollblock="handleScroll"
            />
          </div>
        </div>
      </div>
      <div class="page-edite-lesson__structure-layout__col">
        <div class="page-edite-lesson__structure-layout__block">
          <div class="page-edite-lesson__structure-layout__head">
            Поиск блоков
            <base-button
              v-for="item in tabs"
              :key="`tabs-item-${item.component}`"
              @click="activeTab = item.component"
              v-show="activeTab !== item.component"
              fontSize="base"
              >{{ item.title }}</base-button
            >
          </div>
          <div class="page-edite-lesson__structure-layout__body">
            <component
              :is="activeTab"
              :results="filtredResults"
              :blocks="previewBlocks"
              :dragOptions="searchDragOpts"
              @search:get="handleSearch"
              @search:select="handleSelect"
              @search:reset="handleReset"
              @search:update="searchResults = arguments[0]"
              @filters:update="handleFilters"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PreviewTab from '@/components/StructureEditor/PreviewTab';
import SearchTab from '@/components/StructureEditor/SearchTab';
import StructureEditor from '@/components/StructureEditor/StructureEditor';

export default {
  name: 'PageEditLesson',
  components: {
    SearchTab,
    PreviewTab,
    StructureEditor,
  },
  props: {
    lessonId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pending: false,
      pendingBtn: false,
      block: '',
      maxResults: 20,
      searchResults: null,
      structure: null,
      blockType: 'lessonblock',
      activeTab: 'search-tab',
      dragabbleOptions: {
        draggable: '.structure-unit',
        sort: true,
        group: {
          name: 'shared',
          put: true,
        },
      },
      searchDragOpts: {
        draggable: '.structure-unit',
        sort: false,
        group: {
          name: 'shared',
          put: false,
        },
      },
      tabs: [
        {
          component: 'search-tab',
          title: 'Поиск',
        },
        {
          component: 'preview-tab',
          title: 'Превьюшка',
        },
      ],
      searchFilter: null,
      lessonStatus: 3,
    };
  },
  computed: {
    lessonBlocksList() {
      if (!this.structure) return [];
      return this.structure.map((n) => n.block._id);
    },
    filtredResults() {
      /**
       * Убираем из результатов поиска
       * итемы которые уже есть в структуре
       */
      const { searchResults, lessonBlocksList } = this;
      if (!searchResults) return null;
      return searchResults.filter((n) => !lessonBlocksList.includes(n._id));
    },
    combinedStructure() {
      if (!this.structure) return [];
      return this.structure
        .map((item) => {
          const block = this.$store.state.DB.lessonBlocks[item.block._id];
          return {
            ...item,
            blockName: block && block.name,
            blockType: block && block.type,
          };
        })
        .sort((a, b) => (a.idx > b.idx ? 1 : a.idx === b.idx ? 0 : -1));
    },
    previewBlocks() {
      if (!this.combinedStructure) return;
      return this.combinedStructure.map((item) => {
        const block = this.$store.state.DB.lessonBlocks[item.block._id];
        return { ...block, isVisible: item.visible };
      });
    },
    structDragOpts() {
      // Отключаем драггалку, когда
      // запросы пендятся
      const { dragabbleOptions } = this;
      return { ...dragabbleOptions, disabled: this.pending };
    },
  },
  methods: {
    ...mapActions({
      getBlocks: 'fetchModel',
      getStructure: 'lessonBlocks/getStructure',
      addStructureBlock: 'lessonBlocks/addStructureBlock',
      deleteStructureBlock: 'lessonBlocks/deleteStructureBlock',
      visibleToggle: 'lessonBlocks/toggleStructureVisible',
      swapStructure: 'lessonBlocks/swapStructureBlock',
      addToStore: 'lessonBlocks/addBlocksToStore',
      changeBlockOrder: 'lessonBlocks/changeBlockOrder',
      downloadLessonArchive: 'lessons/downloadLessonArchive',
      fetchLessons: 'lessons/fetchLessons',
      changeLesson: 'lessons/changeLesson',
    }),

    handleScroll(blockId) {
      const element = document.getElementById(`perview_${blockId}`);
      if (!element) return;
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    },
    handleReset() {
      this.searchResults = [];
    },
    handleFilters(payload) {
      this.searchFilter = payload;
    },
    handleVisibility(id) {
      /**
       * TODO
       * Сейчас сделал просто запрос за структурой.
       * после смены статуса. Но надо бы по уму
       * мутировать данные в локальном стейте.
       * ЗЫ: я намеренно не складываю структуру во вьюкс
       * ибо нечего ей там делать, в основном
       */
      this.visibleToggle(id).then((response) => this.getLessonStructure());
    },
    handleDelete(id) {
      /**
       * Запрашиваем список по новой, после успешного
       * удаления, т.к. меняются индексы у структур
       */
      this.deleteStructureBlock(id).then((response) => this.getLessonStructure());
    },
    handleSelect(id) {
      this.addBlock({ id, idx: this.combinedStructure.length });
    },
    handleSwap({ idx, direction }) {
      const current = this.combinedStructure.find((n) => n.idx === idx);
      const next = this.combinedStructure.find((n) => n.idx === idx + direction);
      if (!current || !next) return;
      this.swapStructure({
        structure1: current._id,
        structure2: next._id,
      })
        .then(() => this.getLessonStructure())
        .catch((error) => {
          console.log(error);
        });
    },
    handleSearch({ query, skip = 0 }) {
      const { lessonBlocksList, getBlocks, maxResults, addToStore, searchFilter } = this;

      const defaultFilters = {
        name: { $regex: query },
        _id: { $nin: lessonBlocksList },
      };
      getBlocks({
        model: 'lessonblock',
        options: {
          params: {
            limit: maxResults,
            skip: skip || 0,
            filter: { ...defaultFilters, ...searchFilter },
          },
        },
      }).then((response) => {
        let result = [];
        if (skip !== 0) {
          result = this.searchResults || [];
        }
        this.searchResults = [...result, ...response.data.models];
        addToStore(response.data.models);
      });
    },
    handleStatus(status) {
      this.lessonStatus = status;
      this.changeLesson({ _id: this.lessonId, status });
    },
    handleDownloadClick() {
      this.downloadLessonArchive({ lessonId: this.lessonId });
    },
    getLessonStructure() {
      const { lessonId, getStructure } = this;
      const params = { lesson: lessonId };
      /**
       * Завернул в промис чтобы потом,
       * можно было по-красоте юзать then
       * для того чтобы подтянуть блоки
       * для структуры
       */
      return new Promise((resolve, reject) => {
        getStructure(params)
          .then((result) => {
            if (result.structure) {
              this.structure = result.structure;
            }
            resolve(result.structure);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async addBlock({ id, idx }) {
      this.pending = true;
      const payload = {
        lesson: this.lessonId,
        blockType: this.blockType,
        block: id,
        idx,
      };

      /**
       * Запрашиваем список по новой, после успешного
       * добавления, т.к. меняются индексы у структур
       */
      try {
        const added = await this.addStructureBlock(payload);
        const refreshStructure = await this.getLessonStructure();
        this.pending = false;
      } catch (err) {
        this.pending = false;
        console.log('err', err);
      }
    },
    async init() {
      try {
        const struct = await this.getLessonStructure();
        if (!struct || struct.length === 0) return;
        const blocks = await this.getBlocks({
          model: 'lessonblock',
          options: {
            params: {
              filter: {
                _id: {
                  $in: this.lessonBlocksList,
                },
              },
            },
          },
        });
        blocks.data && this.addToStore(blocks.data.models);

        const lesson = (await this.fetchLessons({ filter: { _id: this.lessonId } }))[0];
        if (lesson) this.lessonStatus = lesson.status;
      } catch (error) {
        console.log('error', error);
      }
    },
    optimisticUpdateList(val) {
      const newItems = val.map((n, idx) => {
        n.idx = idx;
        if (!n.block) {
          n.block = n._id;
          n._id = `${Date.now()}test`;
          this.addBlock({ id: n.block, idx });
        }
        return n;
      });
      this.structure = newItems;
    },
    updateStructureIndexes(newIndexes) {
      const idxMap =
        newIndexes &&
        newIndexes.reduce((acc, val) => {
          acc[val._id] = val.idx;
          return acc;
        }, {});

      this.structure = this.structure.map((n) => {
        n.idx = idxMap[n._id];
        return n;
      });
    },
    structureOrderHandler(newIndex) {
      const current = this.combinedStructure[newIndex];
      if (current) {
        this.pending = true;
        this.changeBlockOrder({
          structure: current._id,
          idx: newIndex,
        })
          .then((res) => {
            this.updateStructureIndexes(res.structure);
            this.$nextTick(() => {
              this.pending = false;
            });
          })
          .catch((err) => console.log(err));
      }
    },
  },
  created() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
@import '~less_vars';

.page-edite-lesson {
  height: 100%;
  padding-bottom: 30px;

  &__header {
    margin-top: -20px;
    padding: 15px 0;
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;

    &-pending {
      display: inline-block;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    margin-right: 20px;

    &-text {
      font-size: 18px;
      margin-right: 20px;
    }

    &-toggle {
      border-radius: 3px;
      overflow: hidden;
      display: inline-block;
    }
  }

  &__download-lesson {
    height: 36px !important;
  }

  &__structure-layout {
    height: calc(100% - 42px);
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 -20px;

    &__col {
      width: 50%;
      height: 100%;
      padding: 0 20px;
    }
    &__block {
      background-color: #f9fdff;
      scroll-behavior: smooth;
      height: 100%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      display: flex;
      flex-direction: column;
    }

    &__head {
      padding: 10px 15px;
      font-weight: bold;
      color: @base-color;
      font-size: 20px;
      border-bottom: 1px solid fade(#000, 10%);
      min-height: 53px;
      background-color: #f9fdff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__body {
      flex: 1;
      overflow-y: auto;
    }
  }
}
</style>
