<template>
  <div class="filter">
    <div class="filter__section">
      <div class="filter__label">Типы блоков</div>
      <div class="filter__check">
        <base-checkbox
          v-for="item in blocktypesFilter"
          v-model="typeList"
          :value="item.value"
          :key="`option-${item.value}`"
          :checked="selectedTypes.includes(item.value)"
          >{{ item.label }}</base-checkbox
        >
      </div>
    </div>

    <div class="filter__section">
      <div class="filter__label">Теги:</div>
      <tag-search @addTags="handleAddTags" :selectedTags="selectedTags" />
      <div v-if="tagsList && tagsList.length" class="tags-list">
        <div class="tags-list__label filter__label">Выбранные теги:</div>
        <div class="tags-list__content">
          <base-tag
            v-for="item in tagsList"
            :key="`tag-item-${item._id}`"
            :name="item.name"
            @onCloseClick="handleRemoveTag(item._id)"
            variant="with-icon"
          ></base-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import tagSearch from '@/components/TagSearch/TagSearch';

export default {
  name: 'SearchFilters',
  components: { tagSearch },
  props: {
    selectedTypes: {
      type: Array,
      default: [],
    },
    selectedTags: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      selected: [],
      tags: [],
    };
  },
  methods: {
    handleAddTags(tags) {
      this.tagsList = tags;
    },
    handleRemoveTag(tagId) {
      const idx = this.tagsList.findIndex((n) => n._id === tagId);
      this.$emit('removeTag', tagId);
    },
  },
  computed: {
    ...mapState({
      types: (state) => state.lessonBlocks.blockTypes,
    }),
    blocktypesFilter() {
      return Object.keys(this.types).map((n) => ({ label: this.types[n], value: n }));
    },
    typeList: {
      get() {
        return this.selectedTypes;
      },
      set(val) {
        this.$emit('typesInput', val);
      },
    },
    tagsList: {
      get() {
        return this.selectedTags;
      },
      set(val) {
        const items = [...new Set([...this.selectedTags, ...val])];
        this.$emit('tagsChange', items);
      },
    },
  },
};
</script>

<style lang="less" scoped>
.filter {
  margin: 20px -15px;
  padding: 20px 15px;
  background-color: #e8f7ff;

  &__section {
    margin-bottom: 20px;
  }

  &__label {
    margin-bottom: 8px;
    font-size: 14px;
  }

  &__check {
    display: flex;
    flex-direction: row;

    .base-checkbox {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.tags-list {
  .base-tag {
    margin-bottom: 5px;
  }
}
</style>
