import { Quill } from 'vue-quill-editor';

const BlockEmbed = Quill.import('blots/embed');
const Link = Quill.import('formats/link');

const ATTRIBUTES = ['height', 'width'];

class Audio extends BlockEmbed {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('controls', true);
    node.setAttribute('src', this.sanitize(value));
    return node;
  }

  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static sanitize(url) {
    return Link.sanitize(url);
  }

  static value(domNode) {
    return domNode.getAttribute('src');
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }

  html() {
    const { audio } = this.value();
    return `<a href="${audio}">${audio}</a>`;
  }
}
Audio.blotName = 'audio';
Audio.className = 'ql-audio';
Audio.tagName = 'audio';

export default Audio;
