import Vue from 'vue';
import Router from 'vue-router';

// views
import Page404 from '@/views/Page404';
import PageLessons from '@/views/PageLessons';
import PageLesson from '@/views/PageLesson';
import PageTags from '@/views/PageTags';
import PageNewLesson from '@/views/PageNewLesson';
import PageEditLesson from '@/views/PageEditLesson';
import PageSections from '@/views/PageSections';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: {
        path: '/lessons',
      },
    },
    {
      path: '/lessons',
      name: 'lessons',
      component: PageLessons,
      props: {
        title: 'Уроки',
        oldFormat: true,
      },
      meta: {
        layout: 'CardLayout',
      },
    },
    {
      path: '/lesson/:lessonId',
      name: 'lesson',
      component: PageLesson,
    },
    {
      path: '/tags',
      name: 'tags',
      component: PageTags,
      meta: {
        layout: 'CardLayout',
      },
    },
    {
      path: '/sections',
      name: 'sections',
      component: PageSections,
      meta: {
        layout: 'CardLayout',
      },
    },
    {
      path: '/newlessons',
      name: 'newlessons',
      component: PageLessons,
      props: {
        title: 'Новые уроки',
        oldFormat: false,
      },
      meta: {
        layout: 'CardLayout',
      },
    },
    {
      path: '/newlesson',
      name: 'newlesson',
      component: PageNewLesson,
      meta: {
        layout: 'CardLayout',
      },
    },
    {
      path: '/edit/:lessonId',
      name: 'edit',
      component: PageEditLesson,
      props: true,
    },
    // Всегда последний
    {
      path: '*',
      component: Page404,
      name: '404',
    },
  ],
});
