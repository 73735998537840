<template>
  <div class="tags-search">
    <div class="tags-search__row">
      <div class="tags-search__col">
        <multiselect
          class="multiselect--theme-combobox"
          placeholder="группа тегов"
          :options="gropsOptions"
          v-model="group"
          track-by="_id"
          label="name"
          :searchable="true"
          @input="handleGroups"
          :show-labels="false"
        />
      </div>
      <div class="tags-search__col">
        <multiselect
          class="multiselect--theme-combobox"
          placeholder="Тег"
          :options="tagsOptions"
          v-model="currentTag"
          track-by="_id"
          label="name"
          :close-on-select="false"
          :disabled="!group"
          :searchable="true"
          @input="handleTags"
          :show-labels="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TagSearch',
  components: { Multiselect },
  props: {
    selectedTags: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      results: null,
      group: null,
      tags: [],
      currentTag: null,
    };
  },
  computed: {
    ...mapGetters({
      groups: 'tags/groups',
    }),
    gropsOptions() {
      if (!this.groups) return [];
      return this.groups;
    },
    tagsOptions() {
      return this.tags.filter((n) => !this.selectedTags.includes(n));
    },
  },
  methods: {
    ...mapActions({
      getTags: 'tags/getTags',
      getGroups: 'tags/fetchGroups',
    }),
    async getTagsByGroupId(groupID) {
      try {
        const tags = await this.getTags({
          filter: {
            type: {
              $in: [groupID],
            },
          },
        });
        this.tags = tags;
      } catch (err) {
        console.log('err', err);
      }
    },
    handleGroups(value) {
      this.getTagsByGroupId(value._id);
    },
    handleTags(tag) {
      this.$emit('addTags', [tag]);
    },
    addTags() {
      this.$emit('addTags', this.currentTag);
      this.currentTag = null;
    },
  },
  mounted() {
    this.getGroups();
    this.getTags();
  },
};
</script>

<style lang="less" scoped>
.tags-search {
  &__row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 -10px;
  }

  &__col {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 15px;
    padding: 0 10px;
  }
}
</style>
