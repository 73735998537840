import LinkWithClass from '@/quill/linkWithClass';

class LinkButton extends LinkWithClass {
  static create(value) {
    let href = value;
    if (typeof value !== 'string') {
      // eslint-disable-next-line prefer-destructuring
      href = value.href;
    }
    const node = super.create(href);
    href = this.sanitize(href);
    node.setAttribute('href', href);
    node.setAttribute('rel', 'noopener noreferrer');
    node.setAttribute('target', '_blank');
    if (typeof value !== 'string' && value.class) {
      node.setAttribute('class', value.class);
    }
    return node;
  }

  static formats(domNode) {
    const formats = {};
    formats.href = domNode.getAttribute('href');
    formats.class = domNode.getAttribute('class');
    return formats;
  }
}

LinkButton.blotName = 'link_button';
LinkButton.className = 'style-link-button';
LinkButton.tagName = 'A';

export { LinkButton as default };
