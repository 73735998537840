<template>
  <div class="base-layout">
    <base-header></base-header>
    <div class="base-layout__content">
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseLayout',
};
</script>

<style lang="less">
@import '~less_vars';

.base-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__content {
    flex: 1;
    overflow: hidden;
    padding-top: 5px;

    .container {
      height: 100%;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 760px;
    height: 760px;
    background-color: @primary-color;
    position: fixed;
    border-radius: 50%;
    top: -470px;
    left: -190px;
    z-index: -1;
  }

  &::after {
    content: '';
    display: block;
    width: 350px;
    height: 350px;
    border: 40px solid @secondary-color;
    background-color: #fff;
    position: fixed;
    border-radius: 50%;
    bottom: -85px;
    right: -85px;
    z-index: -1;
  }
}
</style>
