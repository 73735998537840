<template>
  <div class="editable-block">
    <div class="editable-block__head">
      <div class="editable-block__head-title">
        <base-icon glyph="#icon-eye" variant="hover" width="24px" @click="$emit('scroll-blocks', `${colId}_${block._id}`)" />
        {{ getTitle }}
      </div>
      <div class="editable-block__head-icon">
        <base-icon
          glyph="#icon-copy"
          variant="hover"
          width="20px"
          height="20px"
          title="Скопировать блок"
          @click="$emit('copyBlock', block._id)"
        ></base-icon>
      </div>
      <div class="editable-block__head-move">
        <div class="editable-block__head-move--up" @click.stop="$emit('swap', block._id, 'up')">
          <base-icon glyph="#icon-arrow" width="13px" height="13px" title="Переместить вниз"></base-icon>
        </div>
        <div class="editable-block__head-move--down" @click.stop="$emit('swap', block._id, 'down')">
          <base-icon glyph="#icon-arrow" width="13px" height="13px" title="Переместить вверх"></base-icon>
        </div>
      </div>
      <div class="editable-block__head-icon" @click="$emit('removeBlock', block._id)">
        <base-icon glyph="#icon-close" variant="hover" width="20px" title="Удалить блок" />
      </div>
    </div>

    <div class="editable-block__component">
      <div class="editable-block__component-title">Название:</div>
      <div class="editable-block__component-body">
        <base-input placeholder="Заголовок" type="text" name="name" required :value="block.name" @blur="saveBlocksField" />
      </div>
    </div>

    <div class="editable-block__component">
      <div class="editable-block__component-title">Содержание:</div>
      <div class="editable-block__component-body">
        <component :is="currentContent" :block="block" @uploadFile:open="uploadProxy" @save="save" v-if="isVisible" />
      </div>
    </div>

    <div class="editable-block__component">
      <div class="editable-block__component-title">Настройки:</div>
      <div class="editable-block__component-body">
        <div class="editable-block__component-options">
          <div class="editable-block__component-option">
            <base-checkbox v-model="block.is_home_work">Домашнее задание</base-checkbox>
          </div>
          <div class="editable-block__component-option">
            <base-checkbox v-model="block.showByDefault">Показывать по умолчанию</base-checkbox>
          </div>
        </div>
        <div class="editable-block__component-options">
          <div class="editable-block__component-option">
            <base-checkbox v-model="block.showNarrowImage">Показывать узкую плашку</base-checkbox>
          </div>
        </div>
        <div class="editable-block__component-options">
          <div class="editable-block__component-option">
            <div class="editable-block__input-wrap">
              <span>Время:</span>
              <base-input placeholder="мин" type="number" name="min" :value="parsTime.min" @blur="saveTime" />
              <span>:</span>
              <base-input placeholder="сек" type="number" name="sec" :value="parsTime.sec" @blur="saveTime" />
            </div>
          </div>
          <div class="editable-block__component-option">
            <multiselect
              class="multiselect--theme-combobox"
              placeholder="Выбрать раздел"
              track-by="_id"
              label="name"
              :value="getSection"
              :options="sectionList"
              :searchable="false"
              :show-labels="false"
              @input="handleSection"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="editable-block__footer">
      <div class="editable-block__footer-title">Теги:</div>
      <div class="editable-block__group" v-for="group in groupsTags" :key="group._id">
        <div class="editable-block__group-title">{{ group.name }}</div>
        <div class="editable-block__group-body">
          <multiselect
            class="multiselect--theme-combobox multiselect--theme-add"
            placeholder="Добавить тег"
            :options="getTagsInGroup(group._id)"
            track-by="_id"
            label="name"
            :max-height="190"
            :searchable="true"
            :show-labels="false"
            :close-on-select="false"
            open-direction="bottom"
            :show-no-options="false"
            :show-no-results="false"
            @search-change="onSearchTag"
            @input="onInputSearchTag($event, group._id)"
          >
            <div slot="caret" class="multiselect__select" @click="createTag(group._id)" />
          </multiselect>
          <base-tag
            v-for="tag in group.tags"
            :key="tag._id"
            :name="tag.name"
            variant="with-icon"
            @onCloseClick="removeTag(tag._id, group._id)"
          />
        </div>
      </div>
      <base-button
        class="editable-block__group-add"
        theme="dashed"
        v-if="!isNewGroup && getGroupsList.length"
        @click="addNewGroup"
      >
        <base-icon glyph="#icon-plus" width="16px" />Добавить группу тегов
      </base-button>
      <div class="editable-block__group" v-if="isNewGroup">
        <div class="editable-block__group-title">Новая группа тегов</div>
        <div class="editable-block__group-body">
          <div class="editable-block__group-new">
            <multiselect
              class="multiselect--theme-combobox"
              placeholder="Добавить группу"
              :options="getGroupsList"
              track-by="_id"
              label="name"
              :searchable="false"
              :show-labels="false"
              open-direction="bottom"
              :show-no-results="false"
              :show-no-options="false"
              @input="selectNewGroup"
            />
            <div class="editable-block__group-new__remove" @click="removeNewGroup">
              <base-icon glyph="#icon-close" width="18px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import groupBy from 'lodash.groupby';
import Multiselect from 'vue-multiselect';

import EditableText from '@/components/BlocksContent/EditableText';
import EditableVideo from '@/components/BlocksContent/EditableVideo';
import EditableGallery from '@/components/BlocksContent/EditableGallery';
import EditableTest from '@/components/BlocksContent/EditableTest';

import ModalNewTag from '@/components/Modal/ModalNewTag';

export default {
  name: 'EditableLessonBlock',
  components: {
    EditableText,
    EditableVideo,
    EditableGallery,
    EditableTest,
    Multiselect,
    ModalNewTag,
  },
  props: {
    block: Object,
    colId: String,
  },
  data() {
    return {
      isNewGroup: false,
      groupsTags: [],
      tempTag: null,
      tempGroup: null,
      parsTime: {
        min: 0,
        sec: 0,
      }, // :value="block.approximateTime"
      isVisible: false,
    };
  },
  computed: {
    ...mapState('tags', { stateTag: (state) => state.tempTag }),
    ...mapState({
      types: (state) => state.lessonBlocks.blockTypes,
    }),
    ...mapGetters('tags', { tagsList: 'list', groupsList: 'groups' }),
    ...mapGetters('sections', { sectionList: 'list' }),
    currentContent() {
      return `editable-${this.block.type}`;
    },

    getTitle() {
      const { block, types } = this;
      if (block && block.type) {
        const blockType = types[block.type];
        return blockType || '';
      }
      return '';
    },

    getGroupsList() {
      return this.groupsList.filter(
        (n) => !this.groupsTags.map((g) => g._id).includes(n._id) && groupBy(this.tagsList, 'type')[n._id]
      );
    },

    getSection() {
      const id = this.block.section;
      const section = this.sectionList.find(({ _id }) => _id === id);
      return section || null;
    },
  },
  methods: {
    ...mapActions('lessonBlocks', ['changeBlock']),
    ...mapActions('tags', ['fetchTags']),

    uploadProxy(e) {
      this.$emit('uploadFile:open', ...arguments);
    },

    save(id, fieldName, value, cb) {
      const data = {};
      data._id = id;
      data[fieldName] = value;
      if (cb) cb(data);
    },

    saveBlocksField(e) {
      this.save(this.block._id, e.target.name, e.target.value, (data) => this.changeBlock(data));
    },

    saveTime(e) {
      const { parsTime } = this;
      parsTime[e.target.name] = e.target.value;
      const res = (parsTime.min || 0) * 60000 + (parsTime.sec || 0) * 1000;

      this.save(this.block._id, 'approximateTime', res, (data) => this.changeBlock(data));
    },

    toggleHomeWork(value) {
      this.save(this.block._id, 'is_home_work', value, (data) => this.changeBlock(data));
    },

    toggleIsShow(value) {
      this.save(this.block._id, 'showByDefault', value, (data) => this.changeBlock(data));
    },

    toggleShowNarrowImage(value) {
      this.save(this.block._id, 'showNarrowImage', value, (data) => this.changeBlock(data));
    },

    handleSection(value) {
      this.save(this.block._id, 'section', value._id, (data) => this.changeBlock(data));
    },

    addNewGroup() {
      this.isNewGroup = true;
    },

    selectNewGroup(value) {
      this.groupsTags.push({
        _id: value._id,
        name: value.name,
        tags: [],
      });
      this.isNewGroup = false;
    },

    removeNewGroup() {
      this.isNewGroup = false;
    },

    getTagsInGroup(id) {
      const selectedTags = this.groupsTags.find((group) => group._id === id).tags;
      const allTagsInGroup = groupBy(this.tagsList, 'type')[id];
      return allTagsInGroup.filter((tag) => !selectedTags.map((t) => t._id).includes(tag._id));
    },

    onSearchTag(newTag) {
      if (newTag) this.tempTag = newTag;
    },

    onInputSearchTag(value, id) {
      this.tempTag = null;
      if (value) {
        this.groupsTags.find((group) => group._id === id).tags.push(value);
        this.save(this.block._id, 'tags', [...this.block.tags, value._id], (data) => this.changeBlock(data));
      }
    },

    removeTag(tagId, groupId) {
      const currentGroup = this.groupsTags.find((group) => group._id === groupId);
      currentGroup.tags = currentGroup.tags.filter((tag) => tag._id !== tagId);
      const newTags = this.block.tags.filter((tag) => tag !== tagId);
      this.save(this.block._id, 'tags', newTags, (data) => this.changeBlock(data));
    },

    createTag(id) {
      this.tempGroup = id;

      this.$emit('vuedals:new', {
        name: 'new-tag',
        component: ModalNewTag,
        dismissable: false,
        size: 'sm',
        props: { name: this.tempTag, group: id },
        onClose(e) {},
      });
      this.tempTag = null;
    },
  },

  watch: {
    stateTag(newValue) {
      if (newValue) {
        this.groupsTags.find((group) => group._id === this.tempGroup).tags.push(newValue);
        this.save(this.block._id, 'tags', [...this.block.tags, newValue._id], (data) => this.changeBlock(data));
        this.tempGroup = null;
      }
    },
    'block.is_home_work': function (value) {
      this.toggleHomeWork(value);
    },
    'block.showByDefault': function (value) {
      this.toggleIsShow(value);
    },
    'block.showNarrowImage': function (value) {
      this.toggleShowNarrowImage(value);
    },
    'block.approximateTime': {
      immediate: true,
      handler(value) {
        this.parsTime.min = Math.floor(value / 60000);
        this.parsTime.sec = (value % 60000) / 1000;
      },
    },
  },

  async mounted() {
    if (this.block.tags.length) {
      const currentTags = this.block.tags.map((id) => this.$store.state.DB.tags[id]);
      const currentTagsGroups = groupBy(currentTags, 'type');

      this.groupsTags = Object.keys(currentTagsGroups).map((id) => ({
        _id: id,
        name: this.$store.state.DB.tagGroups[id].name,
        tags: currentTagsGroups[id],
      }));
    }

    // moved to PageLessson out of loop
    // this.fetchSections();

    const rootMargin = '10px 0px';
    this.io = new window.IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting || entries[0].intersectionRatio) {
          this.io.unobserve(this.$el);
          this.io = null;
          this.isVisible = true;
        }
      },
      {
        rootMargin,
        root: this.$parent.$el,
        threshold: 0.1, // [0, Number.MIN_VALUE, 0.01]
      }
    );

    this.io.observe(this.$el);
  },
};
</script>

<style lang="less">
@import '~less_vars';

.editable-block {
  border: 1px solid #eaeaea;
  background: #fff;

  //&:hover {
  //  box-shadow: 2px 3px 7px 3px rgba(0, 0, 0, 0.05);
  //}

  &__head {
    padding: 20px 20px 10px;
    display: flex;
    align-items: center;

    &-title {
      margin-right: auto;
      font-weight: 600;
      text-transform: uppercase;
      font-size: @base-fz;
      color: @primary-color;
      line-height: 24px;

      & > .icon-eye {
        cursor: pointer;
        margin-right: 10px;
      }
    }

    &-icon {
      cursor: pointer;
    }

    &-move {
      margin: -5px 10px;

      &--up,
      &--down {
        cursor: pointer;
        border: 1px solid #d8e1e7;
        margin: 5px;
        padding: 3px;
        border-radius: 2px;
        width: 21px;
        height: 21px;
        display: inline-block;
        transition: 0.1s;

        &:hover {
          border-color: @primary-color;

          .base-icon {
            --primaryIconColor: @primary-color;
          }
        }

        .base-icon {
          --primaryIconColor: #d8e1e7;
        }
      }

      &--up .base-icon {
        transform: rotate(90deg);
      }
      &--down .base-icon {
        transform: rotate(-90deg);
      }
    }
  }

  &__component {
    padding: 10px 20px;

    &-title {
      color: @gray-dark;
      font-weight: 600;
      margin-bottom: 15px;
    }

    &-body {
      margin-bottom: 10px;
    }

    &-options {
      display: flex;
      align-items: center;
      margin: 10px -10px;
    }

    &-option {
      padding: 0 10px;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &__footer {
    padding: 20px 20px 25px;
    background: #f1f5f8;

    &-title {
      font-weight: 600;
      margin-top: 5px;
      font-size: @bigger-fz;
    }
  }

  &__group {
    margin: 20px 0 25px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      color: @gray-dark;
      font-weight: 600;
      margin-bottom: 15px;
    }

    &-body {
      margin: -10px -5px;

      .multiselect {
        width: 200px;
        display: inline-block;
        margin: 10px 5px;
        vertical-align: middle;
      }

      .base-tag {
        margin: 10px 5px;
        vertical-align: middle;
      }
    }

    &-add {
      background: #f5fafc !important;
      margin-top: 25px;
    }

    &-new {
      position: relative;
      display: inline-block;

      &__remove {
        position: absolute;
        width: 28px;
        height: 28px;
        padding: 5px;
        right: -30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        --primaryIconColor: @gray-light;
        --secondaryIconColor: @gray-light;

        &:hover {
          --primaryIconColor: @secondary-color;
          --secondaryIconColor: @primary-color;
        }
      }
    }
  }

  &__input-wrap {
    display: flex;
    align-items: baseline;

    .base-input {
      margin: 0 5px;
    }
  }
}
</style>
