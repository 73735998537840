<template>
  <div class="page-tags">
    <div class="page-tags__header">
      <h2>Теги</h2>
      <base-button variant="with-icon" @click="openModal('new-tags-group', 'ModalNewTagsGroup')">
        <base-icon glyph="#icon-plus" width="20px" />Добавить группу
      </base-button>
    </div>
    <div class="page-tags__body">
      <div class="page-tags__group" v-for="group in groups" :key="group._id">
        <div class="page-tags__group-name">
          {{ group.name }}
          <base-icon
            glyph="#icon-pencil"
            width="19px"
            height="21px"
            @click="openModal('edite-tags-group', 'ModalNewTagsGroup', 'sm', { mod: 'edite', group: group })"
          />
        </div>
        <div class="page-tags__group-list">
          <base-button
            class="page-tags__btn-add-tag"
            theme="secondary"
            variant="with-icon"
            size="large"
            fontSize="base"
            @click="openModal('new-tag', 'ModalNewTag', 'sm', { group: group._id })"
          >
            <base-icon glyph="#icon-plus" width="21px" height="21px" />Тег
          </base-button>

          <base-tag
            class="page-tags__tag"
            variant="with-icon"
            v-for="tag in tagsGroups[group._id]"
            :key="tag._id"
            :name="tag.name"
            @onTagClick="openModal('new-tag', 'ModalNewTag', 'sm', { tag: tag, mod: 'edite' })"
            @onCloseClick="removeTag({ _id: tag._id })"
          />
        </div>
      </div>
    </div>
    <vuedal></vuedal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Component as Vuedal } from 'vuedals';
import groupBy from 'lodash.groupby';

import ModalNewTagsGroup from '@/components/Modal/ModalNewTagsGroup';
import ModalNewTag from '@/components/Modal/ModalNewTag';

export default {
  name: 'PageTags',
  components: {
    ModalNewTagsGroup,
    ModalNewTag,
    Vuedal,
  },
  computed: {
    ...mapGetters('tags', ['list', 'groups']),

    tagsGroups() {
      return groupBy(this.list, 'type');
    },
  },
  methods: {
    ...mapActions('tags', ['fetchTags', 'removeTag', 'fetchGroups', 'reorderTags']),

    openModal(name, componentName, size = 'sm', props = {}) {
      this.$emit('vuedals:new', {
        name,
        component: this.$options.components[componentName],
        props: { ...props },
        dismissable: false,
        size,
      });
    },
  },
  mounted() {
    this.fetchGroups();
    this.fetchTags();
  },
};
</script>

<style lang="less">
@import '~less_vars';
.page-tags {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    padding: 20px 15px;
    display: flex;
    border-bottom: 1px solid #eaeaea;

    h2 {
      margin: 0;
      margin-right: auto;
      font-size: @h2;
    }

    .base-btn {
      margin-left: 10px;
    }
  }

  &__body {
    flex: 1;
    padding: 15px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__group {
    margin: 15px 0 25px;

    &-name {
      font-weight: 600;
      line-height: 20px;
      font-size: 15px;
      color: @gray-dark;
      margin-bottom: 5px;

      .base-icon {
        margin-left: 10px;
        cursor: pointer;
        --primaryIconColor: #c8c1d9;
        --secondaryIconColor: #c8c1d9;

        &:hover {
          --primaryIconColor: @secondary-color;
          --secondaryIconColor: @primary-color;
        }
      }
    }

    &-list {
      margin: 0 -7px;
    }
  }

  &__btn-add-tag {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    margin: 7px;
    font-size: 16px !important;
    font-weight: 600;
  }

  &__tag {
    margin: 7px;
    vertical-align: middle;
    cursor: pointer;
  }
}
</style>
