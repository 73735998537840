<template>
  <base-modal class="modal-new-variant" title="Новый вариант">
    <div slot="modal-body">
      <div class="modal-new-variant__row">
        <multiselect
          class="multiselect--theme-combobox"
          placeholder="Выбрать тип"
          v-model="selectedType"
          :options="typeList"
          track-by="type"
          label="label"
          :searchable="false"
          :show-labels="false"
          @input="setType"
        />
      </div>
      <div class="modal-new-variant__row">
        <multiselect
          class="multiselect--theme-combobox"
          placeholder="Выбрать сложность"
          v-model="selectedDifficulty"
          :options="difficultList"
          track-by="type"
          label="label"
          :searchable="false"
          :show-labels="false"
          @input="setDifficulty"
        />
      </div>
      <base-button class="modal-new-variant__save-btn" @click="createVariant" size="large" :disabled="!variant.practiceType"
        >Создать</base-button
      >
    </div>
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ModalNewVariant',
  components: {
    Multiselect,
  },
  props: {
    handleConfirm: Function,
  },
  data() {
    return {
      variant: {
        practiceType: null,
        difficulty: 'normal',
      },
      selectedType: null,
      selectedDifficulty: null,
    };
  },
  computed: {
    ...mapGetters('variants', ['typeList', 'difficultList']),
  },
  methods: {
    setType(newType) {
      this.variant.practiceType = newType ? newType.type : '';
    },
    setDifficulty(newDifficulty) {
      this.variant.difficulty = newDifficulty ? newDifficulty.type : '';
    },

    createVariant() {
      const { variant, handleConfirm } = this;
      this.$emit('vuedals:close');
      if (handleConfirm) handleConfirm(variant);
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.modal-new-variant {
  &__row {
    margin: 30px 0;
  }
}
</style>
