<template>
  <div id="app">
    <component :is="layout"></component>
  </div>
</template>

<style lang="less">
@import '~less_vars';
@import '~./less/common.less';
</style>

<script>
import BaseLayout from '@/layouts/BaseLayout';
import CardLayout from '@/layouts/CardLayout';

export default {
  name: 'App',
  components: {
    BaseLayout,
    CardLayout,
  },
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
  },
};
</script>

<style lang="less">
:root {
  --primaryIconColor: #4507ca;
  --secondaryIconColor: #fc005c;
  --thirdIconColor: #18af58;
  --greyIconColor: rgba(160, 169, 188, 0.6);
}
</style>
