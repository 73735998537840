<template>
  <div class="lesson" @click="lessonParse">
    <div
      class="lesson__state"
      v-bind:class="{ parse: lesson.status === '2', work: lesson.status === '1', new: lesson.status === '3' }"
    ></div>
    <div class="lesson__row">
      <div class="lesson__title">
        <span class="lesson__id" v-if="lesson.iframeSrc">{{ lesson.iframeSrc }}.</span>
        <span class="lesson__name">{{ lesson.name }}</span>
      </div>
      <div class="lesson__status">
        {{ statusList[lesson.status] }}
      </div>
    </div>
    <div class="lesson__row">
      <span class="lesson__subject" v-if="lesson.subject">{{ lesson.subject }}</span>
      <span class="lesson__subject-area" v-if="lesson.subjectArea">{{ lesson.subjectArea }}</span>
      <span class="lesson__subject-theme" v-if="lesson.subjectTheme">{{ lesson.subjectTheme }}</span>
      <div class="lesson__buttons">
        <base-button theme="default" size="small" v-if="!lesson.oldFormat" @click.stop="lessonDelete"> Удалить </base-button>
        <base-button theme="default" size="small" @click.stop="openModalCreateDubleLesson">Дублировать </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { HTTP } from '@/http';
import slugify from '@/helpers/slugify';

export default {
  name: 'Lesson',
  data() {
    return {
      statusList: {
        0: 'Не обработан',
        1: 'В работе',
        2: 'Обработан',
        3: 'Новый',
      },
    };
  },
  props: {
    lesson: Object,
    isOld: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...mapActions('lessons', ['parseLesson', 'dublicateLesson']),
    async openModalCreateDubleLesson() {
      this.$store.commit('lessons/SET_NEW_LESSON_DATA', {
        name: this.lesson.name,
        path: slugify(this.lesson.name),
        isOpenModal: true,
        _id: this.lesson._id,
      });
    },
    async lessonParse() {
      const { parseLesson, lesson } = this;

      if (!lesson.blocks && this.isOld) {
        const ids = await parseLesson({ lesson: lesson._id });
      }
      this.$router.push({
        name: this.isOld ? 'lesson' : 'edit',
        params: { lessonId: lesson._id },
      });
    },

    async lessonDelete() {
      const { lesson } = this;
      try {
        const params = {
          lessonId: lesson._id,
        };
        const lessonDelete = await HTTP.delete('/api/lessonDelete', { params });
        if (lessonDelete) {
          this.$parent.$emit('getFilteredLessons');
        }
      } catch (error) {
        console.log('error', error);
      }
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';
.modal-copy-lesson {
  position: fixed;
}
.lesson {
  padding: 10px 15px;
  border: 1px solid transparent;
  cursor: pointer;
  text-decoration: none;
  color: @base-color;
  position: relative;
  transition: all 0.2s;

  &:hover {
    background: #f9fdff;
    border: 1px solid rgba(216, 225, 231, 0.72);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    color: @primary-color;
  }

  &__state {
    width: 10px;
    height: 10px;
    border: 2px solid @primary-color;
    border-radius: 50%;
    transition: inherit;
    position: absolute;
    top: 18px;

    &.work {
      background: @primary-color;
    }
    &.parse {
      background: @secondary-color;
      border-color: @secondary-color;
    }
  }

  &:hover &__state:not(.parse) {
    background: @primary-color;
    border-color: @primary-color;
  }

  &__row {
    padding-left: 25px;
    line-height: 1.6;
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    margin-right: auto;
  }

  &__status {
    font-size: @medium-fz;
    color: @gray-disabled;
  }

  &__id {
    font-size: @base-fz;
    margin-right: 5px;
  }

  &__subject,
  &__subject-area,
  &__subject-theme {
    color: @gray-disabled;
    font-size: 10px;
    text-transform: uppercase;
    margin: 5px 10px 5px 0;
    font-weight: 600;
    border: 1px solid rgba(216, 225, 231, 0.72);
    padding: 5px 10px;
    border-radius: 3px;
  }

  &__buttons {
    margin-left: auto;
  }

  // &:nth-child(2n) {
  //   background: #f1f5f8;
  // }
}
</style>
