<template>
  <component :is="parseForSyntactic" />
</template>

<script>
import Vue from 'vue';

export default {
  name: 'PreviewSyntactic',
  props: {
    question: String,
  },
  computed: {
    parseForSyntactic() {
      // const question = `#[#fact{В небе} #pred{плывут} #subj{облака}],
      //   #[#subj{Дети} #pred{смотрят} #def{любимый} #add{мультфильм}],
      //   #[#add{У девочки} #fact{от усталости} #pred{слипаются} #subj{глаза}]`;

      const re = /\#\[([^\]]*)\]/gi;

      return Vue.component('sentence', {
        template: `<div class='preview-syntactic'>${this.question.replace(re, this.sentenceParse(), 'gm')}</div>`,
      });
    },
  },
  methods: {
    sentenceParse() {
      return (match, sentence) => {
        const subj = /\#subj\{([^}]*)\}/gi;
        const pred = /\#pred\{([^}]*)\}/gi;
        const add = /\#add\{([^}]*)\}/gi;
        const def = /\#def\{([^}]*)\}/gi;
        const fact = /\#fact\{([^}]*)\}/gi;
        function replaceMember(name) {
          return (match, item) => {
            return `<span class="sentence-member ${name}">${item}</span>`;
          };
        }
        return `<span class="sentence">${sentence
          .replace(subj, replaceMember('subj'), 'gm')
          .replace(pred, replaceMember('pred'), 'gm')
          .replace(add, replaceMember('add'), 'gm')
          .replace(def, replaceMember('def'), 'gm')
          .replace(fact, replaceMember('fact'), 'gm')}</span>`;
      };
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';
.preview-syntactic {
  font-size: 22px;
}
.sentence {
  line-height: 2;

  &-member {
    display: inline-block;
    background-repeat: repeat-x;
    background-position: 0 1.5em;

    &.subj {
      background-image: url('../../assets/line.svg');
      background-size: 1em 0.5em;
    }
    &.pred {
      background-image: url('../../assets/line-double.svg');
      background-size: 1em 0.5em;
    }
    &.add {
      background-image: url('../../assets/line-dashed.svg');
      background-size: 1em 0.5em;
    }
    &.def {
      background-image: url('../../assets/line-wavy.svg');
      background-size: 1em 0.5em;
    }
    &.fact {
      background-image: url('../../assets/line-dotted.svg');
      background-size: 1.2em 0.5em;
    }
  }
}
</style>
