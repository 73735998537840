<template>
  <div class="lesson-block" v-if="block" @click="$emit('scroll-blocks', `${colId}_${block._id}`)">
    <div v-if="block.showNarrowImage" class="lesson-block__narrow-img">
      <img src="../assets/bg-narrow.svg" alt="" />
    </div>
    <h3 class="lesson-block__name">{{ nameByVariant }}</h3>
    <div class="lesson-block__text" v-bind:style="{ display: block.text ? 'block' : 'none' }" ref="mathJaxText">
      <component :is="parseText" />
    </div>

    <div v-if="block.links && block.links.length" class="lesson-block__video">
      <vue-plyr>
        <video :poster="block.videoPoster">
          <source v-for="(link, index) in block.links" :key="link + index" :src="link" :type="getTypeLink(link)" />
        </video>
      </vue-plyr>
    </div>
    <div v-if="block.title" class="lesson-block__title" v-html="block.title" />
    <div v-if="block.description" class="lesson-block__text" v-html="block.description" />

    <div v-if="block.gallery.length" class="lesson-block__gallery">
      <div v-if="block.gallery.length > 1 && sortedGallery.length > 1">
        <swiper :options="swiperOption" ref="gallery" @slideChange="onSlideChange">
          <swiper-slide
            v-for="slide in sortedGallery"
            v-if="slide"
            :key="slide._id"
            v-bind:style="{ backgroundImage: 'url(' + slide.link + ')' }"
          />

          <div class="swiper-button-prev" slot="button-prev">
            <base-icon glyph="#icon-arrow" width="20px" height="40px"></base-icon>
          </div>

          <div class="swiper-button-next" slot="button-next">
            <base-icon glyph="#icon-arrow" width="20px" height="40px"></base-icon>
          </div>
        </swiper>

        <div class="lesson-block__gallery-pagination">{{ activeSlide + 1 }}/{{ block.gallery.length }}</div>
      </div>
      <div v-else>
        <div
          class="lesson-block__gallery-image"
          v-if="getSlideImage(block.gallery[0])"
          v-bind:style="{
            backgroundImage: 'url(' + getSlideImage(block.gallery[0]).link + ')',
          }"
        ></div>
      </div>

      <div v-if="sortedGallery[activeSlide] && sortedGallery[activeSlide].title" class="lesson-block__title">
        {{ sortedGallery[activeSlide].title }}
      </div>
    </div>

    <div v-if="block.variants && block.variants.length" class="lesson-block__variants">
      <preview-variant
        class="lesson-block__variant"
        v-for="(variant, index) in blockVariants"
        :key="variant._id"
        :index="index"
        :variant="variant"
      >
      </preview-variant>
    </div>
  </div>
</template>

<script>
import 'vue-plyr/dist/vue-plyr.css';
import 'swiper/css/swiper.css';
import sortBy from 'lodash.sortby';

import { mapGetters, mapActions } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

import PreviewVariant from '@/components/Variant/PreviewVariant';
import AudioBtn from '@/components/Common/AudioBtn';

export default {
  name: 'LessonBlock',
  components: {
    Swiper,
    SwiperSlide,
    PreviewVariant,
    AudioBtn,
  },
  props: {
    block: Object,
    colId: String,
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      activeSlide: 0,
      isVisible: false,
    };
  },
  watch: {
    'block.text': {
      immediate: true,
      handler(val) {
        this.renderMathJax();
      },
    },
  },
  computed: {
    ...mapGetters('images', { listImg: 'list' }),
    ...mapGetters('variants', { variants: 'list', bVariants: 'listBlockVariants' }),

    blockVariants() {
      return this.variants.filter((variant) => this.block.variants.includes(variant._id));
    },

    blockStoreVariants() {
      return this.bVariants(this.block.variants);
    },

    sortedGallery() {
      const gallery = this.block.gallery.map((id) => this.getSlideImage(id));
      return sortBy(gallery, 'idx');
    },

    parseText() {
      return {
        components: { AudioBtn },
        template: `<div>${this.block.text
          .replace(/<audio class="ql-audio" controls="true"/g, '<AudioBtn')
          .replace(/<\/audio>/g, '</AudioBtn>')}</div>`,
      };
    },
    nameByVariant() {
      if (this.block.name) return this.block.name;
      if (this.block.variants.length === 0) return '';
      const intersectionVariants = this.variants.filter((x) => this.block.variants.includes(x._id));
      const arrayOfNames = intersectionVariants.map((x) => x.name).filter((x) => x);
      if (arrayOfNames.length === 0) return '';
      const name = arrayOfNames[Math.floor(Math.random() * arrayOfNames.length)];
      return name;
    },
  },
  methods: {
    ...mapActions('images', ['fetchImages']),

    renderMathJax() {
      if (!window.MathJax) return;
      const text = this.$refs.mathJaxText;
      if (text) {
        window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, text]);
      }
    },

    getSlideImage(id) {
      return this.listImg.find((img) => img._id === id);
    },

    getTypeLink(link) {
      return `video/${link.match(/\.[0-9a-z]+$/i) && link.match(/\.[0-9a-z]+$/i)[0].replace('.', '')}`;
    },

    onSlideChange() {
      this.activeSlide = this.$refs.gallery.swiper.activeIndex;
    },
  },
  mounted() {
    const rootMargin = '10px 0px';
    this.io = new window.IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting || entries[0].intersectionRatio) {
          this.renderMathJax();

          this.isVisible = true;
          if (this.block.gallery.length) {
            this.fetchImages({
              filter: { _id: { $in: [...this.block.gallery] } },
            });
          }
          this.io.unobserve(this.$el);
          this.io = null;
        }
      },
      {
        rootMargin,
        root: this.$parent.$el,
        threshold: 0.1, // [0, Number.MIN_VALUE, 0.01]
      }
    );
    this.io.observe(this.$el);
  },
};
</script>

<style lang="less">
@import '~less_vars';

.lesson-block {
  background: @gray-hover;
  padding: 20px 20px 40px;

  &__name {
    font-size: @h2;
    margin-top: 0;
    margin-bottom: 5px;
    line-height: @large-lh;
  }

  &__text {
    font-size: @bigger-fz;
    line-height: @large-lh;

    p {
      margin: 0.5em auto;
    }

    ol,
    ul {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }

    li {
      margin-top: 0.25em;
      margin-bottom: 0.25em;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      max-width: 100%;
      border-radius: 8px;
    }

    .ql-align-center {
      text-align: center;
    }
    .ql-align-right {
      text-align: right;
    }
    .ql-align-justify {
      text-align: justify;
    }

    .style-link-button {
      display: inline-block;
      border-radius: 10px;
      background: linear-gradient(0deg, rgba(193, 35, 229, 1) 0%, rgba(211, 138, 241, 1) 100%);
      padding: 2px 15px 4px;
      text-decoration: none;
      color: #fff;
      &:hover {
        opacity: 0.9;
      }
      &:active {
        background: linear-gradient(180deg, rgba(193, 35, 229, 1) 0%, rgba(211, 138, 241, 1) 100%);
      }
    }

    .style-text-border {
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
      border-radius: 2px;
      padding: 20px;
    }

    .style-text-question {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      border: 2px solid @primary-color;
      border-radius: 15px;
      color: @primary-color;
      margin: 10px 0;

      &__mark {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-right: 20px;
        width: 50px;
        height: 50px;
        font-size: 42px;
        font-weight: 700;
        color: white;
        background-color: @primary-color;
        border-radius: 50%;
      }
    }

    .style-text-dashed-border {
      text-align: center;
      padding: 20px;
      background-image: url('../assets/border-dashed.svg');
      border-radius: 30px;
      margin: 10px 0;
      &.is-yellow {
        background-image: url('../assets/border-dashed-yellow.svg');
      }
      &.is-primary {
        background-image: url('../assets/border-dashed-primary.svg');
      }
      &__title {
        margin-bottom: 15px;
        color: #1848ac;
        font-weight: 700;
      }
      &__text {
        color: black;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;

      td {
        border: 1px solid #ccc;
        padding: 5px 10px;
      }
    }

    table.style-table-color-primary {
      td {
        border: 1px solid #4507ca;
      }
    }

    table.style-table-color-secondary {
      td {
        border: 1px solid #fc005c;
      }
    }

    table.style-table-color-none {
      td {
        border: none;
      }
    }

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    counter-reset: content-ol-counter;

    .basic-template__col {
      &:first-child {
        counter-reset: content-ol-counter;
      }

      > ol {
        counter-reset: none;
      }
    }

    & .basic-template__col:first-child {
      counter-reset: content-ol-counter;
    }

    ol {
      list-style: none;
      padding-left: 2em;
      counter-reset: content-ol-counter;

      > li {
        counter-increment: content-ol-counter;
        margin-bottom: 0.3em;

        &:before {
          content: counter(content-ol-counter) '. ';
          color: @primary-color;
          font-weight: bold;
          margin-left: -2em;
          margin-right: 0.5em;
          min-width: 1.5em;
          display: inline-block;
          text-align: right;
        }
      }
    }

    .basic-template {
      display: flex;
      justify-content: space-between;
      padding: 25px 0;

      &.is-vertical {
        flex-direction: column;
      }

      &.is-center {
        align-items: center;
      }

      &__col {
        & > p {
          margin: 0;
          margin-bottom: 20px;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  &__title {
    color: @gray-dark;
    margin: 10px 0;
    font-size: @medium-fz;
    line-height: @large-lh;
  }

  &:nth-child(2n) {
    background: #f1f5f8;
  }

  &__video {
    margin-top: 10px;

    .plyr {
      border-radius: 8px;
    }

    video {
      max-height: 400px;
    }
  }

  &__gallery {
    margin-top: 10px;

    &-pagination {
      font-size: @base-fz;
      font-weight: 600;
      margin: 10px 0;
    }

    &-image {
      width: 100%;
      height: 300px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .swiper-wrapper {
      height: 300px;
    }

    .swiper-slide {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 8px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 20px;
      height: 40px;
      margin-top: -20px;
      background-image: none;
    }
  }

  &__narrow-img {
    margin-bottom: 20px;
    img {
      max-width: 100%;
    }
  }
}

.richblock-container {
  height: 100vh;
}

@media (max-width: 668px) {
  .richblock-container {
    height: 100%;
  }
}

.richblock-content {
  width: 100%;
  height: 85%;

  &.with-bg {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__name {
    font-size: 48px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
  }

  @media (max-width: 668px) {
    &__name {
      font-size: 26px;
    }
  }

  &__title,
  &__text,
  &__list {
    padding-left: 55px;
    padding-right: 30px;
  }

  @media (max-width: 668px) {
    &__title,
    &__text,
    &__list {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    padding-top: 60px;
    padding-bottom: 10px;
  }

  @media (max-width: 668px) {
    &__title,
    &__list {
      font-size: 18px;
      padding-top: 30px;
    }
  }

  &__text {
    font-size: 14px;
    color: #666666;
  }

  &__list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    font-size: 24px;

    img {
      margin-right: 10px;
    }
  }

  &__longlist {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 70%;

    &-i {
      margin-left: 20px;
      margin-bottom: 10px;
      font-size: 12px;
    }
  }
}

@media (max-width: 668px) {
  .richblock-content {
    height: auto;
  }
}

.richblock-footer {
  width: 90%;
  margin-left: 5%;
  position: relative;
  height: 15%;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-size: 14px;
  align-items: center;

  &__left {
    display: flex;

    &-subject {
      background: #5870e9;
      border-radius: 20px;
      padding: 10px 15px;
      margin-right: 10px;
    }

    &-grade {
      background: #f2c94c;
      border-radius: 20px;
      padding: 10px 15px;
    }
  }
}
</style>
