const navs = {
  namespaced: true,
  state: {
    nav: [
      {
        path: '/lessons',
        label: 'Уроки',
      },
      {
        path: '/tags',
        label: 'Теги',
      },
      {
        path: '/sections',
        label: 'Разделы',
      },
    ],
  },
};

export default navs;
