<template>
  <base-modal class="modal-new-block" title="Новый блок">
    <div slot="modal-body">
      <div class="modal-new-block__row">
        <base-input placeholder="Имя блока" type="text" name="name" :value="blockName" @input="updateBlock" />
      </div>

      <div class="modal-new-block__row modal-new-block__btns-wrap">
        <base-button class="modal-new-block__save-btn" size="large" font-size="big" @click="createBlock('text')">
          <base-icon glyph="#icon-text" width="25px" />
          Текст
        </base-button>
        <base-button class="modal-new-block__save-btn" size="large" font-size="big" @click="createBlock('gallery')">
          <base-icon glyph="#icon-img" width="25px" />
          Галерея
        </base-button>
        <base-button class="modal-new-block__save-btn" size="large" font-size="big" @click="createBlock('video')">
          <base-icon glyph="#icon-video" width="25px" />
          Видео
        </base-button>
        <base-button class="modal-new-block__save-btn" size="large" font-size="big" @click="createBlock('test')">
          <base-icon glyph="#icon-star" width="25px" />
          Задание
        </base-button>
      </div>

      <!-- <base-button class="modal-new-block__save-btn" @click="createBlock" size="large" :disabled="!this.block.type">Создать</base-button> -->
    </div>
  </base-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  name: 'MadalNewBlock',
  components: {
    Multiselect,
  },
  props: {
    idx: Number,
    lessonId: String,
    handleConfirm: Function,
  },
  data() {
    return {
      blockName: '',
    };
  },
  methods: {
    ...mapActions('lessonBlocks', ['addBlock']),

    updateBlock(e) {
      const { name, value } = e.target;
      this.blockName = value;
    },

    setType(newType) {},

    async createBlock(typeBlock) {
      const params = {
        name: this.blockName,
        type: typeBlock,
        lesson: this.lessonId,
      };
      if (this.idx) params.idx = this.idx + 1;

      const newBlock = await this.addBlock(params);
      this.$emit('vuedals:close');
      if (this.handleConfirm) this.handleConfirm(newBlock);
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.modal-new-block {
  &__row {
    margin: 30px 0;
  }

  &__btns-wrap {
    margin: 20px -10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__save-btn {
    margin: 10px;
    width: calc(50% - 20px);
    display: flex !important;
    align-items: center;

    .base-icon {
      margin-right: 30px !important;
    }
  }
}
</style>
