<template>
  <div class="editable-video">
    <div class="editable-video__row">
      <base-icon glyph="#icon-video" height="42px" width="42px" />
      <div class="editable-video__col">
        <base-file
          label="Постер"
          :name="block.videoPoster"
          :removable="false"
          @basefile:edit="$emit('uploadFile:open', block.videoPoster, setPoster)"
        ></base-file>
      </div>
    </div>
    <div class="editable-video__row" v-for="(link, index) in block.links" :key="index + block._id">
      <div class="editable-video__col">
        <base-file
          label="Видеофайл"
          :name="link"
          @basefile:remove="removeLink(index)"
          @basefile:edit="handleLinkEdit(index, link)"
        ></base-file>
      </div>
    </div>
    <div class="editable-video__row">
      <base-button theme="dashed" @click="handleLinkEdit(null, link)">
        <base-icon glyph="#icon-plus" width="16px" />
        Добавить ссылку на видео
      </base-button>
    </div>
    <div class="editable-video__row">
      <base-editor :value="block.title" name="title" placeholder="Заголовок" @save-text="saveBlocksTitle" />
    </div>
    <div class="editable-video__row">
      <base-editor
        :value="block.description"
        name="description"
        placeholder="Содержание блока"
        @save-text="saveBlocksDescription"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'EditableVideo',
  props: {
    block: Object,
  },
  data() {
    return {
      currentLinkIdx: null,
    };
  },
  methods: {
    ...mapActions('lessonBlocks', ['changeBlock']),

    handleLinkEdit(idx, link) {
      this.currentLinkIdx = idx;
      if (this.currentLinkIdx && !this.block.links[idx]) {
        alert('link with index ${idx} not found.');
        window.location.reload();
      }
      this.$emit('uploadFile:open', link, this.setLink);
    },

    setLink(url) {
      const links = this.block.links ? [...this.block.links] : [];
      if (typeof this.currentLinkIdx === 'number') {
        links[this.currentLinkIdx] = url;
      } else {
        links.push(url);
      }
      this.$emit('save', this.block._id, 'links', links, (data) => this.changeBlock(data));
      this.currentLinkIdx = null;
    },

    setPoster(value) {
      this.$emit('save', this.block._id, 'videoPoster', value, (data) => this.changeBlock(data));
    },

    // saveBlocksField(e) {
    //   this.$emit('save', this.block._id, e.target.name, e.target.value, (data) => this.changeBlock(data));
    // },

    saveBlocksTitle(e) {
      this.$emit('save', this.block._id, 'title', e, (data) => this.changeBlock(data));
    },

    saveBlocksDescription(e) {
      this.$emit('save', this.block._id, 'description', e, (data) => this.changeBlock(data));
    },

    saveLink(e, index) {
      const newLinks = [...this.block.links];
      newLinks[index] = e.target.value;
      this.$emit('save', this.block._id, 'links', newLinks, (data) => this.changeBlock(data));
    },

    pushNewLink() {
      const newLinks = [...this.block.links, ''];
      this.$emit('save', this.block._id, 'links', newLinks, (data) => this.changeBlock(data));
    },

    removeLink(index) {
      const newLinks = [...this.block.links];
      newLinks.splice(index, 1);
      this.$emit('save', this.block._id, 'links', newLinks, (data) => this.changeBlock(data));
    },
  },
};
</script>

<style lang="less">
.editable-video {
  &__row {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .base-icon {
      margin-right: 10px;
      flex-shrink: 0;
    }
  }

  &__col {
    flex-shrink: 1;
    width: 50%;
    flex-grow: 1;
  }

  &__link-remove .base-icon {
    margin: 0 0 0 10px;
    cursor: pointer;
  }
}
</style>
