import { normalizeData } from '@/helpers/index';

export default {
  SET_LAYOUT(state, layout = 'BaseLayout') {
    state.layout = layout;
  },
  ADD_PENDING(state, ids) {
    ids.forEach((id) => {
      state.pending[id] = true;
    });
  },
  ADD_TO_STORE(state, { type, payload }) {
    state.DB[type] = { ...state.DB[type], ...payload };
  },
  FETCH_MODELS(state, { type, payload }) {
    let data = {};
    if (type === 'users') {
      data = normalizeData(
        payload.map((n) => {
          n.fullName = `${n.firstName} ${n.lastName}`;
          return n;
        })
      );
    } else {
      data = normalizeData(payload);
    }

    state.DB[type] = { ...state.DB[type], ...data };
  },
  FETCH_VARIANT(state, payload) {
    const variant = state.DB.variants[payload._id];
    payload.practice = variant.practice;
    const data = normalizeData([payload]);
    state.DB.variants = { ...state.DB.variants, ...data };
  },
  FETCH_ACTIVITY(state, payload) {
    state.DB.activities = { ...state.DB.activities, ...payload };
  },
  SET_ANSWER(state, payload) {
    const { question, lesson, update } = payload;
    const currentExam = state.DB.activities[lesson];

    currentExam[question] = {
      ...currentExam[question],
      ...update,
    };
  },
  SET_REDIRECT(state, payload) {
    state.redirect = payload;
  },
  REMOVE_LIST_ITEM(state, { type, id }) {
    delete state.DB[type][id];
  },
};
