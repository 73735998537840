import { HTTP } from '@/http.js';

const tags = {
  namespaced: true,
  state: {
    ids: [],
    idsGroups: [],
    tempTag: {},
  },
  mutations: {
    FETCH_TAGS: (state, payload) => {
      state.ids = [...new Set([...state.ids, ...payload])];
    },
    CREATE_TAG: (state, payload) => {
      state.tempTag = payload;
    },
    REMOVE_TAG: (state, id) => {
      const idx = state.ids.indexOf(id);
      if (idx !== -1) {
        state.ids.splice(idx, 1);
      }
    },
    FETCH_GROUPS: (state, payload) => {
      state.idsGroups = [...new Set([...state.idsGroups, ...payload])];
    },
    REMOVE_GROUP: (state, id) => {
      const idx = state.idsGroups.indexOf(id);
      if (idx !== -1) {
        state.idsGroups.splice(idx, 1);
      }
    },
  },
  getters: {
    list(state, getters, rootState) {
      return state.ids.map((id) => rootState.DB.tags[id]);
    },
    groups(state, getters, rootState) {
      return state.idsGroups.map((id) => rootState.DB.tagGroups[id]);
    },
  },
  actions: {
    getTags({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        HTTP.get('/crud/tag', { params })
          .then((response) => {
            if (response.data.error) {
              reject(response.data.error);
              return;
            }
            commit(
              'FETCH_MODELS',
              {
                type: 'tags',
                payload: response.data.models,
              },
              { root: true }
            );
            resolve(response.data.models);
          })
          .catch((error) => reject(error));
      });
    },
    fetchTags({ commit, dispatch }, params) {
      return dispatch('getTags', params).then((models) => {
        commit(
          'FETCH_TAGS',
          models.map((n) => n._id)
        );
        return models;
      });
    },
    addTag({ state, commit }, params) {
      HTTP.post('/crud/tag', { ...params }).then((response) => {
        if (response.data._id) {
          commit('FETCH_TAGS', [response.data._id]);
          commit('CREATE_TAG', response.data);
          commit(
            'FETCH_MODELS',
            {
              type: 'tags',
              payload: [response.data],
            },
            { root: true }
          );
        }
      });
    },
    removeTag({ state, commit }, params) {
      HTTP.delete('/crud/tag', { data: { ...params } })
        .then((response) => {
          commit('REMOVE_TAG', params._id);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    fetchGroups({ state, commit }, params) {
      return HTTP.get('/crud/tagtype').then((response) => {
        commit(
          'FETCH_GROUPS',
          response.data.models.map((n) => n._id)
        );
        commit(
          'FETCH_MODELS',
          {
            type: 'tagGroups',
            payload: response.data.models,
          },
          { root: true }
        );
      });
    },
    addGroup({ state, commit }, params) {
      HTTP.post('/crud/tagtype', { ...params }).then((response) => {
        if (response.data._id) {
          commit('FETCH_GROUPS', [response.data._id]);
          commit(
            'FETCH_MODELS',
            {
              type: 'tagGroups',
              payload: [response.data],
            },
            { root: true }
          );
        }
      });
    },
    removeGroup({ state, commit }, params) {
      HTTP.delete('/crud/tagtype', { data: { ...params } })
        .then((response) => {
          commit('REMOVE_GROUP', params._id);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
  },
};

export default tags;
