<template>
  <div :class="applyClasses">
    <input
      type="checkbox"
      :id="inputId"
      :name="name"
      :value="value"
      :class="className"
      :required="required"
      :disabled="disabled"
      @change="onChange"
      :checked="state"
    />
    <label :for="inputId" class="base-checkbox__label">
      <slot name="input-box">
        <span class="base-checkbox__box">
          <svg class="base-checkbox__tick" viewBox="0 0 16 12">
            <path fill="none" d="M0 4.83751L4.11108 9L13 0" transform="translate(1.37549 1.03711)"></path>
          </svg>
        </span>
      </slot>
      <span class="base-checkbox__text" v-if="showLabel">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<script>
import { toBemModifer } from '@/helpers/index';

export default {
  name: 'BaseCheckbox',
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    modelValue: {
      default: undefined,
    },
    className: {
      type: String,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
    model: {},
  },
  computed: {
    state() {
      if (this.modelValue === undefined) {
        return this.checked;
      }
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) > -1;
      }
      return !!this.modelValue;
    },
    inputId() {
      const { id, _uid } = this;
      const resId = id || _uid;
      return `input-id-${resId}`;
    },
    showLabel() {
      return !!this.$slots.default;
    },
    applyClasses() {
      const { theme } = this;
      const baseClass = 'base-checkbox';
      const result = [baseClass, ...toBemModifer(theme, baseClass, 'theme')];

      return result;
    },
  },
  methods: {
    onChange() {
      this.toggle();
    },
    toggle() {
      let value;
      if (Array.isArray(this.modelValue)) {
        value = this.modelValue.slice(0);
        if (this.state) {
          value.splice(value.indexOf(this.value), 1);
        } else {
          value.push(this.value);
        }
      } else {
        value = !this.state;
      }
      this.$emit('input', value);
    },
  },
  watch: {
    checked(newValue) {
      if (newValue !== this.state) {
        this.toggle();
      }
    },
  },
  mounted() {
    if (this.checked && !this.state) {
      this.toggle();
    }
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-checkbox {
  outline: none;
  user-select: none;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
  }

  &__label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__box {
    display: inline-block;
    background: #fff;
    border: 1px solid #a0a9bc;
    border-radius: 1px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    overflow: hidden;
    user-select: none;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    flex: 0 0 18px;
  }

  &__tick {
    width: 16px;
    height: 16px;
    position: absolute;
    margin: auto;

    > path {
      opacity: 0;
      stroke: #4507ca;
      stroke-width: 2px;
      stroke-dashoffset: 20;
      stroke-dasharray: 20;
      transition: stroke-dashoffset 0.2s ease-in;
    }
  }

  input:checked + &__label path {
    opacity: 1;
    stroke-dashoffset: 0;
  }

  input:disabled + label {
    opacity: 0.7;
  }

  &__text {
    flex: 1 1 auto;
    cursor: pointer;
    font-size: @base-fz;
    transition: color 0.2s;
  }

  &--theme-question &__box {
    border: 2px solid @secondary-color;
  }

  &--theme-question &__tick {
    > path {
      stroke: @secondary-color;
    }
  }
}
</style>
