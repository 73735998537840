<template>
  <div class="lesson-block__gallery">
    <div v-if="gallery.length > 1 && sortedGallery.length > 1">
      <swiper :options="swiperOption" ref="gallery" @slideChange="onSlideChange">
        <swiper-slide
          v-for="slide in sortedGallery"
          v-if="slide"
          :key="slide._id"
          v-bind:style="{ backgroundImage: 'url(' + slide.link + ')' }"
        />

        <div class="swiper-button-prev" slot="button-prev">
          <base-icon glyph="#icon-arrow" width="20px" height="40px"></base-icon>
        </div>

        <div class="swiper-button-next" slot="button-next">
          <base-icon glyph="#icon-arrow" width="20px" height="40px"></base-icon>
        </div>
      </swiper>

      <div class="lesson-block__gallery-pagination">{{ activeSlide + 1 }}/{{ gallery.length }}</div>
    </div>
    <div v-else>
      <div
        class="lesson-block__gallery-image"
        v-if="getSlideImage(gallery[0])"
        v-bind:style="{
          backgroundImage: 'url(' + getSlideImage(gallery[0]).link + ')',
        }"
      ></div>
    </div>

    <div v-if="sortedGallery[activeSlide] && sortedGallery[activeSlide].title" class="lesson-block__title">
      {{ sortedGallery[activeSlide].title }}
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css';
import sortBy from 'lodash.sortby';
import { mapGetters } from 'vuex';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'PreviewLockGallery',
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    gallery: Array,
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      activeSlide: 0,
    };
  },
  computed: {
    ...mapGetters('images', { listImg: 'list' }),

    sortedGallery() {
      const list = this.gallery.map((id) => this.getSlideImage(id));
      return sortBy(list, 'idx');
    },
  },
  methods: {
    getSlideImage(id) {
      return this.listImg.find((img) => img._id === id);
    },

    onSlideChange() {
      this.activeSlide = this.$refs.gallery.swiper.activeIndex;
    },
  },
};
</script>
