import { Quill } from 'vue-quill-editor';

const Link = Quill.import('formats/link');
class LinkWithClass extends Link {
  static create(value) {
    let href = value;
    if (typeof value !== 'string') {
      href = value.href;
    }
    const node = super.create(href);
    href = this.sanitize(href);
    node.setAttribute('href', href);
    node.setAttribute('rel', 'noopener noreferrer');
    node.setAttribute('target', '_blank');
    if (typeof value !== 'string' && value.class) {
      node.setAttribute('class', value.class);
    }
    return node;
  }

  static formats(domNode) {
    const formats = {};
    formats.href = domNode.getAttribute('href');
    formats.class = domNode.getAttribute('class');
    return formats;
  }

  format(name, value) {
    if (name !== this.statics.blotName || !value) return super.format(name, value);
    value.href = this.constructor.sanitize(value.href);
    this.domNode.setAttribute('href', value.href);
    if (value.class) {
      this.domNode.setAttribute('class', value.class);
    }
    return true;
  }
}

export { LinkWithClass as default };
