<template>
  <base-modal class="modal-upload-file" title="Выбрать файл">
    <div slot="modal-body">
      <div class="modal-upload-file__row">
        <div class="file-input mb-10">
          <div class="text mb-10">Укажите ссылку на файл</div>
          <base-input
            width="100%"
            class="file-input__control"
            placeholder="ссылка на файл"
            type="text"
            required
            :value="url"
            :visible="!isFile"
            @input="setUrl"
          />
          <div class="file-input__overlay" v-if="fileUploadPending">
            <base-spinner size="32px"></base-spinner>
          </div>
        </div>
        <div class="mb-30 text">или <label for="ModalUpladFile">Загрузите</label> файл с диска</div>
        <input class="hidden" type="file" id="ModalUpladFile" :value="file" @change="uploadSingle" />
      </div>
      <div class="modal-upload-file__footer">
        <base-button size="large" @click="submit" :disabled="!url">
          <span>Сохранить</span>
        </base-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { uploadFile } from '@/mixins/Upload';

export default {
  name: 'ModalUpladFile',
  mixins: [uploadFile],
  props: {
    current: String,
    handleSave: Function,
  },
  data() {
    return {
      url: '',
      file: null,
      isFile: false,
    };
  },
  watch: {
    current: {
      immediate: true,
      handler(val) {
        if (val === this.url) return;
        this.url = val;
      },
    },
    fileUploadUrl: {
      handler(val) {
        this.url = val;
      },
    },
  },
  methods: {
    setUrl(e) {
      this.url = e.target.value;
    },
    submit() {
      this.handleSave(this.url);
      this.$emit('upload:setUrl', this.url);
      this.$emit('vuedals:close');
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
@import '~less_vars';

.file-input {
  position: relative;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: fade(#fff, 80%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.hidden {
  display: none;
}
.text {
  color: @base-color;
  label {
    color: @secondary-color;
    display: inline-block;
    vertical-align: baseline;
    text-decoration: none;
    border-bottom: 1px dashed currentColor;
    cursor: pointer;

    &:hover {
      border-bottom-color: transparent;
      color: darken(@secondary-color, 10%);
    }
  }
}
.modal-upload-file {
  &__row {
    margin: 30px 0;
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    .base-btn {
      width: 200px;
      max-width: 100%;
    }
  }
}
</style>
