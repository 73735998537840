<template>
  <textarea
    class="base-textarea"
    :placeholder="placeholder"
    :name="name"
    :cols="cols"
    :rows="rows"
    :required="required"
    :value="value"
    :style="applyStyles"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @blur="$emit('blur', $event)"
  />
</template>
<script>
export default {
  name: 'BaseTextarea',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 10,
    },
    rows: {
      type: Number,
      default: 10,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    width: {
      type: String,
      default: '100%',
    },
  },
  computed: {
    applyStyles() {
      return {
        width: this.width,
      };
    },
  },
};
</script>

<style lang="less">
@import '~less_vars';

.base-textarea {
  background-color: #fff;
  border: 1px solid @gray-light;
  border-radius: 2px;
  padding: 10px 15px;
  max-width: 100%;
  transition: border 0.3s;
  font-family: inherit;
  resize: vertical;

  &:focus {
    outline-color: @primary-color;
    outline-width: 2px;
    border: 1px solid @primary-color;
  }

  &:placeholder {
    color: @gray-dark;
    font-weight: 100;
  }
}
</style>
